import { Box, Button, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import newTheme from '../../../newTheme'
import { inline_buttons, inline_space, text_space } from '../../../Utils/defaultStyles'
import { AssignmentTurnedInOutlined, Block, CheckCircleOutline, Delete, HighlightOffOutlined, KeyboardArrowRight, MoreVert, PersonAddOutlined } from '@material-ui/icons'
import { addQrToFile, getLocalCrits, mergeMultiplePdfFilesAndGet } from '../../../Utils/functions'
import moment from 'moment'
import StatusTag from './StatusTag'
import MainInfo from './CardInfo/MainInfo'
import SolutionInfo from './CardInfo/SolutionInfo'
import ChecklistFieldsRender from '../../../Shared/TableRenders/ChecklistFieldsRender'
import SubdivisionCard from '../../../Shared/Cards/SubdivisionCard'
import SolveDialog from './Dialogs/SolveDialog'
import { getFinding } from '../../../API/findings'
import { useContext, useEffect, useRef, useState } from 'react'
import AssignDialog from './Dialogs/AssignDialog'
import { callSnackbar } from '../../../Utils/snackbar'
import ResolveDialog from './Dialogs/ResolveDialog'
import RejectionInfo from './CardInfo/RejectionInfo'
import ApproveDialog from './Dialogs/ApproveDialog'
import RejectDialog from './Dialogs/RejectDialog'
import AppContext from '../../../AppContext'
import { ConfirmationContext } from '../../../Shared/Dialogs/ConfirmationDialog'
import CardTags from './CardTags'
import { url } from '../../../Utils/instance2'
import { CurrentUserContext } from '../../../CurrentUser'
import LoaderAnimator from '../../../Shared/Loader/LoaderAnimator'
import { FindingsManagerContext } from '../../../DatabaseManagers/FindingsManager'
import EntityTitle from '../../../Shared/EntityTitle'
import MiniLoaderAnimator from '../../../Shared/Loader/MiniLoaderAnimator'
import { transformBodyAndSendCallback } from '../../../Utils/offlineSyncFunctions'
import { useActionContext } from '../../../DatabaseManagers/ActionsManager'
import PermissionChecker from '../../../Shared/Wrappers/PermissionChecker'
import DownloadPdfButton from '../../../Shared/Buttons/DownloadPdfButton'
import { useTranslation } from 'react-i18next'
import { getStatus, STATUS } from '../Utils'

const css = {
	container: {
		padding: 2,
		background: 'white',
		borderRadius: 2,
		minWidth: 350,
		'@media (max-width: 600px)': {
			width: '100%',
			minWidth: 'unset'
		},
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'column',
		boxSizing: 'border-box',
	},
	header: {
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		gap: 1,
		marginBottom: 1
	}
}

const secondImgURL = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_IMG_FOLDER}`
const firstImgURL = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_FINDING_IMG_FOLDER}`

function FindingCard({
	urls = [firstImgURL, secondImgURL],
	finding,
	finding_id,
	index = null
}) {

	const [openDialogs, setOpenDialogs] = useState({
		solve: false, assign: false, approve: false, reject: false, resolve: false, menu: false
	})
	const toggleDialog = (dialog) => setOpenDialogs((prev) => ({ ...prev, [dialog]: !prev[dialog] }))

	const [element, setElement] = useState({ ...finding })
	const [loading, setLoading] = useState(false)
	const [loadingDownload, setLoadingDownload] = useState(false)

	const crits = getLocalCrits()
	const element_criticality = element?.criticality || element?.free_criticality
	const crit = crits.find(crit => crit.value === element_criticality)
	const status = element?.status || getStatus(element)
	const isPending = status === STATUS.PENDING
	const isAssigned = status === STATUS.PROCESS
	const isOverdue = status === STATUS.OVERDUE
	const isSolved = status === STATUS.FINISHED
	const isApproved = status === STATUS.APPROVED
	const isRejected = status === STATUS.REJECTED
	const isOver = status === STATUS.DISMISSED || status === STATUS.DELETED
	const isDismissed = status === STATUS.DISMISSED
	const menuAnchor = useRef(null)

	const { history, online: { status: online } } = useContext(AppContext)
	const { askConfirmation } = useContext(ConfirmationContext)
	const { currentUser } = useContext(CurrentUserContext)
	const { addFinding } = useContext(FindingsManagerContext)
	const { getFinding: getFindingFromDB } = useContext(FindingsManagerContext)

	const actionContext = useActionContext()
	const isAdmin = currentUser?.user_type_id === 1
	const isResponsible = parseInt(currentUser?.id, 10) === element?.user_id
	const isOwner = parseInt(currentUser?.id, 10) === element?.owner_id
	const { t } = useTranslation("findingCard")

	useEffect(() => {

		async function fetchFinding() {
			setLoading(true)
			const response = await getFinding({ id: finding_id })
			setLoading(false)
			setElement(response.data.info)
		}

		async function fetchOfflineFinding() {
			const offline_finding = await getFindingFromDB(finding.id)
			if (!!offline_finding) setElement(offline_finding)
		}

		if (!finding && !!finding_id) {
			fetchFinding()
		}

		if (!!finding) {
			setElement({ ...finding })
		}

		if (!online) { fetchOfflineFinding() }
	}, [finding, finding_id, online])

	function setAndSyncElement(element) {
		addFinding(element)
		setElement(element)
	}

	async function handleFindingUpdate(params, newStatus, actionType, actionLabel, snackbarMessage) {
		const body = { ...element, ...params }
		const newFinding = { ...body }
		newFinding.status = newStatus
		setAndSyncElement(newFinding)
		await transformBodyAndSendCallback(body, actionType, actionContext, actionLabel)
		callSnackbar(snackbarMessage, "success")
		setOpenDialogs((prev) => ({ ...prev, menu: false }))
	}

	async function onAssign(params) {
		await handleFindingUpdate(params, STATUS.PROCESS, "assign_finding", t('actions.assignFinding'), t('messages.success.assignedFinding'))
	}

	async function onSolve(params) {
		await handleFindingUpdate(params, STATUS.FINISHED, "solve_finding", t('actions.solveFinding'), t('messages.success.solvedFinding'))
	}

	async function onApprove(params) {
		await handleFindingUpdate(params, STATUS.APPROVED, "approve_finding", t('actions.approveFinding'), t('messages.success.approvedFinding'))
	}

	async function onReject(params) {
		await handleFindingUpdate(params, STATUS.REJECTED, "reject_finding", t('actions.rejectFinding'), t('messages.success.rejectedFinding'))
	}

	function onDelete() {
		async function callback() {
			await handleFindingUpdate({}, STATUS.DELETED, "delete_finding", t('actions.deleteFinding'), t('messages.success.deletedFinding'))
		}
		askConfirmation(t('actions.deleteFinding'), t('messages.confirmation.deletionQuestion'), t('messages.confirmation.confirmDeletion'), callback)
	}

	function onDismiss() {
		async function callback() {
			await handleFindingUpdate({}, STATUS.DISMISSED, "dismiss_finding", t('actions.dismissFinding'), t('messages.success.dismissedFinding'))
		}
		askConfirmation(t('actions.dismissFinding'), t('messages.confirmation.dismissQuestion'), t('messages.confirmation.confirmDismissal'), callback)
	}

	const onReestimate = () => {
		async function callback() {
			await handleFindingUpdate({}, STATUS.REESTIMATE, "re_estimate_finding", t('actions.re-estimateFinding'), t('messages.success.re-estimatedFinding'))
			setOpenDialogs((prev) => ({ ...prev, menu: false }))
		}
		askConfirmation(t('actions.re-estimateFinding'), t('messages.confirmation.re-estimationQuestion'), t('messages.confirmation.confirmRe-estimation'), callback)
	}

	async function updateForm(params) {
		const body = { ...element, ...params }
		const newFinding = { ...body }
		newFinding.form = JSON.stringify(newFinding.form)
		setAndSyncElement(newFinding)
		await transformBodyAndSendCallback(newFinding, "update_finding_form", actionContext, t('actions.updateFindingForm'), "form")
		callSnackbar(t('messages.success.informationUpdated'))
	}

	async function onDownload(info) {
		if (finding.pdf_url) { return window.open(finding.pdf_url, '_blank') }
		setOpenDialogs({ ...openDialogs, menu: false })
		setLoadingDownload(true)
		const download_url = `${url}/findings/download?id=${element.id}`
		const parsed_url = new URL(download_url)
		if (info) {
			Object.keys(info).forEach(key => {
				parsed_url.searchParams.append(key, info[key])
			})
		}
		const final_url = parsed_url.toString()
		const signedDocument = await mergeMultiplePdfFilesAndGet([final_url])
		const code = `${finding.id}--f`
		const validateUrl = `${window.location.origin}/validate/${code}`
		const title = `${finding.date_approved !== "0000-00-00" ? moment(finding.date_approved).format("DD-MM-YYYY") : moment().format("DD-MM-YYYY")}-${finding.free_description.replaceAll(".", "-")}-${finding.branch}`
		await addQrToFile(signedDocument, title, validateUrl)
		setLoadingDownload(false)
	}

	function openFinding(event) {
		// Check if middle mouse button or ctrl + click
		if (event.button === 1 || (event.ctrlKey && event.button === 0)) {
			window.open(`/findings/${element.id}`)
		} else {
			history.push(`/findings/${element.id}`)
		}
	}

	let state = 0
	if (isPending) state = 1
	if (isAssigned) state = 2
	if (isOverdue) state = 2
	if (isSolved) state = 3
	if (isApproved) state = 4
	if (isRejected) state = 5

	if (isOver) return null
	if (loading) return (
		<Box sx={css.container}>
			<Box sx={{ height: 150, position: 'relative' }}>
				<LoaderAnimator />
			</Box>
		</Box>
	)

	let title = element?.free_description || element?.description
	if (index !== null) { title = `${index + 1}. ${title}` }

	const actionButtons = [
		{ condition: isPending && (isAdmin || isOwner), color: "warning", icon: PersonAddOutlined, label: t('actions.assignFinding'), action: () => toggleDialog('assign') },
		{ condition: (isAssigned || isOverdue) && (isAdmin || isResponsible), color: "info", icon: AssignmentTurnedInOutlined, label: t('actions.solveFinding'), action: () => toggleDialog('solve') },
		{ condition: isRejected && (isAdmin || isResponsible), color: "info", icon: AssignmentTurnedInOutlined, label: t('actions.createAnotherSolution'), action: () => toggleDialog('resolve') }
	]

	const approveRejectButtons = [
		{ condition: isSolved && (isAdmin || isOwner), color: "success", icon: CheckCircleOutline, label: t('actions.approveFinding'), action: () => toggleDialog('approve') },
		{ condition: isSolved && (isAdmin || isOwner), color: "warning", icon: HighlightOffOutlined, label: t('actions.rejectFinding'), action: () => toggleDialog('reject') },
	]

	const menuItems = [
		{ condition: true, label: t('actions.openFinding'), icon: KeyboardArrowRight, icon_color: newTheme.palette.info.main, color: "info.main", action: openFinding, onMouseDown: { openFinding } },
		{ condition: (isAdmin || isOwner) && !isDismissed, label: t('actions.dismiss'), icon_color: newTheme.palette.warning.main, color: "warning.main", icon: Block, action: onDismiss },
		{ condition: (isAdmin || isOwner) && isDismissed, label: t('actions.re-estimate'), icon_color: newTheme.palette.success.main, color: "success.main", icon: Block, action: onReestimate },
		{ condition: (isAdmin || isOwner), label: t('actions.delete'), icon: Delete, icon_color: newTheme.palette.error.main, color: "error.main", action: onDelete }
	]

	return (
		<Box sx={css.container}>
			<Box>
				<Box sx={css.header}>
					<EntityTitle title={title} belongs={element?.is_committee} />
					<Box sx={inline_buttons}>
						<CardTags tags={element?.tags || []} />
						{(isOwner || isResponsible || isAdmin) && !loadingDownload &&
							<IconButton ref={menuAnchor} onClick={() => toggleDialog('menu')}>
								<MoreVert />
							</IconButton>
						}
						{loadingDownload &&
							<MiniLoaderAnimator />
						}
					</Box>
				</Box>
				<Menu open={openDialogs.menu} onClose={() => toggleDialog('menu')} anchorEl={menuAnchor.current}>
					{menuItems.map((item, index) => item.condition && (
						<MenuItem key={index} onClick={item.action} onMouseDown={item.onMouseDown} sx={inline_space} >
							<Typography variant='subtitle2' color={item.color}>{item.label}</Typography>
							{item.icon && <item.icon style={{ color: item.icon_color }} />}
						</MenuItem>
					))}
					<DownloadPdfButton
						onClick={onDownload}
						type={"finding"}
						updateState={setElement}
						element={finding}
						menuItem={true}
						getElement={getFinding} />
				</Menu>
				<ChecklistFieldsRender value={element?.base_checklist_name} element={{ id: element.checklist_id }} />
				<SubdivisionCard subdivision={element.subdivision} branch_id={finding?.branch_id} />
				{!!element?.item &&
					<Typography variant='caption'>
						{t('data.createdFrom', { item: element?.item })}
					</Typography>
				}
				<Box sx={css.tag}>
					<StatusTag status={status} />
				</Box>
				<MainInfo finding={element} crit={crit} urls={urls} updateForm={updateForm} />
				{state >= 3 &&
					<Box>
						<SolutionInfo finding={element} urls={urls} />
					</Box>
				}
				{isRejected &&
					<Box>
						<Typography variant='h4' sx={text_space} >{t('data.rejection')}:</Typography>
						<RejectionInfo finding={element} />
					</Box>
				}
			</Box>
			<Box>
				<PermissionChecker expectedPermissions={["arf"]}>
					{approveRejectButtons.map((btn, index) => btn.condition && (
						<Box key={index} sx={text_space}>
							<Button
								fullWidth
								color={btn.color}
								variant="contained"
								endIcon={<btn.icon />}
								onClick={btn.action}>
								{btn.label}
							</Button>
						</Box>
					))}
				</PermissionChecker>
				{actionButtons.map((btn, index) => btn.condition && (
					<Box key={index} sx={text_space}>
						<Button
							fullWidth
							color={btn.color}
							variant="contained"
							endIcon={<btn.icon />}
							onClick={btn.action}>
							{btn.label}
						</Button>
					</Box>
				))}
			</Box>
			<AssignDialog open={openDialogs.assign} onClose={() => toggleDialog('assign')} finding={element} onSubmit={onAssign} crit={crit} urls={urls} />
			<RejectDialog open={openDialogs.reject} onClose={() => toggleDialog('reject')} finding={element} onSubmit={onReject} crit={crit} urls={urls} />
			<SolveDialog open={openDialogs.solve} onClose={() => toggleDialog('solve')} finding={element} onSubmit={onSolve} crit={crit} urls={urls} />
			<ApproveDialog open={openDialogs.approve} onClose={() => toggleDialog('approve')} finding={element} onSubmit={onApprove} crit={crit} urls={urls} />
			<ResolveDialog open={openDialogs.resolve} onClose={() => toggleDialog('resolve')} finding={element} onSubmit={onSolve} crit={crit} urls={urls} />
		</Box >
	)
}

export default FindingCard