import { Box, Dialog, Typography, Button, Checkbox } from "@mui/material"
import { dialog_style, inline_buttons, end_buttons, title_style, text_space, inline_space } from "../../../Utils/defaultStyles"
import { useTranslation } from "react-i18next"
import UsersInput from "../../../Shared/Inputs/UsersInput"
import DateInput from "../../../Shared/Inputs/DateInput"
import ChecklistTagsSelector from "../../../Shared/Inputs/ChecklistTagsSelector"
import { editChecklistTags, deleteChecklist } from "../../../API/new_checklists"
import { addRemoveInArray } from "../../../Utils/functions"
import { useContext } from "react"
import { ChecklistContext } from "./ChecklistView"
import { CurrentUserContext } from "../../../CurrentUser"
import { toggleCommitteeChecklistFlag } from "../../../API/definitive_checklists"
import PermissionChecker from "../../../Shared/Wrappers/PermissionChecker"
import { Delete, Save } from "@material-ui/icons"
import useToggle from "../../../Hooks/ToogleHook"
import AppContext from "../../../AppContext"
import newTheme from "../../../newTheme"

const css = {
	warning: {
		background: newTheme.palette.error.main,
		padding: 2,
		borderRadius: 2,
		marginTop: 2
	}
}

function EditChecklistDialog({ open, onClose, params, setParams, onSave }) {
	const { t } = useTranslation('checklistShow')
	const [deleteing, toggleDeleteing] = useToggle(false)
	const { checklist, setChecklist, isAdmin } = useContext(ChecklistContext)
	const { currentUser } = useContext(CurrentUserContext)
	const hasCommitteePermissions = currentUser.is_committee || isAdmin
	const { history } = useContext(AppContext)

	async function onChangeTags(id) {
		const tag_ids = checklist.tags.map(t => t.id)
		addRemoveInArray(tag_ids, id)
		const body = { tag_ids: tag_ids, id: checklist.id }
		const response = await editChecklistTags(body)
		const new_tags = response.data.info
		const new_checklist = { ...checklist, tags: new_tags }
		setChecklist(new_checklist)
	}

	async function toggleCommitteeFlag() {
		const body = { id: checklist.id, is_committee: !checklist.is_committee }
		const new_checklist = { ...checklist, is_committee: !checklist.is_committee }
		await toggleCommitteeChecklistFlag(body)
		setChecklist(new_checklist)
	}

	async function onDelete() {
		await deleteChecklist({ id: checklist.id })
		history.push("/")
	}

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
			<Box sx={dialog_style}>
				<Typography variant='h4'>{t('secondaryInfo.editConfig')}</Typography>
				<UsersInput label={t('secondaryInfo.responsible')} name="user_id" value={params.user_id} onChange={setParams} />
				<DateInput label={t('secondaryInfo.dateScheduled')} name="date_scheduled" value={params.date_scheduled} onChange={setParams} />

				<Typography variant='subtitle2'>{t('secondaryInfo.checklistTags')}</Typography>
				<ChecklistTagsSelector value={checklist.tags} onChange={onChangeTags} />
				{hasCommitteePermissions &&
					<Box sx={inline_buttons}>
						<Typography variant='subtitle2'>{t('secondaryInfo.belongsCommittee')}</Typography>
						<Checkbox color="info" checked={checklist.is_committee} onClick={toggleCommitteeFlag} />
					</Box>
				}
				<Box sx={end_buttons}>
					<Button variant="contained" color="info" onClick={onSave} endIcon={<Save />}>
						Guardar
					</Button>
				</Box>

				<PermissionChecker expectedPermissions={"delete_checklist"} plain >
					<Box sx={{ ...end_buttons, marginTop: 2 }}>
						<Button variant="outlined" color="error" endIcon={<Delete />} onClick={toggleDeleteing}>
							{t('secondaryInfo.deleteChecklist')}
						</Button>
					</Box>
				</PermissionChecker>

				{deleteing &&
					<Box sx={css.warning}>
						<Typography sx={{ ...title_style }} color="white.main" variant='h4'>{t('secondaryInfo.deleteWarning.title')}</Typography>
						<Typography sx={{ ...text_space }} color="white.main" variant='subtitle1'>{t('secondaryInfo.deleteWarning.description')}</Typography>
						<Box sx={inline_space}>
							<Button sx={css.white} color="white" variant="outlined" onClick={onDelete}>
								{t('secondaryInfo.deleteWarning.confirm')}
							</Button>
							<Button variant="contained" onClick={toggleDeleteing}>
								{t('buttons.cancel')}
							</Button>
						</Box>
					</Box>
				}

			</Box>

		</Dialog>
	)
}

export default EditChecklistDialog