import { Box } from '@mui/material'
import { useEffect, useRef } from 'react'

function ItemHTMLContent({ item }) {

	const itemContent = useRef(null)

	useEffect(() => {
		if (!!itemContent.current) {
			// Set itemContent html to be the item.content value
			itemContent.current.innerHTML = item.content
		}
	}, [itemContent, item.content])

	return (
		<Box >
			<Box className="ck-content" ref={itemContent} />
		</Box>
	)
}

export default ItemHTMLContent