import { IconButton } from '@mui/material'
import { sendMail } from '../../../../API/notifications'
import { callSnackbar } from '../../../../Utils/snackbar'
import { NotificationsActiveOutlined } from '@material-ui/icons'
import IfOnline from '../../../../Shared/OnlineStatusRendered/IfOnline'
import { useTranslation } from 'react-i18next'
import { notifyResponsibles } from '../../../../API/definitive_checklists'

function SubtitleNotificationBell({ checklist, categories, responsible = null }) {
	const { t } = useTranslation('checklistItem')

	async function sendNotifications() {
		const usersToAlert = [...categories.map(s => s.users).flat()]
		const enableUsersToAlert = usersToAlert.filter(u => u.disabled === false)
		const user_ids = enableUsersToAlert.map(u => u.user_id)
		if (responsible) user_ids.push(responsible.id)
		await notifyResponsibles({ user_ids, id: checklist.id })
		callSnackbar(t('emailSent'))
	}

	return (
		<IfOnline>
			<IconButton onClick={sendNotifications}>
				<NotificationsActiveOutlined />
			</IconButton>
		</IfOnline>
	)
}

export default SubtitleNotificationBell