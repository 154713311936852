import { useContext } from 'react'
import AppContext from '../../AppContext'

function IfOffline({ children }) {

	const { online: { status: online } } = useContext(AppContext)

	if (!online) return children
	return null
}

export default IfOffline