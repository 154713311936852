import React, { useState, useContext, useMemo } from "react"
import { TextField, Autocomplete, Box, Typography, IconButton } from "@mui/material"
import { UserSelectContext } from "../Dialogs/UserSelectDialog"
import { useTranslation } from "react-i18next"
import { removeAccents } from "../../Utils/functions"
import { AddCircleOutline } from "@material-ui/icons"
import { inline_space_no_wrap } from "../../Utils/defaultStyles"

function EmailInputWithMenu({ onSelect, width }) {
	const [inputValue, setInputValue] = useState("")
	const { users } = useContext(UserSelectContext)
	const { t } = useTranslation("input")

	function filterUsersByNameOrEmail(input) {
		if (input === "") return []
		const flatUsers = users.map(element => element.users || []).flat()
		const filtered = flatUsers.filter(user =>
			user.name && user.email &&
			(removeAccents(user.name).toLowerCase().includes(removeAccents(input).toLowerCase()) ||
				removeAccents(user.email).toLowerCase().includes(removeAccents(input).toLowerCase()))
		)
		const simpleFiltered = filtered.map(user => ({ id: user.id, name: user.name, email: user.email }))
		const unique = [...new Set(simpleFiltered.map(user => user.email))].map(email => simpleFiltered.find(user => user.email === email))
		return unique
	}

	const filteredOptions = useMemo(() => {
		return inputValue ? filterUsersByNameOrEmail(inputValue) : []
	}, [inputValue])

	return (
		<Box sx={{ ...inline_space_no_wrap }}>
			<Autocomplete
				sx={{ width: width || 250 }}
				freeSolo
				options={filteredOptions}
				getOptionLabel={(option) =>
					typeof option === "string"
						? option
						: `${option.name} (${option.email})`
				}
				value={null}
				inputValue={inputValue}
				onChange={(event, newValue) => {
					onSelect(newValue)
					setInputValue("")
				}}
				onInputChange={(event, newInputValue) => {
					setInputValue(newInputValue)
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						label={t("emailInput.label")}
						variant="outlined"
						placeholder={t("emailInput.placeholder")}
					/>
				)}
				renderOption={(props, option) => (
					<Box component="li" {...props} key={option.email || option}>
						{typeof option === "string"
							? option
							:
							<Box>
								<Typography variant="subtitle2">{option.name} {option.id}</Typography>
								<Typography variant="caption">{option.email}</Typography>
							</Box>
						}
					</Box>
				)}
			/>
			<IconButton onClick={() => { onSelect(inputValue); setInputValue("") }} disabled={!inputValue}><AddCircleOutline /></IconButton>
		</Box>
	)
}

export default EmailInputWithMenu
