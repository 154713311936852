import { Box, IconButton, Typography } from '@mui/material'
import { useRef, useState } from 'react'
import { fileToBase64 } from '../../Utils/functions'
import { UploadFile, Clear } from '@mui/icons-material'
import { callSnackbar } from '../../Utils/snackbar'
import { inline } from '../../Utils/defaultStyles'
import { useTranslation } from 'react-i18next'

const css = {
	input: {
		border: "1px solid lightgrey",
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '6px 12px',
		borderRadius: 1
	},
}

const file_types = {
	pdf: "application/pdf",
	image: "image/*",
	txt: "text/plain",
}

function PlainFileInput({ value = "", onChange, name, type = false }) {
	const hidden_file = useRef(null)
	const files = !!value ? value?.split("&#&") || [] : []
	const [error, setError] = useState("")
	const { t } = useTranslation('input')

	function simulateClick() {
		hidden_file.current.click()
	}

	async function handleChange(event) {
		const { target } = event
		const { files } = target
		const file = files[0]

		if (type && file.type !== file_types[type]) {
			callSnackbar(t('plainFileInput.errorMessage'), "error")
			setError(t('plainFileInput.errorMessage'))
			return
		}

		const string_file = await fileToBase64(file)
		const file_name = file.name
		const file_with_name = `${string_file}|&|${file_name}`
		const new_files = [...files]
		new_files.push(file_with_name)
		let new_value = ""
		new_value = file_with_name
		onChange({ target: { name, value: new_value } })
	}

	function handleDelete() {
		onChange({ target: { name, value: "" } })  // Resetea el valor a un string vacío
	}

	const file_name = files[0]?.split("|&|")[1]

	return (
		<Box>
			<Box sx={css.input}>
				<Box sx={{ ...inline, gap: 0 }}>
					<Typography variant='subtitle1'>{file_name || t('plainFileInput.addDocument')}</Typography>
					{file_name && (
						<IconButton onClick={handleDelete} size='small'>
							<Clear fontSize='small' />
						</IconButton>
					)}
				</Box>

				<IconButton onClick={simulateClick}>
					<UploadFile />
				</IconButton>
				<input type="file" id="file" hidden ref={hidden_file} onChange={handleChange} accept={type ? file_types[type] : ""} />
			</Box>
			{error && (
				<Typography variant="caption" color="error.main">
					{error}
				</Typography>
			)}
		</Box>
	)
}

export default PlainFileInput
