import { Box, Button, Typography } from '@mui/material'
import { FindingDialogContext } from '../FindingDialog'
import { useContext } from 'react'
import { inline_space, labeless, title_style } from '../../../../Utils/defaultStyles'
import TextInput from '../../../../Shared/Inputs/TextInput'
import SelectInput from '../../../../Shared/Inputs/SelectInput'
import { useTranslation } from 'react-i18next'

function MeasureStep({ value }) {

	const { params, setParams, nextStep, finding_measures, transformBaseToOptions, prevStep } = useContext(FindingDialogContext)
	const { t } = useTranslation("findingCreationDialog")
	function onChange(event) {
		const { target } = event
		const { name, value } = target

		const new_params = { ...params, [name]: value }
		if (name === 'base_finding_measure_id') {
			const measure = finding_measures.find(m => m.id === value)
			new_params.description = measure?.content
		}
		setParams(new_params)
	}

	const filteredFindingMeasures = finding_measures
		.filter(m => String(m.finding_type_id) === String(params.finding_type_id))
		.filter(m => String(m.default_criticality) === String(params.free_criticality))

	return (
		<Box>
			<Typography variant='h4' color="info.main">{t('data.resolvingAction')}</Typography>
			<Typography variant='subtitle1' sx={title_style}>{t('indications.possibleSolution')}</Typography>
			{!!filteredFindingMeasures.length &&
				<Box>
					<Typography variant='subtitle2' sx={labeless}>{t('messages.selectPredefinedMeasure')} </Typography>
					<SelectInput options={transformBaseToOptions(filteredFindingMeasures)} label="" name="base_finding_measure_id" value={params.base_finding_measure_id} onChange={onChange} />
				</Box>
			}
			<Typography variant='subtitle2' sx={labeless}>{t('data.inmediateAction')}</Typography>
			<TextInput label="" name="description" value={params.description} onChange={onChange} />
			<Box sx={inline_space}>
				<Button variant="outlined" onClick={prevStep}>
					{t('stepBackwards')}
				</Button>
				<Button variant="contained" color="success" onClick={nextStep} disabled={!params.description}>
					{t('actions.continue')}
				</Button>
			</Box>
		</Box>
	)
}

export default MeasureStep