import { Delete } from '@material-ui/icons'
import { Box, IconButton, Typography } from '@mui/material'
import { useContext } from 'react'
import useToggle from '../../../../Hooks/ToogleHook'
import newTheme from '../../../../newTheme'
import DeleteDialog from '../../../../Shared/Dialogs/DeleteDialog'
import ImageContainer from '../../../../Shared/Containers/ImageContainer'
import { completePath } from '../../../../Utils/functions'
import { ChecklistContext } from '../../Show/ChecklistView'
import { italic, title_style } from '../../../../Utils/defaultStyles'
import { transformBodyAndSendCallback } from '../../../../Utils/offlineSyncFunctions'
import { useActionContext } from '../../../../DatabaseManagers/ActionsManager'
import { useTranslation } from 'react-i18next'

const css = {
	container: {
		background: 'white',
		padding: 2,
		borderRadius: 1,
		maxWidth: 200,
		position: 'relative'
	},
	offline: {
		position: 'absolute',
		top: 6,
		right: 6,
		color: newTheme.palette.warning.main
	}
}

function Sign({ sign, group }) {

	const { checklist, setChecklist, appendSave, resolveSave, hasPermissions, finished } = useContext(ChecklistContext)
	const [openDelete, toggleDelete] = useToggle(false)
	const isFingerprint = !!sign?.is_fingerprint
	const actionContext = useActionContext()
	const { t } = useTranslation('checklistSigns')

	async function onDelete() {
		const body = { ...sign }
		const new_groups = [...checklist.group_signs]
		const new_group = { ...group }
		const new_group_index = new_groups.findIndex(g => g.id === group.id)
		const new_signs = [...new_group.signs]
		const deleted_sign_index = new_signs.findIndex(s => s.id === sign.id)
		new_signs.splice(deleted_sign_index, 1)
		new_group.signs = new_signs
		new_groups[new_group_index] = new_group
		const new_checklist = { ...checklist }
		new_checklist.group_signs = new_groups
		setChecklist(new_checklist)
		appendSave()
		await transformBodyAndSendCallback(body, "delete_checklist_sign", actionContext, t('actions.deleteSign'))
		resolveSave()
	}

	const old_sign_url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_USER_SIGNS}`
	return (
		<Box sx={css.container}>
			<Typography variant='subtitle2' sx={title_style} ><strong>{sign?.sign_name}</strong></Typography>
			<Typography variant='subtitle1'>{sign?.sign_rut || sign?.rut}</Typography>
			{sign?.position && <Typography variant='subtitle1' sx={italic}>{t('info.position')} {sign?.position}</Typography>}
			{sign?.sub_branch && <Typography variant='subtitle1' sx={italic}>{t('info.contractor')} {sign.sub_branch}</Typography>}
			{sign?.sub_enterprise && <Typography variant='subtitle1' sx={italic}>{t('info.enterprise')} {sign.sub_enterprise}</Typography>}
			<Typography variant='caption'>{sign?.date}</Typography>
			{isFingerprint ?
				<Box sx={{ width: 200 }}>
					<ImageContainer width={200} height={100} src={completePath(sign?.sign_url, old_sign_url)} alt={t('signature')} />
				</Box>
				:
				<Box>
					{
						sign?.sign_url ?
							<ImageContainer width={200} src={completePath(sign?.sign_url, old_sign_url)} alt={t('signature')} />
							:
							<Box sx={{ width: 200 }}>
								<Typography sx={{ marginTop: 2 }} variant='subtitle2' color="success.main">Firmado con TazkiPass</Typography>
							</Box>
					}

				</Box>
			}
			<IconButton sx={css.offline} onClick={toggleDelete} disabled={!sign.id || !hasPermissions || finished}>
				<Delete />
			</IconButton>
			<DeleteDialog open={openDelete} onClose={toggleDelete} onSubmit={onDelete} />
		</Box>
	)
}

export default Sign