import { Check, Clear } from '@material-ui/icons'
import { Box, Button } from '@mui/material'

const css = {
	option_buttons: {
		display: 'flex',
		flexDirection: 'row',
		gap: 2,
		'@media (max-width:600px)': {
			flexDirection: 'column',
		}
	},
}

const option_buttons = [
	{ name: <Check />, color: "success", value: 1 },
	{ name: <Clear />, color: "error", value: 2 },
	{ name: "N/A", color: "info", value: 3 },
]

function ItemButtons({ disabled, answer, answerItem }) {
	return (
		<Box sx={css.option_buttons}>
			{option_buttons.map(button =>
				<Button
					disabled={disabled}
					key={button.value}
					sx={css.option_button}
					onClick={() => answerItem(button.value)}
					color={button.color}
					variant={answer.value_id === button.value ? "contained" : "outlined"}
				>
					{button.name}
				</Button>
			)}
		</Box>
	)
}

export default ItemButtons