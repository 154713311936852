import { Box, Collapse, IconButton, Typography, Button, useMediaQuery } from '@mui/material'
import newTheme from '../../../../newTheme'
import { inline_space, inline_buttons, inline_space_no_wrap } from '../../../../Utils/defaultStyles'
import ToggleIcon from '../../../../Shared/Icons/ToggleIcon'
import useToggle from '../../../../Hooks/ToogleHook'
import { ChecklistContext } from '../ChecklistView'
import { useContext } from 'react'
import AppContext from '../../../../AppContext'
import { useTranslation } from 'react-i18next'
import { getStatusColor } from '../checklistUtils'

const css = {
	container: {
		background: newTheme.palette.white.main,
		borderRadius: 1,
		padding: 2,
		margin: '24px 0'
	},
	checklists: {
		background: 'whitesmoke',
		padding: 2,
		borderRadius: 2,
		marginTop: 2
	}
}

function RelatedChecklistQuickAccess({ }) {
	const { t } = useTranslation('checklistShow')
	const [openCollapse, toggleCollapse] = useToggle(false)
	const { checklist } = useContext(ChecklistContext)
	const { history } = useContext(AppContext)
	const isMobile = useMediaQuery('(max-width: 600px)')

	function openChecklist(id) {
		history.push(`/checklists/${id}`)
	}

	return (
		<Box sx={css.container}>
			<Box sx={inline_space_no_wrap}>
				<Box sx={inline_buttons}>
					<Typography variant='h4'>{t("relatedChecklists.title")}</Typography>
				</Box>
				<IconButton onClick={toggleCollapse}>
					<ToggleIcon open={openCollapse} />
				</IconButton>
			</Box>
			<Collapse in={openCollapse}>
				<Box sx={{ maxHeight: "180px", overflowY: "auto" }}>
					{checklist.related_checklists.map((related_checklist, index) => (
						<Box sx={{ ...inline_space, margin: "12px 0", background: 'whitesmoke', padding: 2 }} key={index}>
							<Box onClick={isMobile ? () => history.push(`/checklists/${checklist?.id}`) : null}>
								<Typography>{related_checklist.name}</Typography>
								<Typography variant="caption" color={getStatusColor(related_checklist.status, t)}>{t(`status.${related_checklist.status}`)}</Typography>
							</Box>
							{!isMobile && <Button color="primary" variant="contained" size="small" onClick={() => openChecklist(related_checklist.id)}>
								{t("relatedChecklists.goNow")}
							</Button>}
						</Box>
					))}
				</Box>
			</Collapse>
		</Box>
	)
}

export default RelatedChecklistQuickAccess
