import { AttachFile, NotificationsActiveOutlined, VpnKey } from '@material-ui/icons'
import { Box, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import newTheme from '../../../../newTheme'
import { ChecklistContext } from '../../Show/ChecklistView'
import { CurrentUserContext } from '../../../../CurrentUser'
import { inline, title_style } from '../../../../Utils/defaultStyles'
import SignsGroups from './SignsGroups'
import Attachments from './Attachments'
import InviteUsers from './InviteUsers'
import { ConfirmationContext } from '../../../../Shared/Dialogs/ConfirmationDialog'
import { transformBodyAndSendCallback } from '../../../../Utils/offlineSyncFunctions'
import { useActionContext } from '../../../../DatabaseManagers/ActionsManager'
import { callSnackbar } from '../../../../Utils/snackbar'

const css = {
	container: {
		maxWidth: 800
	},
	paper: {
		padding: 2
	},
	title: {
		margin: "12px 0"
	},
	subtitle: {
		margin: '24px 0 12px 0'
	},
	participants: {
		padding: 2,
		background: newTheme.palette.blue.light,
		borderRadius: 2
	},
	participant: {
		display: 'flex',
		alignItems: 'center',
		margin: '6px 0'
	}
}

function ChecklistSignContent({ value }) {
	const { t } = useTranslation('checklistSigns')
	const [selected, setSelected] = useState([])
	const { checklist, setChecklist, isAdmin, isResponsible, appendSave, resolveSave, highlighted, imResponsibleOfSection, finished } = useContext(ChecklistContext)
	const { currentUser } = useContext(CurrentUserContext)
	const { askConfirmation } = useContext(ConfirmationContext)
	const actionContext = useActionContext()

	useEffect(() => {
		setSelected(checklist?.requested_participants || [])
	}, [checklist])

	const participants = checklist.requested_participants || []

	async function addParticipantOffline(body) {
		const new_checklist = { ...checklist }
		new_checklist.requested_participants = body.participants
		setChecklist(new_checklist)
		appendSave()
		await transformBodyAndSendCallback(body, "set_checklist_participants", actionContext, t('content.inviteUsersAction'))
		resolveSave()
	}

	function onParticipantsSelection(event) {
		const participants = event.target.value
		if (participants.length >= 10) {
			askConfirmation(
				t('content.confirmTitle'),
				t('content.confirmMessage'),
				t('content.confirmYes'),
				callback
			)
			return
		}

		function callback() {
			const body = { participants, id: checklist?.id }
			callSnackbar(t('requests.sent'), "success")
			addParticipantOffline(body)
		}

		callback()
	}

	const groups = checklist.group_signs || []
	const allSigns = groups.map(group => group.signs).flat().map(sign => String(sign.user_id))
	const hasPermissions = isResponsible || isAdmin || imResponsibleOfSection
	const isCollab = currentUser.userType === 3
	const hasAttachments = !!checklist.attachment

	return (
		<Box sx={css.container}>
			{hasPermissions && !isCollab &&
				<Box>
					<Box sx={{ ...inline, ...css.title }}>
						<NotificationsActiveOutlined className={highlighted?.type === "sign" ? "rise-shake" : ""} />
						<Typography variant='h4'>{t('requests.signatures')}</Typography>
					</Box>
					<Box sx={title_style}>
						{highlighted?.type === "sign" &&
							<Typography color="error">{t('content.missingSignatures')}</Typography>
						}
					</Box>

					<InviteUsers
						setSelected={setSelected}
						participants={participants}
						selected={selected}
						onParticipantsSelection={onParticipantsSelection}
						allSigns={allSigns}
					/>

				</Box>
			}
			{hasAttachments &&
				<Box>
					<Box sx={{ ...inline, ...css.subtitle }}>
						<AttachFile />
						<Typography variant='h4'>{t('content.attachedDocuments')}</Typography>
					</Box>
					<Attachments />
				</Box>
			}
			<Box sx={{ ...inline, ...css.subtitle }} >
				<VpnKey />
				<Typography variant='h4'>{t('content.registeredSignatures')}</Typography>
			</Box>
			<SignsGroups />
		</Box>
	)
}

export default ChecklistSignContent
