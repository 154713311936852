import { Box, Checkbox, Typography } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { ChecklistContext } from "../../Show/ChecklistView"
import TextInput from "../../../../Shared/Inputs/TextInput"
import { useTranslation } from "react-i18next"

function ComplianceField({ field, onChange, value, onBlur, onChangeAndSave }) {
	const [params, setParams] = useState(value ? { ...value } : {})
	const { finished, hasPermissions } = useContext(ChecklistContext)
	const { t } = useTranslation("checklistForm")

	useEffect(() => {
		const newValue = value ? JSON.parse(value) : {}
		setParams({ ...newValue })
	}, [value])

	function onChangeParams(event) {
		const { target } = event

		const new_value = value ? { ...params } : {}
		if (["total", "desfav"].includes(target.name)) {
			const value = target.value > 0 ? target.value : 0
			new_value[target.name] = value
		} else {
			new_value[target.name] = target.value
		}

		const new_event = {
			target: { value: JSON.stringify(new_value) },
		}
		setParams(new_value)
		onChange(new_event)
	}

	function toggleSolved() {
		const new_value = value ? { ...params } : {}
		new_value.solved = !new_value.solved
		const new_event = {
			target: { value: JSON.stringify(new_value) },
		}
		setParams(new_value)
		onChangeAndSave(new_event)
	}

	const ratio = params.total > 0 ? params.desfav / params.total : 0

	const calculated_compliance = 100 - ratio * 100
	const partial_compliance = params.solved ? calculated_compliance : 0
	const final_compliance = params.solved ? 100 : calculated_compliance

	if (finished || !hasPermissions)
		return (
			<Box>
				<Typography variant="subtitle1">
					{t("compliance.total")}: <strong>{params.total}</strong>
				</Typography>
				<Typography variant="subtitle1">
					{t("compliance.case")}: <strong>{params.desfav}</strong>
				</Typography>
				{calculated_compliance < 100 && (
					<Typography variant="subtitle1">
						{t("compliance.observation")}: <strong>{params.comment}</strong>
					</Typography>
				)}
				{calculated_compliance < 100 && (
					<Typography variant="subtitle1">
						{t("compliance.resolvedOnSite")}:{" "}
						<strong>{params.solved ? t("compliance.yes") : t("compliance.no")}</strong>
					</Typography>
				)}
				{calculated_compliance < 100 && (
					<Typography variant="subtitle1">
						{t("compliance.partial")}:{" "}
						<strong>{partial_compliance.toFixed(1)}%</strong>
					</Typography>
				)}
				<Typography variant="subtitle1">
					{t("compliance.final")}: <strong>{final_compliance.toFixed(1)}%</strong>
				</Typography>
			</Box>
		)

	return (
		<>
			<Box>
				<TextInput
					type="number"
					onBlur={onBlur}
					value={params.total}
					onChange={onChangeParams}
					label=""
					name="total"
				/>
				<Typography variant="subtitle1">{t("compliance.case")}</Typography>
				<TextInput
					type="number"
					onBlur={onBlur}
					name="desfav"
					value={params.desfav}
					onChange={onChangeParams}
					label=""
				/>
				{(final_compliance < 100 || (params.solved && partial_compliance < 100)) && (
					<Box>
						<Checkbox
							checked={params.solved || false}
							onClick={toggleSolved}
						/>
						<Typography variant="subtitle1">{t("compliance.resolvedQuestion")}</Typography>
					</Box>
				)}
				{partial_compliance !== 0 && (
					<Typography variant="subtitle1">
						<strong>
							{t("compliance.partial")}: {partial_compliance.toFixed(1)}%
						</strong>
					</Typography>
				)}
				<Typography variant="subtitle1">
					<strong>
						{t("compliance.final")}: {final_compliance.toFixed(1)}%
					</strong>
				</Typography>
				{(final_compliance < 100 || (params.solved && partial_compliance < 100)) && (
					<Box>
						<Typography variant="subtitle1">{t("compliance.observation")}</Typography>
						<TextInput
							onBlur={onBlur}
							name="comment"
							value={params.comment}
							onChange={onChangeParams}
							label=""
						/>
					</Box>
				)}
			</Box>
		</>
	)
}

export default ComplianceField
