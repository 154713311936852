import { Box, TextField } from '@mui/material'
import { useRef } from 'react'

const css = {
	container: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		gap: 1
	}
}

function PinInput({ value, onChange, name, length }) {

	const inputRefs = useRef([])

	function onChangeDigit(e, index) {

		if (value.length === length && e.target.value) return

		const newValue = value.split('')
		newValue[index] = e.target.value
		const newEvent = { target: { value: newValue.join(''), name } }
		onChange(newEvent)

		// Move to next input
		if (e.target.value && index < length - 1) {
			inputRefs.current[index + 1].focus();
		}
	}

	function onBackspacePress() {
		const newValue = value.split('')
		newValue[value.length - 1] = ''
		const newEvent = { target: { value: newValue.join(''), name } }
		onChange(newEvent)

		// Move to previous input
		inputRefs.current[value.length - 1].focus();
	}

	const pinSpaces = Array.from({ length: length }, (_, i) => i)

	return (
		<Box sx={css.container}>
			{pinSpaces.map((digit, index) => (
				<TextField
					key={index}
					value={value[index] || ''}
					onChange={e => onChangeDigit(e, index)}
					inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
					inputRef={el => inputRefs.current[index] = el}
					maxLength={1}
					onKeyDown={e => e.key === 'Backspace' && onBackspacePress()}

				/>
			))}
		</Box>
	)
}

export default PinInput