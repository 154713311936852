import { Divider } from '@material-ui/core'
import { History, KeyboardArrowDown } from '@material-ui/icons'
import { Box, IconButton, Typography } from '@mui/material'
import moment from 'moment'
import { useContext } from 'react'
import { ChecklistContext } from './ChecklistView'
import useToggle from '../../../Hooks/ToogleHook'
import { useTranslation } from 'react-i18next'

const css = {
	container: {
		padding: 2,
		borderRadius: 2,
		background: 'white',
		marginBottom: 2,
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	inline: {
		display: 'flex',
		alignItems: 'center',
		gap: 1,
	},
	history: {
		maxHeight: 350,
		overflow: 'auto',
	},
	element: {
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'space-between',
		margin: '12px 0',
	},
}

function HistoryElement({ element }) {
	const { t } = useTranslation('checklistShow')
	return (
		<Box sx={css.element}>
			<Box>
				<Typography variant="subtitle2">* {element.title}</Typography>
				<Typography variant="subtitle1">{element.content}</Typography>
				<Box>
					<Typography variant="caption">
						{moment(element.created_at).format(t('secondaryInfo.dateFormat'))}
					</Typography>
				</Box>
			</Box>
		</Box>
	)
}

function ChecklistHistory({ value }) {
	const { checklist } = useContext(ChecklistContext)
	const history = checklist.history || []
	const [open, toggleOpen] = useToggle(false)
	const { t } = useTranslation('checklistShow')

	return (
		<Box sx={css.container}>
			<Box sx={css.header}>
				<Box sx={css.inline}>
					<History />
					<Typography color="secondary" variant="h4">
						{t('secondaryInfo.historyTitle')} {!!history.length && t('secondaryInfo.historyCount', { count: history.length })}
					</Typography>
				</Box>
				<IconButton onClick={toggleOpen}>
					<KeyboardArrowDown />
				</IconButton>
			</Box>
			{open && (
				<Box sx={css.history}>
					{history.map((element, index) => {
						return (
							<Box key={element.id}>
								{index > 0 && <Divider />}
								<HistoryElement element={element} />
							</Box>
						)
					})}
				</Box>
			)}
		</Box>
	)
}

export default ChecklistHistory
