import { SubjectOutlined } from '@material-ui/icons'
import { Box, Paper, Typography } from '@mui/material'
import { createContext, useContext } from 'react'
import { inline } from '../../../Utils/defaultStyles'
import { ChecklistContext } from '../Show/ChecklistView'
import FieldCategory from './Form/FieldCategory'
import IndexField from './Form/IndexField'
import { callSnackbar } from '../../../Utils/snackbar'
import { checkIfAreTheSameFields, formatForm } from '../Show/checklistUtils'
import { transformBodyAndSendCallback } from '../../../Utils/offlineSyncFunctions'
import { useActionContext } from '../../../DatabaseManagers/ActionsManager'
import { useTranslation } from 'react-i18next'

const css = {
	container: {
		padding: 2
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		height: 32
	}
}

export const ChecklistFieldContext = createContext()

function ExtraFields() {
	const { checklist, setChecklist, appendSave, resolveSave, isPublic } = useContext(ChecklistContext)
	const actionContext = useActionContext()
	const { t } = useTranslation('checklistContent')

	const categories = checklist.checklist_field_categories || []

	const fields_key = isPublic ? "checklist_fields" : "fields"
	const raw_fields = checklist[fields_key] || []

	const fields = formatForm(raw_fields, isPublic) || []
	const key = isPublic ? "base_field_option_id" : "checklist_field_option_id"
	const root_fields = fields.filter(field => field[key] === null) || []
	const free_fields = root_fields.filter(field => field.checklist_field_category_id === null) || []

	async function updateFieldLocally(field, showSnackbar = true) {
		const fields_key = isPublic ? "checklist_fields" : "fields"
		const base_fields = checklist[fields_key] || []
		const new_fields = base_fields.map(f => {
			if (checkIfAreTheSameFields(f, field)) return field
			return f
		})
		setChecklist({ ...checklist, [fields_key]: new_fields })
		if (isPublic) return
		appendSave()
		await transformBodyAndSendCallback(field, "answer_field", actionContext, t('actions.answer'))
		resolveSave()

		if (showSnackbar) { callSnackbar(t('answerSaved')) }
	}

	return (
		<Paper sx={css.container}>
			<Box sx={css.header}>
				<Box sx={inline}>
					<SubjectOutlined />
					<Typography variant='h4'>{t('form')}</Typography>
				</Box>
			</Box>
			<ChecklistFieldContext.Provider value={{ updateField: updateFieldLocally, isPublic }}>
				<Box>
					<Box sx={css.fields}>
						<Box>
							{free_fields.map(field => <IndexField field={field} key={field.id} />)}
						</Box>
						{categories.map(category => {
							const category_fields = root_fields.filter(field => String(field.checklist_field_category_id) === String(category.id))
							return (
								<FieldCategory key={category.id} category={category} fields={category_fields} />
							)
						})}
					</Box>
				</Box>
			</ChecklistFieldContext.Provider>
		</Paper>
	)
}

export default ExtraFields