import { Box } from '@mui/material'
import FindingCard from '../../../Findings/Card/FindingCard'
import { end_buttons } from '../../../../Utils/defaultStyles'
import { Button } from '@material-ui/core'
import newTheme from '../../../../newTheme'
import { useTranslation } from 'react-i18next'

const css = {
	findings: {
		background: newTheme.palette.background.main,
		borderRadius: 1,
		padding: 1,
		margin: '12px 0'
	}
}

function ItemFindings({ findings = [], onCreate, disabled }) {
	const { t } = useTranslation('checklistItem')
	if (!findings.length) return null

	return (
		<Box sx={css.findings} >
			{findings.map(finding => {
				const secondImgURL = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_IMG_FOLDER}`
				const firstImgURL = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_FINDING_IMG_FOLDER}`
				return (
					<Box sx={{ marginBottom: 1 }} key={finding.id}>
						<FindingCard
							urls={[firstImgURL, secondImgURL]}
							finding={finding}
						/>
					</Box>
				)
			})}
			<Box sx={end_buttons} style={{ marginTop: 12 }}>
				<Button disabled={disabled} onClick={onCreate} variant="contained" color="primary">
					{t('addOtherFinding')}
				</Button>
			</Box>
		</Box>
	)
}

export default ItemFindings