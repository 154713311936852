import { Box } from '@mui/material'
import { createContext, useEffect, useState } from 'react'

export const BaseChecklistsManagerContext = createContext({})

function BaseChecklistsManager({ children, db }) {

	const [base_checklists, setBaseChecklistsArray] = useState([])

	useEffect(() => {
		getBaseChecklists()
	}, [])

	function addBaseChecklist(request) {
		const db_request = db.transaction(["base_checklists"], "readwrite")
			.objectStore("base_checklists")
			.put(request)

		db_request.onsuccess = function (event) {
			getBaseChecklists()
			console.log("BaseChecklist has been added to the store")
		}

		db_request.onerror = function (event) {
			console.log("Error adding request to the store")
		}
	}

	function addBaseChecklists(base_checklists) {
		const transaction = db.transaction(["base_checklists"], "readwrite")
		const store = transaction.objectStore("base_checklists")

		for (const request of base_checklists) {
			store.put(request)
		}

		transaction.oncomplete = function (event) {
			console.log("BaseChecklists has been added to the store")
			setBaseChecklistsArray(r => [...r, ...base_checklists])
		}

		transaction.onerror = function (event) {
			console.log("Error adding base_checklists to the store")
		}
	}

	function getBaseChecklist(id) {
		if (!db) return null
		const db_request = db.transaction(["base_checklists"]).objectStore("base_checklists").get(id)

		return new Promise((resolve, reject) => {
			db_request.onsuccess = function (event) {
				resolve(db_request.result)
			}

			db_request.onerror = function (event) {
				console.log("Error getting request from the store")
				reject(db_request.error)
			}
		})
	}

	function setBaseChecklists(base_checklists) {
		const transaction = db.transaction(["base_checklists"], "readwrite")
		const store = transaction.objectStore("base_checklists")
		store.clear()

		for (const request of base_checklists) {
			store.put(request)
		}

		transaction.oncomplete = function (event) {
			setBaseChecklistsArray(base_checklists)
			console.log("BaseChecklists has been added to the store")
		}

		transaction.onerror = function (event) {
			console.log("Error adding base_checklists to the store")
		}
	}

	function updateBaseChecklist(base_checklist) {
		if (!db) return null
		const db_request = db.transaction(["base_checklists"], "readwrite").objectStore("base_checklists").put(base_checklist)

		return new Promise((resolve, reject) => {
			db_request.onsuccess = function (event) {
				resolve(db_request.result)
				getBaseChecklists()
			}

			db_request.onerror = function (event) {
				console.log("Error getting request from the store")
				reject(db_request.error)
			}
		})

	}

	async function addOrUpdateBaseChecklists(base_checklists) {
		if (!db) return false
		for (let base_checklist of base_checklists) {
			const exists = await getBaseChecklist(base_checklist.id)
			if (!!exists) {
				updateBaseChecklist(base_checklist)
			} else {
				addBaseChecklist(base_checklist)
			}
		}
	}

	function getBaseChecklists() {
		if (!db) return null
		const objectStore = db.transaction("base_checklists").objectStore("base_checklists")
		const base_checklists_response = []

		return new Promise((resolve, reject) => {
			objectStore.openCursor().onsuccess = function (event) {
				const cursor = event.target.result
				if (cursor) {
					base_checklists_response.push(cursor.value)
					cursor.continue()
				} else {
					setBaseChecklistsArray(base_checklists_response)
					resolve(base_checklists_response)
				}
			}

			objectStore.openCursor().onerror = function (event) {
				console.log("Error getting base_checklists from the store")
				reject(event.target.error)
			}
		})
	}

	function getBaseChecklistsPlain() {
		if (!db) return Promise.resolve([]); // Return an empty array if the db is not available

		const objectStore = db.transaction("base_checklists").objectStore("base_checklists");
		const base_checklists_response = [];

		return new Promise((resolve, reject) => {
			const cursorBaseChecklist = objectStore.openCursor();

			cursorBaseChecklist.onsuccess = function (event) {
				const cursor = event.target.result;
				if (cursor) {
					base_checklists_response.push(cursor.value);
					cursor.continue();
				} else {
					resolve(base_checklists_response);
				}
			};

			cursorBaseChecklist.onerror = function (event) {
				console.log("Error getting base_checklists from the store");
				reject(event.target.error);
			};
		});
	}

	function deleteBaseChecklist(id) {
		if (!db) return null
		const db_request = db.transaction(["base_checklists"], "readwrite").objectStore("base_checklists").delete(id)

		return new Promise((resolve, reject) => {
			db_request.onsuccess = function (event) {
				resolve(db_request.result)
				getBaseChecklists()
			}

			db_request.onerror = function (event) {
				console.log("Error getting request from the store")
				reject(db_request.error)
			}
		})
	}

	return (

		<BaseChecklistsManagerContext.Provider value={{ addOrUpdateBaseChecklists, addBaseChecklist, addBaseChecklists, getBaseChecklist, getBaseChecklists, setBaseChecklists, deleteBaseChecklist, base_checklists, getBaseChecklistsPlain }}>
			{children}
		</BaseChecklistsManagerContext.Provider>
	)
}

export default BaseChecklistsManager