import { Box, Button } from '@mui/material'
import { FindingDialogContext } from '../FindingDialog'
import { useContext, useMemo, useState } from 'react'
import { inline_space } from '../../../../Utils/defaultStyles'
import FindingFormRender from '../../Fields/FindingFormRender'
import { setupForm } from '../../Utils'
import { useTranslation } from 'react-i18next'

function checkSubquestionRequired(question) {
	if (question.field_type !== 'select') return true
	const value = question.value
	const selectedOption = question.options.find(op => op.label === value)
	if (!selectedOption) return false
	const subquestions = selectedOption.subquestions || []
	if (subquestions.length === 0) return true
	const ready = subquestions.filter(subq => subq.required).every(subq => subq.value && checkSubquestionRequired(subq))
	return ready
}

function FormStep({ value }) {

	const { params, setParams, nextStep, prevStep, finding_form } = useContext(FindingDialogContext)
	const { t } = useTranslation("findingCreationDialog")
	const [form, setForm] = useState(finding_form)

	function updateField(new_field) {
		const new_form = [...form]
		const new_field_index = new_form.findIndex(field => field.id === new_field.id)
		new_form[new_field_index] = new_field
		setForm(new_form)
		const json_form = JSON.stringify(new_form)
		setParams({ ...params, form: json_form })
	}

	const parsedForm = useMemo(() => setupForm(form), [form])

	// Check that all required fields are filled
	const ready_form = parsedForm.filter(field => field.required).every(field => field.value && checkSubquestionRequired(field))


	return (
		<Box >
			<FindingFormRender form={parsedForm} onChangeForm={updateField} />
			<Box sx={inline_space}>
				<Button variant="outlined" onClick={prevStep}>
					{t('stepBackwards')}
				</Button>
				<Button variant="contained" color="success" onClick={nextStep} disabled={!ready_form}>
					{t('actions.continue')}
				</Button>
			</Box>
		</Box>
	)
}

export default FormStep