import { Box, Paper, Typography, Button, Dialog } from '@mui/material'
import { useContext, useMemo, useState } from 'react'
import { ChecklistContext } from '../ChecklistView'
import AppContext from '../../../../AppContext'
import { dialog_style, inline_space, loader_container } from '../../../../Utils/defaultStyles'
import { CurrentUserContext } from '../../../../CurrentUser'
import { createFreeChecklist } from '../../../../API/new_checklists'
import { BaseChecklistsManagerContext } from '../../../../DatabaseManagers/BaseChecklistManager'
import { ChecklistManagerContext } from '../../../../DatabaseManagers/ChecklistsManager'
import { transformBodyAndSendCallback } from '../../../../Utils/offlineSyncFunctions'
import { generateOfflineChecklist } from '../checklistUtils'
import { useActionContext } from '../../../../DatabaseManagers/ActionsManager'
import LoaderAnimator from '../../../../Shared/Loader/LoaderAnimator'
import { useTranslation } from 'react-i18next'

function RelatedChecklistDialog({ open, onClose }) {
	const { t } = useTranslation('checklistShow')
	const { checklist, setChecklist } = useContext(ChecklistContext)
	const { online: { status: online }, branch, history } = useContext(AppContext)
	const { currentUser } = useContext(CurrentUserContext)
	const { getBaseChecklist } = useContext(BaseChecklistsManagerContext)
	const { addChecklist } = useContext(ChecklistManagerContext)
	const actionContext = useActionContext()
	const [loading, setLoading] = useState(false)

	const relatedBaseChecklists = useMemo(() => {
		return checklist.related_base_checklists || []
	}, [checklist])

	async function createNow(base_checklist_id) {
		setLoading(true)
		const relatedChecklistInfo = [{ id: checklist.id, name: checklist.name }]
		let newChecklist = null
		if (online) {
			const body = { base_checklist_id, related_checklists: relatedChecklistInfo }
			const response = await createFreeChecklist(body)
			newChecklist = response?.data?.info
		} else {
			const baseChecklist = await getBaseChecklist(base_checklist_id)
			if (!baseChecklist) return alert(t("relatedChecklists.noBaseChecklistFound"))
			newChecklist = generateOfflineChecklist(baseChecklist, null, currentUser.id, relatedChecklistInfo)

			const offlineBody = {
				branch_id: branch.id,
				user_id: currentUser.id,
				base_checklist_id: baseChecklist.id,
				temp_id: newChecklist.id,
				date_scheduled: newChecklist.date_scheduled,
				items: newChecklist.checklist_items2,
				fields: newChecklist.fields,
				item_categories: newChecklist.categories,
				field_categories: newChecklist.checklist_field_categories,
				sign_groups: newChecklist.group_signs,
				related_checklists: relatedChecklistInfo
			}

			await transformBodyAndSendCallback(offlineBody, "create_checklist", actionContext, t('relatedChecklists.create'))

			await addChecklist(newChecklist)
			setChecklist({ ...checklist, related_checklists: [...checklist.related_checklists, { id: newChecklist.id, name: newChecklist.name, status: newChecklist.status }] })
		}
		history.push(`/checklists/${newChecklist?.id}`)
		onClose()
		setLoading(false)
	}

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
			{loading ?
				<Box sx={loader_container}>
					<LoaderAnimator />
				</Box> :
				<Box sx={dialog_style}>
					{relatedBaseChecklists.length > 0 && (
						<>
							<Box sx={{ mb: "24px" }}>
								<Typography variant="h1">{t("relatedChecklists.create")}</Typography>
							</Box>
							<Box sx={{ maxHeight: 300, overflowY: 'auto' }}>
								{relatedBaseChecklists.map((base_checklist, index) => (
									<Box sx={{ ...inline_space, margin: "12px 0", background: 'whitesmoke', padding: 2 }} key={index}>
										<Typography>{base_checklist.name}</Typography>
										<Button color="info" variant="contained" size="small" onClick={() => createNow(base_checklist.id)}>
											{t("relatedChecklists.createNow")}
										</Button>
									</Box>
								))}
							</Box>
						</>
					)}
				</Box>
			}
		</Dialog>
	)
}

export default RelatedChecklistDialog