import { Box, Typography } from '@mui/material'
import ImageContainer from '../../../Shared/Containers/ImageContainer'
import { completePath } from '../../../Utils/functions'
import moment from 'moment'
import { useEffect, useState } from 'react'
import RTE from '../../../Shared/Inputs/RTE'
import { title_style } from '../../../Utils/defaultStyles'
import { useTranslation } from 'react-i18next'

const css = {
	input: {
		'& > *': {
			margin: "0 !important"
		},
		marginBottom: 2
	},
	category: {
		margin: '12px 0',
		padding: 1,
		borderRadius: 1
	}

}

function getArray(value) {
	if (value === "") return []
	return JSON.parse(value)
}

function TextField({ field, value, t }) {
	return (
		<Box sx={css.input}>
			<Typography variant='subtitle1'>{value || t('withoutAnswer')}</Typography>
		</Box>
	)

}

function RutField({ field, value, t }) {
	return (
		<Box sx={css.input}>
			<Typography variant='subtitle1'>{value || t('withoutAnswer')}</Typography>
		</Box>
	)

}

function SelectField({ field, value, t }) {
	const options = field?.options || []
	const selectedOption = options.find(option => option.label === value)
	const subquestions = selectedOption?.subquestions || []
	return (
		<Box sx={css.input}>
			<Typography variant='subtitle1'>{selectedOption ? selectedOption?.label : t('withoutAnswer')}</Typography>
			<Box style={{ padding: '8px 0 0 8px' }}>
				{subquestions.map(subquestion => <Field field={subquestion} />)}
			</Box>
		</Box>
	)


}

function MultiField({ field, value = [], t }) {
	const real_value = Array.isArray(value) ? value : getArray(value)
	return (
		<Box sx={css.input}>
			<Box sx={css.options}>
				{!!real_value?.length ?
					real_value.map(val => <Typography sx={css.option} variant='subtitle1'>{val}</Typography>) :
					<Typography variant='subtitle1'>{t('withoutAnswer')}</Typography>
				}
			</Box>
		</Box>
	)

}

function RTEField({ onChange, value, field, onBlur, t }) {
	return (
		<Box style={{ maxWidth: '100%' }}>
			<RTE disabled onBlur={onBlur} value={value} onChange={onChange} label="" />
		</Box>
	)
}

function ComplianceField({ field, value, t }) {

	const [params, setParams] = useState(value ? { ...value } : {})

	useEffect(() => {
		const newValue = value ? JSON.parse(value) : {}
		setParams({ ...newValue })
	}, [value])

	const ratio = params.total > 0 ? params.desfav / params.total : 0
	const calculatedCompliance = 100 - ratio * 100
	const partialCompliance = params.solved ? calculatedCompliance : 0
	const finalCompliance = params.solved ? 100 : calculatedCompliance

	return (
		<Box sx={css.input}>
			<Typography variant="subtitle1">
				{t('fieldLabel')}: <strong>{params.total}</strong>
			</Typography>
			<Typography variant="subtitle1">
				{t('nonComplianceCases')}: <strong>{params.desfav}</strong>
			</Typography>
			{calculatedCompliance < 100 && (
				<Typography variant="subtitle1">
					{t('complianceObservation')}: <strong>{params.comment}</strong>
				</Typography>
			)}
			{calculatedCompliance < 100 && (
				<Typography variant="subtitle1">
					{t('resolvedOnSite')}: <strong>{params.solved ? t('yes') : t('no')}</strong>
				</Typography>
			)}
			{calculatedCompliance < 100 && (
				<Typography variant="subtitle1">
					{t('partialCompliance')}: <strong>{partialCompliance.toFixed(1)}%</strong>
				</Typography>
			)}
			<Typography variant="subtitle1">
				{t('finalCompliance')}: <strong>{finalCompliance.toFixed(1)}%</strong>
			</Typography>
		</Box>
	)
}

function ImageField({ field, value, t }) {

	const images = typeof value === "string" ? value.split("&#&") : ""
	return (
		<Box sx={css.input}>
			{!!images.length ?
				images.map(image => <ImageContainer border="6px" src={completePath(image, `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_IMG_FOLDER}`)} width={64} />) :
				<Typography variant='subtitle1'>{t('withoutAnswer')}</Typography>
			}
		</Box>
	)
}

function PeriodField({ field, value, t }) {

	function processValue() {
		return value?.split("#") || [moment().format("YYYY-MM-DD hh:mm"), moment().format("YYYY-MM-DD hh:mm")]
	}

	const parsedValue = processValue()

	return (
		<Box sx={css.input}>
			<Typography variant="subtitle1">
				{t('period.from')}: {parsedValue[0]} - {t('period.to')}: {parsedValue[1]}
			</Typography>
		</Box>
	)
}

function DateField({ field, value, t }) {
	return (
		<Box sx={css.input}>
			<Typography variant='subtitle1'>{value || t('withoutAnswer')}</Typography>
		</Box>
	)
}

function NumberField({ field, value, t }) {
	return (
		<Box sx={css.input}>
			<Typography variant='subtitle1'>{value || t('withoutAnswer')}</Typography>
		</Box>
	)
}

function Field({ field }) {
	const { t } = useTranslation('checklistShared')
	let ToRender = TextField
	if (field.type === "text") { ToRender = TextField }
	if (field.type === "rut") { ToRender = RutField }
	if (field.type === "select") { ToRender = SelectField }
	if (field.type === "multiselect") { ToRender = MultiField }
	if (field.type === "rte") { ToRender = RTEField }
	if (field.type === "img") { ToRender = ImageField }
	if (field.type === "ids") return null
	if (field.type === "compliance") { ToRender = ComplianceField }
	if (field.type === "number") { ToRender = NumberField }
	if (field.type === "duration") { ToRender = PeriodField }
	if (field.type === "date") { ToRender = DateField }

	return (
		<Box sx={css.field} >
			<Typography variant='subtitle1'><strong>{`${field.label}${field?.required ? " *" : ""}`}</strong></Typography>
			<ToRender field={field} value={field?.value} t={t} />
		</Box>
	)
}


function RenderReadOnlyFields({ form, categories, supply }) {
	const { t } = useTranslation('checklistShared')
	const freeFields = form.filter(field => !field.checklist_field_category_id)

	return (
		<Box>
			{supply && (
				<Box>
					<Box sx={css.field}>
						<Typography variant="subtitle1">
							<strong>{t('readonlyFields.supplyLabel')}</strong>
						</Typography>
					</Box>
					<Box sx={css.input}>
						<Typography variant="subtitle1">
							{supply.code}-{supply.type}
						</Typography>
					</Box>
				</Box>
			)}
			{!form.length ? (
				<Typography variant="subtitle1">
					{t('readonlyFields.noFields')}
				</Typography>
			) : (
				<>
					{freeFields.map((field) => (
						<Field field={field} key={field.id} />
					))}
					{categories.map((category) => {
						const fields = form.filter(
							(field) => field.checklist_field_category_id === category.id
						)
						if (!fields.length) return null
						return (
							<Box
								key={category.id}
								sx={{ ...css.category, background: category?.color }}
							>
								<Typography variant="h4" sx={title_style}>
									{category.name}
								</Typography>
								{fields.map((field) => (
									<Field field={field} key={field.id} />
								))}
							</Box>
						)
					})}
				</>
			)}
		</Box>
	)
}


export default RenderReadOnlyFields