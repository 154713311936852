import { Box, Button, Typography } from '@mui/material'
import { inline_space, labeless, title_style } from '../../../../Utils/defaultStyles'
import DateTimeInput from '../../../../Shared/Inputs/DateTimeInput'
import { FindingDialogContext } from '../FindingDialog'
import { useContext } from 'react'
import moment from 'moment'
import { calculateBusinessHoursDeadline } from '../../../../Utils/functions'
import { useTranslation } from 'react-i18next'

function DateLimitStep() {

	const { params, setParams, nextStep, prevStep, finding_timings, businessHoursOnly } = useContext(FindingDialogContext)
	const { t } = useTranslation("findingCreationDialog")


	function onChange(event) {
		const { target } = event
		const { name, value } = target
		setParams(p => ({ ...p, [name]: value }))
	}

	function getDefaultTime() {
		const defaultTiming = finding_timings.find(ft => {
			const isFromType = ft.base_finding_type_id === params.base_finding_type_id
			const hasSameCriticality = ft.default_criticality === params.free_criticality
			return isFromType && hasSameCriticality
		})
		return defaultTiming || false
	}

	const defaultTime = getDefaultTime()
	if (params.date_limit === undefined && defaultTime) {
		if (businessHoursOnly)
			setParams(p => ({ ...p, date_limit: calculateBusinessHoursDeadline(moment(), defaultTime.offset_hours) }))
		else setParams(p => ({ ...p, date_limit: moment().add(defaultTime.offset_hours, "hours").format("YYYY-MM-DD HH:mm") }))
	}

	return (
		<Box>
			<Typography variant='h4' color="info.main">{t('data.dateLimit')}</Typography>
			<Typography variant='subtitle1' sx={title_style} >{t('indications.limitSolve')}</Typography>
			<Typography variant='subtitle2' sx={labeless}>{t('indications.selectDateAndHour')}</Typography>
			<DateTimeInput name="date_limit" value={params.date_limit} onChange={onChange} label="" />
			<Box sx={inline_space}>
				<Button variant="outlined" onClick={prevStep}>
					{t('stepBackwards')}
				</Button>
				<Button variant="contained" color="success" onClick={nextStep} disabled={!params.date_limit}>
					{t('actions.continue')}
				</Button>
			</Box>
		</Box>
	)
}

export default DateLimitStep