import { Clear, VpnKey } from '@mui/icons-material'
import { Box, Button, Dialog, IconButton, Typography } from '@mui/material'
import useToggle from '../../Hooks/ToogleHook'
import { dialog_style, inline_title_no_wrap, loader_container } from '../../Utils/defaultStyles'
import MySignaturePad from '../Signature/MySignaturePad'
import { CurrentUserContext } from '../../CurrentUser'
import { useContext, useState } from 'react'
import { addNameToB64, fromBase64ToFile } from '../../Utils/functions'
import { addUserSign, uploadFile } from '../../API/users'
import moment from 'moment'
import { callSnackbar } from '../../Utils/snackbar'
import LoaderAnimator from '../Loader/LoaderAnimator'
import { useTranslation } from 'react-i18next'
const css = {
	pad: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	}
}

function RegisterNewSign({ afterRegister, disabled }) {

	const { currentUser, setCurrentUser } = useContext(CurrentUserContext)
	const [openDialog, toggleDialog] = useToggle(false)
	const [loading, setLoading] = useState(false)
	const { t } = useTranslation("dialogs")

	async function onSave(value) {
		const b64file = addNameToB64(value, currentUser.name + "0")
		setLoading(true)
		const file = await fromBase64ToFile(b64file)
		const file_body = new FormData()
		file_body.append('file', file)
		file_body.append('name', currentUser.name + "0")
		const file_response = await uploadFile(file_body)
		const file_url = file_response.data.info
		const body = {
			user_id: currentUser.id,
			firma: file_url,
			sign_name: currentUser.name,
			date_sign: moment().format('YYYY-MM-DD'),
			url: file_url
		}
		const response = await addUserSign(body)
		const new_sign = response.data.info
		setLoading(false)
		callSnackbar(t('feedback.signAdded'), "success")
		toggleDialog(false)
		afterRegister(new_sign)
		setCurrentUser({ ...currentUser, signs: [...currentUser.signs, new_sign] })
	}

	return (
		<Box>
			<Button color="info" variant="outlined" endIcon={<VpnKey />} onClick={toggleDialog} disabled={disabled}>
				{t('actions.registerSign')}
			</Button>
			<Dialog open={openDialog} onClose={toggleDialog} fullWidth maxWidth="md">
				<Box sx={dialog_style}>
					<Box sx={inline_title_no_wrap}>
						<Typography variant='h4'>{t('actions.registerSign')}</Typography>
						<IconButton onClick={toggleDialog}>
							<Clear />
						</IconButton>
					</Box>

					{loading ?
						<Box sx={loader_container}>
							<LoaderAnimator />
						</Box>
						:
						<Box sx={css.pad}>
							<MySignaturePad
								clearButton="true"
								save={onSave}
								submitText={t('actions.registerSign')}
							/>
						</Box>
					}
				</Box>
			</Dialog>
		</Box>
	)
}

export default RegisterNewSign