import { Box, Button, Typography, useMediaQuery } from '@mui/material'
import { centered_container, inline_space, text_space, title_style } from '../../../../Utils/defaultStyles'
import { useContext, useRef, useState } from 'react'
import { FindingDialogContext } from '../FindingDialog'
import { AddPhotoAlternateOutlined } from '@material-ui/icons'
import { resizeImage } from '../../../../Utils/functions'
import { useTranslation } from 'react-i18next'
import MultipleImageContainer from '../../../../Shared/Containers/MultipleImageContainer'
import PlainFileInput from '../../../../Shared/Inputs/PlainFileInput'
import SelectImage from '../../../../Shared/Inputs/Mobile/SelectImage'

function EvidenceStep() {

	const { params, setParams, nextStep, prevStep, is_evidence_required } = useContext(FindingDialogContext)
	const hidden_folder_file = useRef(null)
	const { t } = useTranslation("findingCreationDialog")
	const isMobile = useMediaQuery('(max-width:850px)')
	const [openDrawer, setOpenDrawer] = useState(false)
	const dialogRef = useRef(null)

	async function addPhoto(event) {
		const { target } = event
		const { files } = target
		const file = files[0]
		const image = await resizeImage(file)
		const file_name = file.name.split('.').slice(0, -1).join('.') // Remove the extension
		const image_with_name = `${image}|&|${file_name}`
		const new_images = [...images]
		new_images.push(image_with_name)
		const new_value = new_images.join("&#&")
		setParams(p => ({ ...p, free_file: new_value }))
	}


	function simulateFolderClick() {
		hidden_folder_file.current.click()
	}

	function photoDrawer() {
		if (isMobile) {
			setOpenDrawer(true)
		}
		else {
			simulateFolderClick()
		}
	}

	function removePhoto(index) {
		const images = params?.free_file?.split("&#&") || []
		const new_images = [...images]
		new_images.splice(index, 1)
		const new_value = new_images.join("&#&")
		setParams(p => ({ ...p, free_file: new_value }))
	}

	function onChange(event) {
		const { target } = event
		setParams((p) => ({ ...p, [target.name]: target.value }))

	}

	const images = params?.free_file?.split("&#&") || []


	const disable_next = is_evidence_required && !images.length

	return (
		<Box ref={dialogRef}>
			<Typography variant='h4' color="info.main">{t('titles.nonComplianceEvidence')}</Typography>
			<Box sx={text_space}>
				<Typography variant='subtitle1' sx={title_style} >{t('messages.evidenceImages')}</Typography>
				<Box sx={text_space}>
					<PlainFileInput name="evidence_pdf_url" value={params?.evidence_pdf_url} onChange={onChange} />
				</Box>
				<Box sx={text_space}>
					<MultipleImageContainer grid images={images} height="164px" width="164px" border="6px" align='left' onRemove={removePhoto} />
				</Box>
				<Box sx={{ ...text_space, ...centered_container }}>
					<Button variant="contained" color="info" onClick={photoDrawer} size="large" fullWidth endIcon={<AddPhotoAlternateOutlined />}>
						{t('actions.addPhotoPlural')}
					</Button>
				</Box>
			</Box>
			<Box sx={inline_space}>
				<Button variant="outlined" onClick={prevStep}>
					{t('stepBackwards')}
				</Button>
				<Button variant="contained" color="success" onClick={nextStep} disabled={disable_next}>
					{t('actions.continue')} {!images.length ? t('noPhotos') : ""}
				</Button>
			</Box>
			<SelectImage open={openDrawer} onClose={() => setOpenDrawer(false)} dialogRef={dialogRef} addPhoto={addPhoto} />
			<input type="file" accept="image/*" id="file" ref={hidden_folder_file} hidden onChange={addPhoto} />
		</Box >
	)
}

export default EvidenceStep