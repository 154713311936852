import { VpnKey } from '@material-ui/icons'
import { Box, Button, Typography } from '@mui/material'
import moment from 'moment'
import { useContext } from 'react'
import { signChecklistGroupRegistered } from '../../../../API/new_checklists'
import { CurrentUserContext } from '../../../../CurrentUser'
import ImageContainer from '../../../../Shared/Containers/ImageContainer'
import { completePath } from '../../../../Utils/functions'
import { ChecklistContext } from '../../Show/ChecklistView'
import MySignaturePad from '../../../../Shared/Signature/MySignaturePad'
import { useTranslation } from 'react-i18next'

const css = {
	container: {
		// background: newTheme.palette.background.main,
		padding: 2,
		borderRadius: 2,
		marginBottom: 2
	},
	sign: {
		background: 'white',
		borderRadius: 1,
		width: 128
	},
	inline: {
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
		marginTop: 2
	},
	disclaim: {
		marginTop: 2
	},
	signPad: {
		display: 'flex',
		justifyContent: 'center'
	},
}

function WorkerSignForm({ group, onManualSign }) {
	const { checklist, setChecklist } = useContext(ChecklistContext)
	const { currentUser } = useContext(CurrentUserContext)
	const hasRegisteredSigns = !!currentUser?.signs?.length
	const sign = hasRegisteredSigns ? currentUser.signs[0] : null
	const old_sign_url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_USER_SIGNS}`
	const signUrl = completePath(sign?.sign, old_sign_url)
	const signs = group.signs || []
	const isSigned = !!signs.filter(s => String(s.user_id) === String(currentUser.id)).length
	const { t } = useTranslation('checklistSigns')

	async function onSign() {
		const body = {
			sign_url: sign?.sign,
			name: currentUser?.name,
			sign_name: currentUser?.name,
			rut: currentUser?.rut,
			checklist_id: checklist?.id,
			checklist_group_sign_id: group?.id,
			user_id: currentUser?.id,
			date: moment().format("YYYY-MM-DD HH:mm")
		}
		const response = await signChecklistGroupRegistered(body)
		setChecklist(response.data.info)
	}

	function signManual(value) {
		onManualSign(value, currentUser?.id)
	}

	function registeredSection() {
		return (
			<Box sx={css.inline}>
				<Box sx={css.sign}>
					<ImageContainer src={signUrl} height={64} width={128} border={"6px"} />
				</Box>
				{!isSigned &&
					<Button color="info" variant="outlined" endIcon={<VpnKey />} onClick={onSign}>
						{t('group.sign')}
					</Button>
				}
			</Box>
		)
	}

	function unregisteredSection() {
		if (isSigned) return null
		return (
			<Box sx={css.signPad}>
				<MySignaturePad
					clearButton="true"
					save={signManual}
				/>
			</Box>
		)
	}

	return (
		<Box sx={css.container}>
			<Typography variant='h4'>{t('group.signingLike')} {currentUser?.name}</Typography>
			{hasRegisteredSigns ? registeredSection() : unregisteredSection()}
			{isSigned && <Typography sx={css.disclaim} color="primary" variant='subtitle1'>{t('group.checklistSigned')}</Typography>}
		</Box>
	)
}

export default WorkerSignForm