import { Close, ErrorOutline } from '@material-ui/icons'
import { Box, IconButton, Slide, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import newTheme from '../../../../newTheme'

const css = {
	container: {
		position: 'fixed',
		bottom: 24,
		right: 24,
		height: 48,
		background: newTheme.palette.error.main,
		borderRadius: 2,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '0 12px',
		gap: 1,
	},
	closeButton: {
		position: 'absolute',
		top: -16,
		right: -16,
		background: newTheme.palette.error.main
	}
}

function ErrorCard({ open, onClose }) {
	const { t } = useTranslation('checklistShow')

	return (
		<Slide in={open} direction="left">
			<Box sx={css.container}>
				<IconButton sx={css.closeButton} onClick={onClose} size="small">
					<Close />
				</IconButton>
				<ErrorOutline style={{ color: 'white', marginRight: 6 }} />
				<Box>
					<Typography variant='subtitle2' color="white.main">{t('cards.errorOccurred')}</Typography>
					<Typography variant='caption' color="white.main">{t('cards.tryAgain')}</Typography>
				</Box>
			</Box>
		</Slide>
	)
}

export default ErrorCard