import { Box, Dialog, IconButton, Typography, useMediaQuery } from '@mui/material'
import { dialog_style, inline_space_title } from '../../Utils/defaultStyles'
import { Clear } from '@material-ui/icons'

function MyDialog({ title, open, onClose, maxWidth, children }) {

	const isMobile = useMediaQuery('(max-width: 600px)')

	return (
		<Dialog open={open} onClose={onClose} maxWidth={maxWidth} fullWidth fullScreen={isMobile}>
			<Box sx={dialog_style}>
				<Box sx={inline_space_title}>
					<Typography variant='h4'>{title}</Typography>
					<IconButton onClick={onClose}>
						<Clear />
					</IconButton>
				</Box>
				{children}
			</Box>
		</Dialog>
	)
}

export default MyDialog