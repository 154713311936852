import React from "react"
import moment from "moment"
import { KeyboardDateTimePicker } from '@material-ui/pickers/'

function Hidden() {
	return null
}

function HiddenDateTimeInput({ value, name, disabled, onBlur, onChange, open, onClose, maxDate }) {
	function handleChange(value) {
		const result = value?.format('YYYY-MM-DD HH:mm') || 'YYYY-MM-DD HH:mm'
		const event = { target: { name, value: result } }
		console.log(event)
		!!onChange && onChange(event)
	}

	return (
		<KeyboardDateTimePicker
			// disableToolbar
			autoOk
			// disablePast
			name={name}
			fullWidth
			onBlur={onBlur}
			disabled={disabled}
			format="YYYY-MM-DD HH:mm"
			inputVariant="outlined"
			value={value || moment().format("YYYY-MM-DD")}
			onChange={handleChange}
			style={{ margin: "12px 0" }}
			open={open}
			onClose={onClose}
			hidden={true}
			TextFieldComponent={Hidden}
			{...maxDate && { maxDate }}
		/>
	)
}

export default HiddenDateTimeInput