import { Box, Typography } from '@mui/material'
import { useContext } from 'react'
import { ChecklistContext } from './ChecklistView'
import newTheme from '../../../newTheme'
import SubdivisionCard from '../../../Shared/Cards/SubdivisionCard'
import { title_style } from '../../../Utils/defaultStyles'
import { useTranslation } from 'react-i18next'

const css = {
	container: {
		padding: 2,
		background: newTheme.palette.white.main,
		borderRadius: 2
	}
}

function ChecklistStructure({ base_subdivision_id }) {

	const { checklist, setChecklist, setNoChecklist } = useContext(ChecklistContext)
	const { t } = useTranslation('checklistShow')

	return (
		<Box sx={css.container}>
			<Typography variant='h4' sx={title_style} >{t('title.location')}</Typography>
			<SubdivisionCard
				subdivision={checklist.subdivision}
				editable
				checklist={checklist}
				setChecklist={setChecklist}
				onRemove={setNoChecklist}
				base_subdivision_id={base_subdivision_id}
				branch_id={checklist.branch_id}
			/>
		</Box>
	)
}

export default ChecklistStructure