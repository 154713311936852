import { Box, Typography, Button, useMediaQuery } from "@mui/material"
import { useContext, useEffect } from "react"
import { ChecklistContext } from "../../Show/ChecklistView"
import IndexField from "./IndexField"
import SelectInput from "../../../../Shared/Inputs/SelectInput"
import { transformToExtraOptions } from "../../../../Utils/functions"
import ImageContainer from "../../../../Shared/Containers/ImageContainer"
import newTheme from "../../../../newTheme"
import { inline_space, loader_container } from "../../../../Utils/defaultStyles"
import { createFreeChecklist } from "../../../../API/new_checklists"
import { generateOfflineChecklist } from "../../Show/checklistUtils"
import { transformBodyAndSendCallback } from "../../../../Utils/offlineSyncFunctions"
import { CurrentUserContext } from "../../../../CurrentUser"
import { BaseChecklistsManagerContext } from "../../../../DatabaseManagers/BaseChecklistManager"
import { ChecklistManagerContext } from "../../../../DatabaseManagers/ChecklistsManager"
import { useActionContext } from "../../../../DatabaseManagers/ActionsManager"
import { useState } from "react"
import AppContext from "../../../../AppContext"
import LoaderAnimator from "../../../../Shared/Loader/LoaderAnimator"
import { getStatusColor } from "../../Show/checklistUtils"
import { useTranslation } from "react-i18next"

const css = {
	image: {
		background: newTheme.palette.background.main,
		padding: 1,
		borderRadius: 0,
	},
	all_checklists: {
		maxHeight: "180px",
		overflowY: "auto",
		backgroundColor: "white"
	},
	checklists: {
		padding: 2,
		margin: "12px 0"
	}
}

function SelectField({ field, value, onChangeAndSave, shouldHighlight }) {
	const options = field?.options || []
	const id = field?.id || 0
	const selectedOption = options.find(option => option.label === value)
	const subquestions = selectedOption?.subquestions || []
	const { checklist, setChecklist, finished, hasPermissions } = useContext(ChecklistContext)
	const { online: { status: online }, branch, history } = useContext(AppContext)
	const { currentUser } = useContext(CurrentUserContext)
	const { getBaseChecklist } = useContext(BaseChecklistsManagerContext)
	const { addChecklist } = useContext(ChecklistManagerContext)
	const actionContext = useActionContext()
	const [loading, setLoading] = useState(false)
	const [createClicked, setCreateClicked] = useState(false)
	const isMobile = useMediaQuery('(max-width: 600px)')
	const { t } = useTranslation('checklistForm')
	const { t: showT } = useTranslation('checklistShow')

	useEffect(() => {
		const element = document.getElementById(`html-message-${id}`)
		if (!!element) {
			element.innerHTML = selectedOption?.message
		}
	}, [selectedOption])

	if (finished || !hasPermissions) return (
		<Box sx={css.input}>
			<Typography variant='subtitle1'>{selectedOption ? selectedOption?.label : t('noAnswer')}</Typography>
			<Box sx={{ padding: '8px 0 0 8px' }}>
				{subquestions.map(subquestion => <IndexField key={subquestion.id} field={subquestion} />)}
			</Box>
		</Box>
	)

	async function createNow(base_checklist_id) {
		setCreateClicked(true)
		setLoading(true)
		const relatedChecklistInfo = [{ id: checklist.id, name: checklist.name, status: checklist.status }]
		let newChecklist = null
		if (online) {
			const body = { base_checklist_id, related_checklists: relatedChecklistInfo }
			const response = await createFreeChecklist(body)
			newChecklist = response?.data?.info
		} else {
			const baseChecklist = await getBaseChecklist(base_checklist_id)
			if (!baseChecklist) return alert(t('select.alert'))
			newChecklist = generateOfflineChecklist(baseChecklist, null, currentUser.id, relatedChecklistInfo)

			const offlineBody = {
				branch_id: branch.id,
				user_id: currentUser.id,
				base_checklist_id: baseChecklist.id,
				temp_id: newChecklist.id,
				date_scheduled: newChecklist.date_scheduled,
				items: newChecklist.checklist_items2,
				fields: newChecklist.fields,
				item_categories: newChecklist.categories,
				field_categories: newChecklist.checklist_field_categories,
				sign_groups: newChecklist.group_signs,
				related_checklists: relatedChecklistInfo
			}

			await transformBodyAndSendCallback(offlineBody, "create_checklist", actionContext, t('actions.createChecklist'))

			await addChecklist(newChecklist)
			setChecklist({ ...checklist, related_checklists: [...checklist.related_checklists, { id: newChecklist.id, name: newChecklist.name, status: newChecklist.status }] })
		}
		openChecklist(newChecklist?.id)
		setLoading(false)
	}

	function openChecklist(id) {
		history.push({ pathname: `/checklists/${id}`, state: { goBackId: checklist.id } })
	}

	const notStartedChecklists = selectedOption?.base_checklists?.filter(option => !checklist.related_checklists.some(related => related.name === option.name))

	function renderChecklist(highligth, isRequired, checklist, onClick) {
		return (
			<Box sx={{ ...inline_space, ...css.checklists, background: highligth ? '#f7c1b6' : 'whitesmoke' }}>
				<Box onClick={isMobile ? () => history.push(`/checklists/${checklist?.id}`) : null}>
					<Typography>{checklist.name}{isRequired ? " *" : ""}</Typography>
					<Typography variant="caption" color={getStatusColor(checklist.status)}>{checklist.status ? showT(`status.${checklist.status}`) : t('select.notStarted')}</Typography>
				</Box>
				{!isMobile && <Button color={checklist.status ? "primary" : "info"} variant="contained" size="small" disabled={createClicked} onClick={onClick}>
					{checklist.status ? t('select.goNow') : t('select.submitNow')}
				</Button>}
			</Box >
		)
	}

	return (
		<Box>
			<SelectInput value={value} onChange={onChangeAndSave} label="" options={transformToExtraOptions(options)} />
			<Box sx={{ background: "white" }}>
				{
					!!selectedOption && selectedOption.message &&
					<Box className="ck-content" id={"html-message-" + id}></Box>
				}
				{
					!!selectedOption && selectedOption?.base_checklists?.length > 0 &&
					<Box sx={css.all_checklists}>
						{notStartedChecklists.map(base_checklist => {
							const isRequired = base_checklist.required || false
							const highligth = shouldHighlight && isRequired && base_checklist.status !== "finished"
							return renderChecklist(highligth, isRequired, base_checklist, () => createNow(base_checklist.id))
						})}
						{checklist.related_checklists.map(checklist => {
							const matchingBaseChecklist = selectedOption.base_checklists.find(base => base.name === checklist.name)
							const isRequired = matchingBaseChecklist?.required || false
							const highligth = shouldHighlight && isRequired && checklist.status !== "finished"
							return renderChecklist(highligth, isRequired, checklist, () => openChecklist(checklist.id))
						})}
					</Box>
				}
				{
					!!selectedOption && selectedOption.photo &&
					<Box sx={css.image}>
						<ImageContainer src={selectedOption.photo} alt="FOTO" height={64} width={64} border={'4px'} />
					</Box>
				}
				<Box sx={{ padding: '8px 0 0 8px' }}>
					{subquestions.map(subquestion => <IndexField key={subquestion.id} field={subquestion} />)}
				</Box>
			</Box>
			{loading && <LoaderAnimator sx={loader_container} />}
		</Box >
	)
}

export default SelectField