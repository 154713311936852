import axios from "axios";

const fingerprint_api_base_url = `${process.env.REACT_APP_FINGERPRINT_URL}/api`;

export function enrollUserFingerprint(body) {
	return axios.post(`${fingerprint_api_base_url}/enroll`, body);
}

export function identifyUserFingerprint(body) {
	return axios.post(`${fingerprint_api_base_url}/identify`, body);
}

export function checkIfEnrolled(body) {
	return axios.post(`${fingerprint_api_base_url}/check`, body);
}

function base64ToHex(base64) {
	var binary = atob(base64);
	var buffer = new ArrayBuffer(binary.length);
	var view = new Uint8Array(buffer);

	for (var i = 0; i < binary.length; i++) {
		view[i] = binary.charCodeAt(i);
	}

	var dataView = new DataView(buffer);
	var hex = '';
	for (var i = 0; i < dataView.byteLength; i++) {

		var value = dataView.getUint8(i);
		var stringValue = value.toString(16);
		if (stringValue.length === 1) {
			stringValue = '0' + stringValue;
		}

		hex += stringValue;
	}

	return hex;
}

function base64UrlDecode(data) {
	var base64 = data.replace(/-/g, '+').replace(/_/g, '/');
	var padding = '='.repeat((4 - base64.length % 4) % 4);
	var base64WithPadding = base64 + padding;
	return base64WithPadding;
}

export function getHexValueFromAcquisition(data) {
	const sample = data.samples[0].Data;
	const binary = atob(sample);
	const binary_data = JSON.parse(binary);
	const more_data = binary_data.Data;
	const format = binary_data.Format;
	const height = format.iHeight;
	const width = format.iWidth;
	const dpi = format.iXdpi;
	const more_data_binary = base64UrlDecode(more_data);
	const hex = base64ToHex(more_data_binary);
	return { hex, width, height, dpi, image: more_data };
}

export function getImageFromAquisition(data) {
	const sample = data.samples[0]
	const more_data_binary = base64UrlDecode(sample);
	return more_data_binary
}