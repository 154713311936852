import { Box, Paper, Typography } from '@mui/material'
import UsersInput from '../../../../Shared/Inputs/UsersInput'
import { AccessTimeOutlined, CheckCircleOutline, WifiOff } from '@material-ui/icons'
import { useContext } from 'react'
import { ChecklistContext } from '../../Show/ChecklistView'
import AppContext from '../../../../AppContext'
import { UserSelectContext } from '../../../../Shared/Dialogs/UserSelectDialog'
import newTheme from '../../../../newTheme'
import { title_style } from '../../../../Utils/defaultStyles'
import { useTranslation } from 'react-i18next'

const css = {
	paper: {
		padding: 2
	},
	title: {
		margin: "12px 0"
	},
	subtitle: {
		margin: '24px 0 12px 0'
	},
	participants: {
		padding: 2,
		background: newTheme.palette.blue.light,
		borderRadius: 2
	},
	participant: {
		display: 'flex',
		alignItems: 'center',
		margin: '6px 0'
	}
}

function InviteUsers({ setSelected, participants, selected, onParticipantsSelection, allSigns }) {

	const { checklist } = useContext(ChecklistContext)
	const { online: { status: online } } = useContext(AppContext)
	const { getUsersNames } = useContext(UserSelectContext)
	const { t } = useTranslation('checklistSigns')

	return (
		<Paper sx={css.paper}>

			<UsersInput label={t('workers')} onChange={e => setSelected(e.target.value)} value={selected} branch_id={String(checklist.branch_id)} isMultiple onBlur={onParticipantsSelection} />

			{!!participants.length &&
				<Box sx={css.participants}>
					<Typography sx={title_style} variant='h4'>{t('requests.title')}</Typography>
					{participants.map(user => {
						const hasSigned = allSigns.includes(String(user))
						let checkIcon = hasSigned ?
							<CheckCircleOutline style={{ color: '#6a6a6a', marginRight: 6 }} /> :
							<AccessTimeOutlined style={{ color: '#6a6a6a', marginRight: 6 }} />
						checkIcon = online ? checkIcon : <WifiOff style={{ color: '#6a6a6a', marginRight: 6 }} />
						return (
							<Box sx={css.participant}>
								{checkIcon}
								<Typography variant='subtitle1'>{getUsersNames(user)}</Typography>
							</Box>
						)
					})}
				</Box>
			}
			{!online && <Typography variant='caption'>{t('requests.offlineMessage')}</Typography>}
		</Paper>
	)
}

export default InviteUsers