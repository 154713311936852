import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import newTheme from '../../../../newTheme'
import { Check, Clear } from '@material-ui/icons'
import FindingCard from '../../../Findings/Card/FindingCard'
import ImageContainer from '../../../../Shared/Containers/ImageContainer'
import { completePath } from '../../../../Utils/functions'
import { checklistsUrl } from '../../../../Utils/baseUrls'
import ItemHTMLContent from './ItemHTMLContent'
import useToggle from '../../../../Hooks/ToogleHook'
import { clickable, inline_buttons } from '../../../../Utils/defaultStyles'
import { InfoOutlined } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useContext, useMemo, useRef, useEffect } from 'react'
import { ChecklistContext } from '../../Show/ChecklistView'
import AnnouncementIcon from '@mui/icons-material/Announcement'
import RejectionCommentDialog from '../RejectionCommentDialog'
import '../animations.css'

const css = {
	findings: {
		background: newTheme.palette.background.main,
		borderRadius: 1,
		padding: 1,
		margin: '12px 0'
	},
	images: {
		display: 'flex',
		alignItems: 'center',
		gap: 2,
		justifyContent: 'flex-end',
		margin: '12px 0'
	},
	imageContainer: {
		borderRadius: 2,
		border: '1px dashed black',
		height: 68,
		width: 68,
		position: 'relative'
	},
	item: {
		background: 'white',
		borderRadius: 2,
		padding: 2,
		margin: '12px 0',
		width: '100%',
		position: 'relative'
	},
	itemContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%'
	},
	floatinIcon: {
		position: 'absolute',
		top: 8,
		right: 8
	}
}

function FinishedItem({ item }) {

	const [openContent, toggleContent] = useToggle(false)
	const { t } = useTranslation('checklistItem')
	const { rejecting, rejectionComment, setRejectionComment, highlighted, rejected } = useContext(ChecklistContext)
	const answer = item.answer
	const colors = [newTheme.palette.green.dark, newTheme.palette.error.main, newTheme.palette.info.main]
	const findings = item.findings || []
	const [openRejectDialog, toggleRejectDialog] = useToggle(false)
	const shouldHighlight = useMemo(() => highlighted?.element?.id === item?.id && highlighted?.type === "item", [highlighted, item])
	const mainItem = useRef(null)

	const itemComment = useMemo(() => {
		const foundComment = rejectionComment
			.split(";")
			.find(comment => comment.startsWith(`item-${item.id}:`)) || ""

		return foundComment.replace(`item-${item.id}:`, "").trim()
	}, [rejectionComment, item])

	useEffect(() => {
		if (shouldHighlight) {
			mainItem.current.scrollIntoView({ behavior: "smooth", block: "center" })
		}
	}, [shouldHighlight, mainItem])

	const icons = [
		<Check sx={{ color: newTheme.palette.green.dark, marginRight: 6 }} />,
		<Clear sx={{ color: newTheme.palette.error.main, marginRight: 6 }} />,
		<Typography sx={{ color: newTheme.palette.info.main, marginRight: 6 }} variant='subtitle2'>[N/A]</Typography>
	]

	function handleRejectComment(text) {
		const newComment = `item-${item.id}:${text}`
		toggleRejectDialog()
		setRejectionComment(prevComment => {
			let comments = prevComment.split(";").filter(comment => comment.trim() !== "")
			const existingIndex = comments.findIndex(comment => comment.startsWith(`item-${item.id}:`))
			if (existingIndex !== -1) {
				comments[existingIndex] = newComment
			} else {
				comments.push(newComment)
			}
			return `;${comments.join(";")}`
		})
	}

	return (
		<Box sx={css.itemContainer} className={shouldHighlight ? "highlight_background" : ""} ref={mainItem}>
			<Box sx={css.item}>
				<Box>
					{icons[answer.value_id - 1]}
					<Typography variant='h4' sx={{ color: colors[answer.value_id - 1] }}>{item.name}</Typography>
				</Box>
				{!!item?.content &&
					<Box sx={{ ...inline_buttons, ...clickable, margin: '6px 0' }} onClick={toggleContent}>
						<InfoOutlined sx={{ height: 18, width: 18, color: newTheme.palette.grey.medium }} />
						<Typography variant='caption'>{t('viewInfo')}</Typography>
					</Box>
				}
				{openContent && <ItemHTMLContent item={item} />}

				<Box sx={css.images}>
					{!!answer.file &&
						<Box sx={css.imageContainer}>
							<ImageContainer height={68} width={68} border={'6px'} src={completePath(answer.file, checklistsUrl) || "/noimage.png"} />
						</Box>
					}
					{!!answer.file2 &&
						<Box sx={css.imageContainer}>
							<ImageContainer height={68} width={68} border={'6px'} src={completePath(answer.file2, checklistsUrl) || "/noimage.png"} />
						</Box>
					}
					{!!answer.file3 &&
						<Box sx={css.imageContainer}>
							<ImageContainer height={68} width={68} border={'6px'} src={completePath(answer.file3, checklistsUrl) || "/noimage.png"} />
						</Box>
					}
				</Box>
				{!!findings.length &&
					<Box sx={css.findings}>
						{findings.map(finding =>
							<Box sx={{ margin: '8px 0' }}>
								<FindingCard finding={finding} />
							</Box>
						)}
					</Box>
				}
				<Box>
					<Typography variant='subtitle1'><strong>Comentarios:</strong> {answer.comments || t('withoutComments')}</Typography>
				</Box>
				{rejecting &&
					<Box sx={css.floatinIcon}>
						<RejectionCommentDialog field={item} field_type='item' />
					</Box>
				}
			</Box>

		</Box>
	)
}

export default FinishedItem