import { Box, Chip, Typography, IconButton, TextField } from '@mui/material'
import { useContext, useState, useEffect } from 'react'
import { ChecklistContext } from '../ChecklistView'
import { Clear, HelpOutline } from '@mui/icons-material'
import { elliptical, flex_center, inline_space_no_wrap } from '../../../../Utils/defaultStyles'
import useToggle from '../../../../Hooks/ToogleHook'
import InstructionsDialog from './InstructionsDialog'
import { useTranslation } from 'react-i18next'
import SeamlessInput from '../../../../Shared/Inputs/SeamlessInput'

const css = {
	sectionTitle: {
		fontWeight: 'bold',
		fontSize: '18px',
		marginBottom: '8px',
		marginTop: '16px'
	},
	commentRow: {
		...flex_center,
		justifyContent: 'flex-start',
		padding: '6px 12px',
		border: '1px solid lightgrey',
		borderRadius: '8px',
		background: 'white',
		gap: '12px',
		width: '100%'
	},
	chip: {
		cursor: 'pointer',
		maxWidth: '150px',
		minWidth: '150px',
		...elliptical,
	},
	commentText: {
		flexGrow: 1,
		wordBreak: 'break-word'
	},
}

const findSubquestionLabel = (fields, fieldId) => {
	for (const field of fields) {
		if (field.id.toString() === fieldId) {
			return field.label
		}
		for (const option of field.options || []) {
			for (const subquestion of option.subquestions || []) {
				const foundLabel = findSubquestionLabel([subquestion], fieldId)
				if (foundLabel) return `${field.label} > ${foundLabel}`
			}
		}
	}
	return null
}

function RejectionCommentField({ motive, items, fields, editable = true }) {
	const { setHighlighted, setRejectionComment } = useContext(ChecklistContext)
	const [openInstructions, toggleInstructions] = useToggle(false)

	const [editableComments, setEditableComments] = useState({})
	const { t } = useTranslation('checklistRejection')

	useEffect(() => {
		if (motive) {
			const initialComments = {}
			motive.split(';').forEach(comment => {
				const [id, message] = comment.split(':')
				if (id && message) initialComments[id] = message
			})
			setEditableComments(initialComments)
		}
	}, [motive])

	if (!motive) return null

	const comments = motive.split(';').filter(comment => comment.trim() !== '')

	const formFields = comments
		.filter(comment => comment.startsWith('field-'))
		.map(comment => {
			const [id, message] = comment.replace('field-', '').split(':')
			let label = findSubquestionLabel(fields, id) || `Field-${id}`
			return { id, message, label }
		})

	const checklistItems = comments
		.filter(comment => comment.startsWith('item-'))
		.map(comment => {
			const [id, message] = comment.replace('item-', '').split(':')
			const itemFound = items?.find(item => item.id.toString() === id)
			const label = itemFound ? itemFound.name : `Item-${id}`
			return { id, message, label }
		})

	const handleDeleteComment = (id, type) => {
		const prefix = type === "field" ? "field-" : "item-"
		const newMotive = comments.filter(comment => !comment.startsWith(`${prefix}${id}:`)).join(";")
		setRejectionComment(newMotive)
	}


	function handleChipClick(id, type) {
		setHighlighted({ element: { id: parseInt(id) }, type })
	}

	const handleCommentChange = (id, type, newValue) => {
		const key = `${type}-${id}`

		setEditableComments(prev => ({ ...prev, [key]: newValue }))

		const prefix = type === "field" ? "field-" : "item-"
		const newComments = comments.map(comment => {
			if (comment.startsWith(`${prefix}${id}:`)) {
				return `${prefix}${id}:${newValue}`
			}
			return comment
		}).join(";")

		setRejectionComment(newComments)
	}

	function renderCommentSection(type, data, editable) {
		return (
			<>
				<Typography variant="subtitle1">
					{type === "field" ? "Formulario" : "Checklist"}
				</Typography>
				{data.map(({ id, label }) => (
					<Box key={id} sx={{ ...inline_space_no_wrap, marginBottom: '8px' }}>
						<Box sx={css.commentRow}>
							<Chip
								label={label}
								sx={css.chip}
								color={type === "field" ? "primary" : "secondary"}
								clickable onClick={() => handleChipClick(id, type)}
							/>
							<SeamlessInput
								disabled={!editable}
								fullWidth
								color='transparent'
								variant="subtitle1"
								value={editableComments[`${type}-${id}`] ?? ""}
								onChange={(e) => handleCommentChange(id, type, e.target.value)}
							/>
						</Box>
						{editable && (
							<Box>
								<IconButton onClick={() => handleDeleteComment(id, type)}>
									<Clear color="error" />
								</IconButton>
							</Box>
						)}
					</Box>
				))}
			</>
		)
	}

	return (
		<Box>
			<Box sx={inline_space_no_wrap}>
				<Typography variant="h4" sx={!editable && { marginTop: 2, marginBottom: 2 }}>{t('titles.rejectionComments')}</Typography>
				{editable &&
					<IconButton onClick={toggleInstructions}>
						<HelpOutline color="primary" />
					</IconButton>
				}
			</Box>
			{formFields.length > 0 || checklistItems.length > 0 ?
				<>
					{formFields.length > 0 && renderCommentSection("field", formFields, editable)}
					{checklistItems.length > 0 && renderCommentSection("item", checklistItems, editable)}
				</>
				:
				<Typography variant="body1">{t('indicators.noRejectionComments')}</Typography>
			}
			<InstructionsDialog open={openInstructions} onClose={toggleInstructions} />
		</Box>
	)
}

export default RejectionCommentField
