import { Box, Typography } from '@mui/material'
import newTheme from '../../../../newTheme'

const css = {
	container: {
		padding: 2,
		background: newTheme.palette.white.main,
		borderRadius: 2,
		margin: '12px 0',
		display: 'flex',
		overflow: 'auto',
		gap: 2
	},
	group: {
		background: newTheme.palette.background.main,
		padding: 2,
		borderRadius: 1,
		minWidth: 'fit-content',
		cursor: 'pointer',
		transition: 'all 0.2s ease-in-out',
		'&:hover': {
			background: newTheme.palette.background.dark,
			transform: 'scale(1.1)',
		}
	},
	selected: {
		background: newTheme.palette.primary.main,
		padding: 2,
		borderRadius: 1,
		minWidth: 'fit-content',
		cursor: 'pointer',
		transition: 'all 0.2s ease-in-out',
		'&:hover': {
			background: newTheme.palette.background.dark,
			transform: 'scale(1.1)',
		}
	}
}

function CategoryGroups({ groups = [], selected, onSelect }) {

	if (!groups.length) return null

	function onClick(group) {
		return () => {
			onSelect(group)
		}
	}

	return (
		<Box sx={css.container}>
			{groups.map((group, index) => {
				const imSelected = selected?.id === group.id
				return (
					<Box key={group.id} sx={imSelected ? css.selected : css.group} onClick={onClick(group)}>
						<Typography variant='h4' color={imSelected ? "white.main" : "primary"} >{group.name}</Typography>
					</Box>
				)
			})}
		</Box>
	)
}

export default CategoryGroups