import { AssignmentTurnedInOutlined, FileCopyOutlined, MoreVert } from '@material-ui/icons'
import { Box, IconButton, Menu, MenuItem, Paper, Typography, } from '@mui/material'
import { useContext, useEffect, useRef, useState } from 'react'
import AppContext from '../../../AppContext'
import useToggle from '../../../Hooks/ToogleHook'
import newTheme from '../../../newTheme'
import { inline } from '../../../Utils/defaultStyles'
import { ChecklistContext } from '../Show/ChecklistView'
import SubtitleNotificationBell from './Item/SubtitleNotificationBell'
import FinishedItemsCategory from './Item/FinishedItemsCategory'
import ItemsCategory from './Item/ItemsCategory'
import CategoryGroups from './Item/CategoryGroups'
import { v4 as uuid } from 'uuid'
import { deepClone } from '../../../Utils/functions'
import CategoryGroupNameEdit from './Item/CategoryGroupNameEdit'
import { transformBodyAndSendCallback } from '../../../Utils/offlineSyncFunctions'
import { useActionContext } from '../../../DatabaseManagers/ActionsManager'
import { useTranslation } from 'react-i18next'

const css = {
	container: {
		padding: 2
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
		gap: 2,
	},
	inline: {
		display: 'flex',
		alignItems: 'center'
	},
	item: {
		background: 'white',
		borderRadius: 2,
		padding: 2,
		margin: '12px 0'
	},
	option_buttons: {
		display: 'flex',
		flexDirection: 'row',
		gap: 2,
		'@media (max-width:600px)': {
			flexDirection: 'column',
		}
	},
	item_header: {
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'space-between'
	},
	images: {
		display: 'flex',
		alignItems: 'center',
		gap: 2,
		justifyContent: 'flex-end',
		margin: '12px 0'
	},
	imageContainer: {
		borderRadius: 2,
		border: '1px dashed black',
		height: 68,
		width: 68,
		position: 'relative'
	},
	image_button: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)'
	},
	delete_image_button: {
		position: 'absolute',
		left: '65%',
		top: '65%',
		background: 'white',
		opacity: 0.8
	},
	category: {
		margin: '30px 0 6px 0'
	},
	users: {
		background: 'white',
		borderRadius: 2,
		position: 'relative',
		padding: 1,
		margin: '6px 0'
	},
	users_not: {
		position: 'absolute',
		top: 6,
		right: 6
	},
	option_button: {
		padding: "8px 24px"
	},
	criticality: {
		margin: '12px 0',
		textAlign: 'end',
		'& > button': {
			borderRadius: 24
		}
	},
	icons: {
		blue: { color: newTheme.palette.blue.main, marginRight: 6 },
		yellow: { color: newTheme.palette.middle.main, marginRight: 6 },
		orange: { color: newTheme.palette.orange.main, marginRight: 6 },
		red: { color: newTheme.palette.error.main, marginRight: 6 },
	},
	all_button: {
		textAlign: 'end',
		margin: '12px 0'
	},
	user: {
		padding: 1
	},
	findings: {
		background: newTheme.palette.background.main,
		borderRadius: 1,
		padding: 1,
		margin: '12px 0'
	}
}

function getSubtitleItems(subtitle, items) {
	return items.filter(item => item.checklist_category_id === subtitle.id)
}

function sortCategories(cat1, cat2) {
	if (!!cat1.checklist_category_order && !cat2.checklist_category_order) return 1
	if (!cat1.checklist_category_order && !!cat2.checklist_category_order) return -1
	if (!cat1.checklist_category_order && !cat2.checklist_category_order) return 0
	if (cat1.checklist_category_order > cat2.checklist_category_order) return 1
	if (cat1.checklist_category_order < cat2.checklist_category_order) return -1
	if (cat1.checklist_category_order === cat2.checklist_category_order) {
		if (cat1.id > cat2.id) return 1
		if (cat1.id < cat2.id) return -1
	}
	return 0
}

function ItemsContainer() {
	const [selectedGroup, setSelectedGroup] = useState(null)
	const [openMenu, toggleMenu] = useToggle(false)
	const menu = useRef(null)
	const { checklist, finished, hasPermissions, responsible, isPublic, setChecklist, appendSave, resolveSave, highlighted } = useContext(ChecklistContext)
	const actionContext = useActionContext()
	const { t } = useTranslation('checklistContent')

	useEffect(() => {
		if (!selectedGroup && !!checklist.category_groups?.length) {
			setSelectedGroup(checklist.category_groups[0])
		}
	}, [checklist, selectedGroup])

	async function onCreateCategoryGroup() {
		const new_checklist = deepClone(checklist)

		// If there is no groups yet we create a placeholder group for the original categories
		const groups = checklist.category_groups || []
		let base_category_group_id = null
		if (!groups.length) {
			const new_category_group_id = uuid()
			const new_category_group = {
				id: new_category_group_id,
				temp_id: new_category_group_id,
				checklist_id: checklist.id,
				name: `${checklist?.checklist_category_group_name || "Hoja"} 1`,
			}

			// update the original categories to have the new group id
			const new_categories = new_checklist.categories.map(cat => {
				return {
					...cat,
					checklist_category_group_id: new_category_group.id
				}
			})
			new_checklist.category_groups.push(new_category_group)
			new_checklist.categories = new_categories
			base_category_group_id = new_category_group.id
			setChecklist(new_checklist)
		}

		const new_group_index = !!groups.length ? groups.length + 1 : 2
		const new_group_name = `${checklist?.checklist_category_group_name || "Hoja"} ${new_group_index}`
		const new_category_group_id = uuid()
		const new_category_group = {
			id: new_category_group_id,
			temp_id: new_category_group_id,
			checklist_id: checklist.id,
			name: new_group_name,
		}
		// Clone all categories and items from the original checklist
		const new_items = []

		// Filter cats to be on original checklist

		const original_categories = checklist.categories.filter(cat =>
			cat.checklist_category_group_id === null ||
			cat.checklist_category_group_id === checklist.category_groups[0]?.id
		)

		const categories = original_categories.map(category => {
			const category_temp_id = uuid()
			const items = [...checklist.checklist_items2.filter(item => item.checklist_category_id === category.id)]
			const new_category_items = []
			for (const item of items) {
				const new_item_id = uuid()
				const new_item = {
					...item,
					id: new_item_id,
					temp_id: new_item_id,
					checklist_category_id: category_temp_id,
				}
				// Clone item answer
				const new_item_answer = { ...item.answer }

				// Clean the answer set everything to null
				const clean_item_answer = Object.keys(new_item_answer).reduce((acc, key) => {
					acc[key] = null
					return acc
				}, {})

				clean_item_answer.id = uuid()
				clean_item_answer.temp_id = clean_item_answer.id
				clean_item_answer.value_id = null
				clean_item_answer.value = null
				clean_item_answer.checklist_item_id = new_item.id
				new_item.answer = clean_item_answer

				new_items.push(new_item)
				new_category_items.push(new_item)
			}

			return {
				...category,
				temp_id: category_temp_id,
				id: category_temp_id,
				checklist_category_group_id: new_category_group.id,
				items: new_category_items
			}
		})

		// Push the new category group to the checklist
		new_checklist.category_groups.push(new_category_group)
		new_checklist.categories.push(...categories)
		new_checklist.checklist_items2.push(...new_items)
		setChecklist(new_checklist)
		const body = {
			checklist_id: checklist.id,
			category_group: new_category_group,
			categories,
			items: new_items,
			base_category_group_id
		}

		// Now the endpoint
		if (isPublic) return


		appendSave()
		await transformBodyAndSendCallback(body, "create_sheet", actionContext, t('actions.createSheet'))
		resolveSave()

	}

	async function onUpdateGroup(group) {
		const new_checklist = deepClone(checklist)
		const new_groups = new_checklist.category_groups.map(g => g.id === group.id ? group : g)
		new_checklist.category_groups = new_groups
		setChecklist(new_checklist)

		// Now the endpoint
		if (isPublic) return

		appendSave()
		await transformBodyAndSendCallback(group, "update_sheet", actionContext, t('actions.updateSheet'))
		resolveSave()

	}

	async function onDeleteGroup(id) {
		const new_checklist = deepClone(checklist)
		const new_groups = new_checklist.category_groups.filter(g => g.id !== id)
		new_checklist.category_groups = new_groups

		// Remove categories
		const new_categories = new_checklist.categories.filter(cat => cat.checklist_category_group_id !== id)
		new_checklist.categories = new_categories

		// Remove items
		const removed_categories = checklist.categories.filter(cat => cat.checklist_category_group_id === id)
		const removed_categories_id = removed_categories.map(cat => cat.id)
		const new_items = new_checklist.checklist_items2.filter(item => !removed_categories_id.includes(item.checklist_category_id))
		new_checklist.checklist_items2 = new_items

		setChecklist(new_checklist)

		// if selected is deleted change to first group
		if (selectedGroup.id === id) setSelectedGroup(new_groups[0])

		// Now the endpoint
		if (isPublic) return

		appendSave()
		await transformBodyAndSendCallback({ id }, "remove_sheet", actionContext, t('actions.deleteSheet'))
		resolveSave()
	}

	useEffect(() => {
		const highlighted_category = checklist.categories?.find(cat => cat.id === highlighted?.element?.checklist_category_id)
		const highlighted_group = checklist.category_groups?.find(group => group.id === highlighted_category?.checklist_category_group_id)
		if (!!highlighted_group) {
			setSelectedGroup(highlighted_group)
		}
	}, [highlighted, checklist.categories, checklist.category_groups])

	let groups = checklist.category_groups || []
	const items = checklist.checklist_items2 || []
	const categories = checklist.categories || []

	// Filter by group (selectedGroup)
	const filtered_categories = categories.filter(cat =>
		cat.checklist_category_group_id === selectedGroup?.id ||
		cat.checklist_category_group_id === null
	)
	const categories_id = categories.map(s => s.id)
	const orphan_items = items.filter(item => !categories_id.includes(item.checklist_category_id))
	const { online: { status: online } } = useContext(AppContext)
	const canSendNotifications = hasPermissions && online && !isPublic
	const canCloneSheet = hasPermissions && !isPublic && !finished && checklist.allow_sheet_clone
	const sorted_categories = filtered_categories.sort(sortCategories)

	return (
		<Box>
			<Paper sx={css.container}>
				<Box sx={css.header}>
					<Box sx={inline}>
						<AssignmentTurnedInOutlined />
						<Typography variant='h4'>Checklist</Typography>
					</Box>
					<Box>
						{canCloneSheet &&
							<IconButton onClick={toggleMenu} ref={menu}>
								<MoreVert />
							</IconButton>
						}
						{canSendNotifications &&
							<SubtitleNotificationBell checklist={checklist} categories={sorted_categories} responsible={responsible} />
						}
					</Box>
				</Box>
			</Paper>
			<CategoryGroups groups={groups} onSelect={setSelectedGroup} selected={selectedGroup} />
			{!!groups.length && <CategoryGroupNameEdit group={selectedGroup} deletable={groups.length > 1 && !finished} onUpdateGroup={onUpdateGroup} onDeleteGroup={onDeleteGroup} />}
			<Box>
				{sorted_categories.map(category => finished ?
					<FinishedItemsCategory key={category.id} category={category} items={getSubtitleItems(category, items)} /> :
					<ItemsCategory key={category.id} category={category} items={getSubtitleItems(category, items)} />
				)}
				{finished ?
					<FinishedItemsCategory category={{ name: t('noCategory'), id: 0 }} items={orphan_items} /> :
					<ItemsCategory category={{ name: t('noCategory'), id: 0 }} items={orphan_items} />
				}
			</Box>
			<Menu open={openMenu} onClose={toggleMenu} anchorEl={menu.current} >
				<MenuItem sx={inline} onClick={onCreateCategoryGroup}>
					<FileCopyOutlined color="info.main" />
					<Typography color="info.main" variant='subtitle2'>{t('addSheet')}</Typography>
				</MenuItem>
			</Menu>
		</Box>
	)
}

export default ItemsContainer