import { Badge, Box, Divider, IconButton, Tooltip, Typography } from '@mui/material'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import TextField from './TextField'
import { inline_space_end_no_wrap, title_style } from '../../../../Utils/defaultStyles'
import RutField from './RutField'
import SelectField from './SelectField'
import MultiField from './MultiField'
import RTEField from './RTEField'
import ImageField from './ImageField'
import IDSField from './IDSField'
import ComplianceField from './ComplianceField'
import NumberField from './NumberField'
import PeriodField from './PeriodField'
import DateField from './DateField'
import { ChecklistFieldContext } from '../ExtraFields'
import { ChecklistContext } from '../../Show/ChecklistView'
import LocationField from './LocationField'
import UserField from './UserField'
import MultiUserField from './MultiUserField'
import ContractorField from './ContractorField'
import MultiContractorField from './MultiContractorField'
import CalcField from './CalcField'
import DocumentField from './DocumentField'
import AnnouncementIcon from '@mui/icons-material/Announcement'
import useToggle from '../../../../Hooks/ToogleHook'
import RejectionCommentDialog from '../RejectionCommentDialog'
import '../animations.css'
import BigTooltip from '../../../../Shared/Tooltip/BigTooltip'

const css = {
	field: {
		'& > * > *': {
			margin: "0 !important"
		}
	},
}

function IndexField({ field: data }) {
	const { highlighted, setHighlighted, rejecting } = useContext(ChecklistContext)
	const { updateField } = useContext(ChecklistFieldContext)
	const [field, setField] = useState({ ...data })


	const mainField = useRef(null)

	function onChange(event) {
		const { target } = event
		const new_field = { ...field }
		new_field.value = target.value
		setField(new_field)
		setHighlighted && setHighlighted(null)
	}

	function onChangeAndSave(event) {
		const { target } = event
		const new_field = { ...field }
		new_field.value = target.value
		setField(new_field)
		updateField(new_field)
	}

	function onBlur() {
		updateField(field)
	}

	let FieldComponent = TextField
	if (field.field_type === "text") { FieldComponent = TextField }
	if (field.field_type === "rut") { FieldComponent = RutField }
	if (field.field_type === "select") { FieldComponent = SelectField }
	if (field.field_type === "multiselect") { FieldComponent = MultiField }
	if (field.field_type === "rte") { FieldComponent = RTEField }
	if (field.field_type === "img") { FieldComponent = ImageField }
	if (field.field_type === "ids") { FieldComponent = IDSField }
	if (field.field_type === "compliance") { FieldComponent = ComplianceField }
	if (field.field_type === "number") { FieldComponent = NumberField }
	if (field.field_type === "duration") { FieldComponent = PeriodField }
	if (field.field_type === "date") { FieldComponent = DateField }
	if (field.field_type === "location") { FieldComponent = LocationField }
	if (field.field_type === "users") { FieldComponent = UserField }
	if (field.field_type === "multi_users") { FieldComponent = MultiUserField }
	if (field.field_type === "contractor") { FieldComponent = ContractorField }
	if (field.field_type === "multi_contractors") { FieldComponent = MultiContractorField }
	if (field.field_type === "calc") { FieldComponent = CalcField }
	if (field.field_type === "document") { FieldComponent = DocumentField }

	const shouldHighlight = useMemo(() => {
		return highlighted?.element?.id === field?.id
	}, [highlighted, field])

	useEffect(() => {
		if (shouldHighlight) {
			mainField.current.scrollIntoView({ behavior: "smooth", block: "center" })
		}
	}, [shouldHighlight, mainField])

	return (
		<Box sx={title_style} id={`field_${field.id}`} ref={mainField} className={shouldHighlight ? "highlight_background" : ""}>
			{field.checklist_field_option_id &&
				<Divider sx={{ opacity: 0.1 }} />}
			<Box sx={{ ...(rejecting ? inline_space_end_no_wrap : {}), alignItems: "center" }}>
				<Typography className={shouldHighlight ? "highlight_text" : ""} variant='subtitle2'>{field?.label}{field?.required ? " *" : ""}</Typography>
				{rejecting &&
					<RejectionCommentDialog field={field} />
				}
			</Box>
			<Box sx={css.field} className={shouldHighlight ? "highlight_field highlight_background" : ""}>
				{FieldComponent && <FieldComponent
					key={field.id}
					value={field?.value}
					field={field}
					onChange={onChange}
					onChangeAndSave={onChangeAndSave}
					onBlur={onBlur}
					shouldHighlight={shouldHighlight}
				/>}
			</Box>
			{!field.checklist_field_option_id &&
				<Divider sx={{ opacity: 0.3 }} />
			}
		</Box >
	)
}

export default IndexField