import { Box, Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import useToggle from '../../Hooks/ToogleHook'
import { setSubdivision } from '../../API/checklists'
import { deleteChecklist } from '../../API/new_checklists'
import { callSnackbar } from '../../Utils/snackbar'
import RepeatedDialog from './SubdivisionCard/RepeatedDialog'
import SubdivisionShowDialog from './SubdivisionCard/SubdivisionShowDialog'
import { useTranslation } from 'react-i18next'

const css = {
	link: {
		cursor: 'pointer',
		'&:hover': {
			textDecoration: 'underline'
		}
	},
	breadcrumbs: {
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
		'@media (max-width:600px)': {
			display: 'block'
		}
	},
	crumb: {
		display: 'flex',
		alignItems: 'center',
	},
	dialog: {
		position: 'relative'
	},
	button: {
		position: 'absolute',
		top: 8,
		right: 8
	},
	editBox: {
		marginTop: 2
	},
	container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	}
}

function SubdivisionCard({ subdivision, editable, checklist, setChecklist, onRemove, base_subdivision_id, branch_id }) {

	const [openDialog, toggleDialog] = useToggle(false)
	const [repeated, setRepeated] = useState(false)
	const [names, setNames] = useState([])
	const { t } = useTranslation('cards')

	useEffect(() => {
		const name = checklist?.subdivision_name || subdivision?.name
		const base_names = name ? [name] : []
		setNames(base_names)
	}, [checklist, subdivision])

	async function onSaveSubdivision(subdivision) {
		setChecklist({ ...checklist, subdivision_id: subdivision.id, subdivision_name: subdivision.name, subdivision })
		const response = await setSubdivision({ id: checklist?.id, subdivision_id: subdivision.id })
		const hasBeenSettedBefore = response.data.has_been_already_added
		setRepeated(hasBeenSettedBefore)
	}

	async function onDeleteChecklist() {
		const response = await deleteChecklist({ id: checklist?.id, force: true })
		if (response.data.status === "success") {
			callSnackbar(t('feedback.deletedChecklist'), "success")
			setRepeated(false)
			toggleDialog(false)
			onRemove(true)
		}
	}

	function updateNames(crumbs) {
		const new_names = crumbs.map(crumb => crumb.name)
		setNames(new_names)
	}

	const mainName = useMemo(() => {
		const name_string = names.join(' > ')
		return name_string || checklist?.branch_name
	}, [names, checklist])

	return (
		<Box>
			<Typography variant='subtitle1' color="info.main" sx={css.link} onClick={toggleDialog}>{mainName}</Typography>
			<RepeatedDialog open={repeated} onDeleteChecklist={onDeleteChecklist} checklist={checklist} onClose={() => setRepeated(false)} />
			<SubdivisionShowDialog
				open={openDialog}
				onClose={toggleDialog}
				checklist={checklist}
				base_subdivision_id={base_subdivision_id}
				onSubmit={onSaveSubdivision}
				updateCrumbs={updateNames}
				subdivision={subdivision}
				branch_id={branch_id}
				editable={editable}
			/>
		</Box>
	)
}

export default SubdivisionCard

