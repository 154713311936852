import { Box } from '@mui/material'
import { createContext, useEffect, useState } from 'react'

export const ContractorsManagerContext = createContext({})

function ContractorsManager({ children, db }) {

	const [contractors, setContractorsArray] = useState([])

	useEffect(() => {
		getContractors()
	}, [])

	function addContractor(request) {
		const db_request = db.transaction(["contractors"], "readwrite")
			.objectStore("contractors")
			.put(request)

		db_request.onsuccess = function (event) {
			getContractors()
			console.log("Contractor has been added to the store")
		}

		db_request.onerror = function (event) {
			console.log("Error adding request to the store")
		}
	}

	function addContractors(contractors) {
		const transaction = db.transaction(["contractors"], "readwrite")
		const store = transaction.objectStore("contractors")

		for (const request of contractors) {
			store.put(request)
		}

		transaction.oncomplete = function (event) {
			console.log("Contractors has been added to the store")
			setContractorsArray(r => [...r, ...contractors])
		}

		transaction.onerror = function (event) {
			console.log("Error adding contractors to the store")
		}
	}

	function getContractor(id) {
		if (!db) return null
		const db_request = db.transaction(["contractors"]).objectStore("contractors").get(id)

		return new Promise((resolve, reject) => {
			db_request.onsuccess = function (event) {
				resolve(db_request.result)
			}

			db_request.onerror = function (event) {
				console.log("Error getting request from the store")
				reject(db_request.error)
			}
		})
	}

	function setContractors(contractors) {
		const transaction = db.transaction(["contractors"], "readwrite")
		const store = transaction.objectStore("contractors")
		store.clear()

		for (const request of contractors) {
			store.put(request)
		}

		transaction.oncomplete = function (event) {
			setContractorsArray(contractors)
			console.log("Contractors has been added to the store")
		}

		transaction.onerror = function (event) {
			console.log("Error adding contractors to the store")
		}
	}

	function updateContractor(contractor) {
		if (!db) return null
		const db_request = db.transaction(["contractors"], "readwrite").objectStore("contractors").put(contractor)

		return new Promise((resolve, reject) => {
			db_request.onsuccess = function (event) {
				resolve(db_request.result)
				getContractors()
			}

			db_request.onerror = function (event) {
				console.log("Error getting request from the store")
				reject(db_request.error)
			}
		})

	}

	async function addOrUpdateContractors(contractors) {
		if (!db) return false
		for (let contractor of contractors) {
			const exists = await getContractor(contractor.id)
			if (!!exists) {
				updateContractor(contractor)
			} else {
				addContractor(contractor)
			}
		}
	}

	function getContractors() {
		if (!db) return null
		const objectStore = db.transaction("contractors").objectStore("contractors")
		const contractors_response = []

		return new Promise((resolve, reject) => {
			objectStore.openCursor().onsuccess = function (event) {
				const cursor = event.target.result
				if (cursor) {
					contractors_response.push(cursor.value)
					cursor.continue()
				} else {
					setContractorsArray(contractors_response)
					resolve(contractors_response)
				}
			}

			objectStore.openCursor().onerror = function (event) {
				console.log("Error getting contractors from the store")
				reject(event.target.error)
			}
		})
	}

	function getContractorsPlain() {
		if (!db) return Promise.resolve([]); // Return an empty array if the db is not available

		const objectStore = db.transaction("contractors").objectStore("contractors");
		const contractors_response = [];

		return new Promise((resolve, reject) => {
			const cursorContractor = objectStore.openCursor();

			cursorContractor.onsuccess = function (event) {
				const cursor = event.target.result;
				if (cursor) {
					contractors_response.push(cursor.value);
					cursor.continue();
				} else {
					resolve(contractors_response);
				}
			};

			cursorContractor.onerror = function (event) {
				console.log("Error getting contractors from the store");
				reject(event.target.error);
			};
		});
	}

	function deleteContractor(id) {
		if (!db) return null
		const db_request = db.transaction(["contractors"], "readwrite").objectStore("contractors").delete(id)

		return new Promise((resolve, reject) => {
			db_request.onsuccess = function (event) {
				resolve(db_request.result)
				getContractors()
			}

			db_request.onerror = function (event) {
				console.log("Error getting request from the store")
				reject(db_request.error)
			}
		})
	}

	return (
		<ContractorsManagerContext.Provider value={{ addOrUpdateContractors, addContractor, addContractors, getContractor, getContractors, setContractors, deleteContractor, contractors, getContractorsPlain }}>
			{children}
		</ContractorsManagerContext.Provider>
	)
}

export default ContractorsManager