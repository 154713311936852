import { Box, IconButton, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { getTags } from '../../API/tags'
import newTheme from '../../newTheme'
import { AddCircleOutline, Close } from '@material-ui/icons'
import { tag_style, tags_container } from '../../Utils/defaultStyles'

const css = {
	tagText: {
		color: newTheme.palette.white.main,
	}
}

function ChecklistTagsSelector({ value = [], onChange }) {

	const [tags, setTags] = useState([])

	useEffect(() => {
		async function fetchData() {
			const response = await getTags()
			setTags(response.data.info)
		}
		fetchData()
	}, [])

	return (
		<Box sx={tags_container}>
			{tags.map(tag => {
				const imTagged = value.map(t => t.id).includes(tag.id)
				const updatedCss = { ...tag_style }
				updatedCss.background = tag.color + `${imTagged ? "" : "42"}`
				updatedCss["&:hover"] = { background: tag.color }
				return (
					<Box sx={updatedCss} className={imTagged ? "tagged" : "not-tagged"} onClick={() => onChange(tag.id)}>
						<Typography sx={css.tagText} variant='subtitle2'>{tag.name}</Typography>
						{imTagged ?
							<IconButton size="small" color="white">
								<Close />
							</IconButton>
							:
							<IconButton size="small" color="white">
								<AddCircleOutline />
							</IconButton>
						}
					</Box>
				)
			})}
		</Box>
	)
}

export default ChecklistTagsSelector