import { completePath } from "../Utils/functions"
import instance2 from "../Utils/instance2"
import { authorizationNotification } from "./new_checklists"
// import { callSnackbar } from "../Utils/snackbar"
const currentEnterprise = localStorage.getItem("enterprise") ? JSON.parse(localStorage.getItem("enterprise")) : {}
const enterprise = currentEnterprise?.name || "Tazki"
const logo = currentEnterprise?.logo

const logo_string = !!logo ? `<div>
	<img src=${completePath(logo, `${process.env.REACT_APP_IMG_URL}/${process.env.REACT_APP_ENTERPRISE_LOGO}`)} height=64px />
	</div>` : ""

export const sendMail = body => {
	return instance2({
		method: 'post',
		url: 'workflows/notification.php',
		data: body
	})
}

export const notifySign = (worker, registerType, link, name = "") => {
	const body = {
		subject: `${enterprise} - Registro firmado en Tazki`,
		content: `
			${logo_string}
			<strong>${worker.name}</strong>
			<p>Has firmado un(a) ${registerType} - ${name} en Tazki</p>
			<p>En el enlace a continuación podrás revisar el registro: <a href="${link}" target="_blank">Ver ${registerType}</a></p>
			`,
		emails: [worker.email],
		extra_mails: []
	}
	return sendMail(body)
}

export const notifyEnd = (worker, registerType, link, name = "") => {
	const body = {
		subject: `${enterprise} - Registro realizado`,
		content: `
			${logo_string}
			<strong>${worker.name}</strong>
			<p>Se ha terminado un(a) ${registerType} - ${name} en Tazki</p>
			<p>En el enlace a continuación podrás revisar el registro: <a href="${link}" target="_blank">Ver ${registerType}</a></p>
			`,
		emails: [worker.email],
		extra_mails: []
	}
	return sendMail(body)
}

export const notifyAuthorization = (users, link, name = "", checklist) => {
	return authorizationNotification({ id: checklist.id, users: users.map(u => u.id) })
}


