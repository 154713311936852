import { useContext } from "react"
import { ChecklistContext } from "../../Show/ChecklistView"
import { Box, Typography } from "@mui/material"
import UsersInput from "../../../../Shared/Inputs/UsersInput"
import { UserSelectContext } from "../../../../Shared/Dialogs/UserSelectDialog"
import UserInfoField from "./UserInfoField"
import { useTranslation } from "react-i18next"

function UserField({ field, onChange, value, onChangeAndSave }) {
	const { finished, hasPermissions } = useContext(ChecklistContext)
	const { getUsersNames } = useContext(UserSelectContext)
	const { t } = useTranslation("checklistForm")

	if (finished || !hasPermissions) return (
		<Box>
			<Typography variant='subtitle1'>{getUsersNames(value) || t('noAnswer')}</Typography>
			{field.compliance_observation && <UserInfoField field={field} user_ids={value} />}
		</Box>
	)

	return (
		<Box>
			<UsersInput onBlur={onChangeAndSave} value={value} onChange={onChange} label="" />
			{field.compliance_observation && <UserInfoField field={field} user_ids={value} />}
		</Box>
	)
}

export default UserField