import { KeyboardDateTimePicker } from '@material-ui/pickers/'
import { Typography } from "@mui/material"
import { Box } from '@mui/material'
import moment from 'moment'
import { italic_link } from '../../Utils/defaultStyles'
import useToggle from '../../Hooks/ToogleHook'
import HiddenDateTimeInput from './HiddenDateTimeInput'
import { useTranslation } from 'react-i18next'

function NewDateTimeInput({ value, label, name, disabled, required, onChange, onBlur, pretty, maxDate = null }) {

	const [openInput, toggleInput] = useToggle(false)
	const { t } = useTranslation("input")

	function handleChange(value) {
		const result = value?.format('YYYY-MM-DD HH:mm') || 'YYYY-MM-DD HH:mm'
		const event = { target: { name, value: result } }
		onChange(event)
		toggleInput(false)
	}

	if (pretty) {

		const real_value = value ? moment(value).format("YYYY-MM-DD HH:mm") : t('actions.selectDate')

		return (
			<Box>
				<Typography onClick={toggleInput} variant='subtitle2' color="info.main" sx={italic_link} >{real_value}</Typography>
				<HiddenDateTimeInput onClose={toggleInput} open={openInput} label={label} name={name} value={value} onChange={onChange} maxDate={maxDate} />
			</Box>
		)
	}

	return (
		<KeyboardDateTimePicker
			autoOk
			label={<Typography sx={{ fontWeight: required ? 600 : 400 }} color={required ? "secondary" : "primary"}>{`${label}${required ? "*" : ""}`}</Typography>}
			name={name}
			fullWidth
			disabled={disabled}
			format="YYYY-MM-DD HH:mm"
			value={value || moment().format("YYYY-MM-DD")}
			onChange={handleChange}
			style={{ margin: "12px 0" }}
			inputVariant="outlined"
			{...maxDate && { maxDate }}
		/>
	)
}

export default NewDateTimeInput
