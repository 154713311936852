import { Box, Divider, Drawer, Typography, Button } from "@mui/material"
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import { useRef } from "react"
import newTheme from "../../../newTheme"
import { useTranslation } from "react-i18next"
import { centered_container } from "../../../Utils/defaultStyles"
const css = {
	icon: {
		height: 24,
		width: 24,
		color: newTheme.palette.primary.main,
		transition: "color 0.2s ease-in-out",
	},
	button: {
		padding: "8px",
		borderRadius: 2,
		cursor: 'pointer',
		transition: "all 0.2s ease-in-out",
		background: newTheme.palette.background.main,
		boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
		height: 90,
		width: 120,
		...centered_container,
		flexDirection: "column",
		"&:hover": {
			background: newTheme.palette.grey[300],
			transform: "scale(1.05)",
		},
	},
	text: {
		fontSize: "14px",
		marginTop: "2px",
		lineHeight: "normal",
	},
	buttons: {
		display: "flex",
		gap: 6,
		flexWrap: "wrap",
		justifyContent: "center",
		padding: "16px 0",
	},
	drawer: {
		borderTopLeftRadius: 16,
		borderTopRightRadius: 16,
		boxShadow: "0px -4px 10px rgba(0, 0, 0, 0.1)",
		background: newTheme.palette.background.default,
		padding: "16px 0",
		textAlign: "center",
	},
}

function SelectImage({ open, onClose, dialogRef, addPhoto }) {
	const hidden_folder_file = useRef(null)
	const hidden_file = useRef(null)
	const { t } = useTranslation('input')

	function simulateClick() {
		if (hidden_file.current) {
			hidden_file.current.click()
		}
	}

	function simulateFolderClick() {
		hidden_folder_file.current.click()
	}

	return (
		<Drawer anchor="bottom" open={open} onClose={() => onClose()} container={dialogRef.current}
			sx={{
				'& .MuiDrawer-paper': {
					borderRadius: '16px 16px 0 0',
					backgroundColor: newTheme.palette.white.main,
					height: "30vh",
				},
			}}
			ModalProps={{
				keepMounted: true,
			}}
			PaperProps={{ sx: css.drawer }}
		>
			<Box>
				<Typography variant="h2" sx={{ fontWeight: "bold", paddingBottom: 2, }}>
					{t('actions.selectImage')}
				</Typography>
				<Divider sx={{ margin: "0 auto", opacity: 0.3 }} />
				<Box sx={css.buttons}>
					<Box sx={css.buttons}>
						<Box sx={css.button} onClick={simulateClick}>
							<CameraAltIcon sx={css.icon} />
							<Typography sx={css.text} variant="h1" fontSize="14px">{t('camera')}</Typography>
						</Box>
						<Box sx={css.button} onClick={simulateFolderClick}>
							<InsertPhotoIcon sx={css.icon} />
							<Typography sx={css.text} variant="h1" fontSize="14px">{t('library')}</Typography>
						</Box>
					</Box>
				</Box>
				<input type="file" accept="image/*" id="file" ref={hidden_folder_file} hidden onChange={addPhoto} />
				<input type="file" accept="image/*" id="file" hidden ref={hidden_file} onChange={addPhoto} capture />
				<Button variant="contained" onClick={() => onClose()} sx={{ marginTop: 5, width: "90%" }}>
					Cancelar
				</Button>
			</Box>
		</Drawer>
	)
}
export default SelectImage