import moment from "moment"
import { KeyboardDateTimePicker } from '@material-ui/pickers/'
import { Typography } from "@mui/material"


export default function DateTimeInput({ onChange, name, value, label, disabled, required }) {


    function handleChange(value) {
        const result = value?.format('YYYY-MM-DD HH:mm') || 'YYYY-MM-DD HH:mm'
        const event = { target: { name, value: result } }
        onChange(event)
    }

    return (
        <KeyboardDateTimePicker
            autoOk
            label={<Typography sx={{ fontWeight: required ? 600 : 400 }} color={required ? "secondary" : "primary"}>{`${label}${required ? "*" : ""}`}</Typography>}
            name={name}
            fullWidth
            disabled={disabled}
            format="YYYY-MM-DD HH:mm"
            value={value || moment().format("YYYY-MM-DD")}
            onChange={handleChange}
            style={{ margin: "12px 0" }}
            inputVariant="outlined"
        />
    )
}