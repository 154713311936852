import { Box } from '@mui/material'
import { createContext, useEffect, useState } from 'react'
import ChecklistManager from './ChecklistsManager'
import RequestManager from './RequestsManager'
import FindingsManager from './FindingsManager'
import PendingManager from './PendingsManager'
import BaseFindingManager from './BaseFindingManager'
import ActionManager from './ActionsManager'
import ContractorsManager from './ContractorsManager'
import SuppliesManager from './SupplyManager'
import BaseChecklistsManager from './BaseChecklistManager'

export const DatabaseManagerContext = createContext({})

let db

function DatabaseManager({ children }) {

	const [indexedDBReady, setReady] = useState(false)

	useEffect(() => {
		const request = indexedDB.open("TazkiDB", 9)

		request.onerror = function (event) {
			console.log("Error creating/accessing IndexedDB database")
		}

		request.onsuccess = function (event) {
			console.log("Success creating/accessing IndexedDB database")
			db = request.result
			db.transaction("checklists").objectStore("checklists")
			db.transaction("requests").objectStore("requests")
			db.transaction("actions").objectStore("actions")
			db.transaction("users").objectStore("users")
			db.transaction("findings").objectStore("findings")
			db.transaction("pendings").objectStore("pendings")
			db.transaction("finding_types").objectStore("finding_types")
			db.transaction("base_finding_descriptions").objectStore("base_finding_descriptions")
			db.transaction("base_finding_actions").objectStore("base_finding_actions")
			db.transaction("base_finding_timings").objectStore("base_finding_timings")
			db.transaction("base_finding_forms").objectStore("base_finding_forms")
			db.transaction("contractors").objectStore("contractors")
			db.transaction("supplies").objectStore("supplies")
			db.transaction("base_checklists").objectStore("base_checklists")
			setReady(true)
		}

		request.onupgradeneeded = function (event) {
			console.log("Upgrading IndexedDB database")
			const db = event.target.result
			const objectStoreNames = db.objectStoreNames
			if (!objectStoreNames.contains("checklists")) { db.createObjectStore("checklists", { keyPath: "id" }) }
			if (!objectStoreNames.contains("findings")) { db.createObjectStore("findings", { keyPath: "id" }) }
			if (!objectStoreNames.contains("users")) { db.createObjectStore("users", { keyPath: "id" }) }
			if (!objectStoreNames.contains("requests")) { db.createObjectStore("requests", { keyPath: "id", autoIncrement: true }) }
			if (!objectStoreNames.contains("actions")) { db.createObjectStore("actions", { keyPath: "id", autoIncrement: true }) }
			if (!objectStoreNames.contains("pendings")) { db.createObjectStore("pendings", { keyPath: "unique_id" }) }
			if (!objectStoreNames.contains("finding_types")) { db.createObjectStore("finding_types", { keyPath: "id" }) }
			if (!objectStoreNames.contains("base_finding_descriptions")) { db.createObjectStore("base_finding_descriptions", { keyPath: "id" }) }
			if (!objectStoreNames.contains("base_finding_actions")) { db.createObjectStore("base_finding_actions", { keyPath: "id" }) }
			if (!objectStoreNames.contains("base_finding_timings")) { db.createObjectStore("base_finding_timings", { keyPath: "id" }) }
			if (!objectStoreNames.contains("base_finding_forms")) { db.createObjectStore("base_finding_forms", { keyPath: "id" }) }
			if (!objectStoreNames.contains("contractors")) { db.createObjectStore("contractors", { keyPath: "id" }) }
			if (!objectStoreNames.contains("supplies")) { db.createObjectStore("supplies", { keyPath: "id" }) }
			if (!objectStoreNames.contains("base_checklists")) { db.createObjectStore("base_checklists", { keyPath: "id" }) }
		}
	}, [])

	return (
		<DatabaseManagerContext.Provider value={{ db, indexedDBReady }}>
			<ActionManager db={db} >
				<PendingManager db={db} >
					<BaseFindingManager db={db} >
						<RequestManager db={db} >
							<ChecklistManager db={db} >
								<FindingsManager db={db} >
									<ContractorsManager db={db} >
										<SuppliesManager db={db} >
											<BaseChecklistsManager db={db} >
												{children}
											</BaseChecklistsManager>
										</SuppliesManager>
									</ContractorsManager>
								</FindingsManager>
							</ChecklistManager>
						</RequestManager>
					</BaseFindingManager>
				</PendingManager>
			</ActionManager>
		</DatabaseManagerContext.Provider>
	)
}

export default DatabaseManager