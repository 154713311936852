import { useContext } from 'react'
import AppContext from '../../AppContext'
import { inline_buttons, italic } from '../../Utils/defaultStyles'
import { ReportProblemOutlined } from '@material-ui/icons'
import { Box, Typography } from '@mui/material'
import newTheme from '../../newTheme'
import { useTranslation } from 'react-i18next'

const css = {
	container: {
		margin: "12px 0",
	}
}

function ConectionDisclaimer() {

	const { online: { status: online } } = useContext(AppContext)
	const { t } = useTranslation('onlineStatusRendered')

	if (online) return null

	return (
		<Box sx={{ ...inline_buttons, ...css.container }}>
			<ReportProblemOutlined style={{ color: newTheme.palette.warning.main }} />
			<Typography variant='subtitle2' color="warning.main" sx={italic}>{t('workingWithoutConnection')}</Typography>
		</Box>
	)
}

export default ConectionDisclaimer