import { Box, Typography } from '@mui/material'
import { ChecklistContext } from '../../Show/ChecklistView'
import { useContext, useEffect, useMemo, useState } from 'react'
import { DatabaseManagerContext } from '../../../../DatabaseManagers/DatabaseManager'
import { ContractorsManagerContext } from '../../../../DatabaseManagers/ContractorsManager'
import { transformToOptions } from '../../../../Utils/functions'
import MultiSelectInput from '../../../../Shared/Inputs/MultiSelectInput'
import { useTranslation } from 'react-i18next'

function MultiContractorField({ field, onChangeAndSave, value, onBlur }) {
	const { finished, isAdmin, hasPermissions } = useContext(ChecklistContext)
	const [contractors, setContractors] = useState([])
	const { getContractors } = useContext(ContractorsManagerContext)
	const { indexedDBReady } = useContext(DatabaseManagerContext)
	const { t } = useTranslation('checklistForm')

	useEffect(() => {
		async function fetchData() {
			const response = await getContractors()
			setContractors(response)
		}
		if (indexedDBReady) { fetchData() }
	}, [indexedDBReady])

	const selectedContractors = useMemo(() => {
		return contractors.filter(c => value.includes(String(c.id))).map(c => c.name).join(", ")
	})

	if (finished || !hasPermissions) return (
		<Box>
			<Typography variant='subtitle1'>{selectedContractors || t('noAnswer')}</Typography>
		</Box>
	)
	return (
		<Box>
			<MultiSelectInput options={transformToOptions(contractors)} value={value} onChange={onChangeAndSave} label="" />
		</Box>
	)
}

export default MultiContractorField