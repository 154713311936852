import React, { useState, useEffect, useMemo } from 'react'
import { Download, Refresh } from '@mui/icons-material'
import { IconButton, Box, MenuItem, Typography } from '@mui/material'
import MiniLoaderAnimator from '../Loader/MiniLoaderAnimator'
import ActionCable from 'actioncable'
import { webSocketUrl } from '../../Utils/instance2'
import BigTooltip from '../Tooltip/BigTooltip'
import { inline_space } from '../../Utils/defaultStyles'
import { useConfirmationDialog } from '../Dialogs/ConfirmationDialog'
import { useTranslation } from 'react-i18next'

const css = {
	refresh_button: {
		top: 0,
		left: -3,
		animation: 'growth 0.5s ease-in-out',
		'@keyframes growth': {
			'0%': { transform: 'scale(0)' },
			'100%': { transform: 'scale(1)' }
		}
	}
}

const cable = ActionCable.createConsumer(webSocketUrl)

function DownloadPdfButton({ onClick, disabled, type, finished = true, updateState, element, menuItem = false, getElement = null, download }) {
	const [loading, setLoading] = useState(false)
	const [pdf_state, setPdfState] = useState(element.pdf_state)
	const [connected, setConnected] = useState(false)
	const [hovered, setHovered] = useState(false)
	const askConfirmation = useConfirmationDialog()
	const { t } = useTranslation('buttons')

	const channelParams = useMemo(() => ({ channel: 'PdfStateChannel', type, id: element.id }), [type, element.id])

	useEffect(() => {
		if (!element?.id) return
		const channel = cable.subscriptions.create(channelParams, {
			connected() {
				setConnected(true)
				if (getElement) { fetchPdfState() }
			},
			disconnected() { },
			received(data) {
				updateState(prevElemet => ({ ...prevElemet, pdf_state: data.status, pdf_url: data.url }))
				setPdfState(data.status)
			}
		})

		const fetchPdfState = async () => {
			const response = await getElement({ id: element.id })
			setPdfState(response.data.info.pdf_state)
		}

		return () => {
			if (channel && connected) {
				cable.subscriptions.remove(channel)
			}
		}
	}, [channelParams, element.id])

	useEffect(() => {
		setPdfState(element.pdf_state)
	}, [element.pdf_state])

	async function handleClick() {
		setLoading(true)
		await onClick()
		setLoading(false)
		setHovered(false)
	}

	function handleForceDownload() {
		async function callback() {
			setLoading(true)
			await onClick(true)
			setLoading(false)
		}
		askConfirmation(
			t('force.title'),
			t('force.description'),
			t('force.submit'),
			callback
		)
	}

	const isPreparingPdf = element.id && pdf_state === "generating"
	const isButtonDisabled = disabled || loading || isPreparingPdf
	const buttonColor = pdf_state === "generated" ? "success" : ""

	if (!element) return null

	const buttonContent = loading || isPreparingPdf
		? <MiniLoaderAnimator />
		: <Download color={buttonColor} />

	return menuItem ? (
		<MenuItem onClick={handleClick} disabled={isButtonDisabled} sx={inline_space} >
			<Typography variant='subtitle2' color={buttonColor}>{t('download')}</Typography >
			{buttonContent}
		</MenuItem>
	) : (
		<Box
			onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
		>
			{!isButtonDisabled && hovered && (
				<BigTooltip title={t('regenerateDocument')} isWarning={true}>
					<IconButton size="medium" onClick={handleForceDownload} sx={css.refresh_button}>
						<Refresh />
					</IconButton>
				</BigTooltip>
			)}
			<IconButton size="medium" disabled={isButtonDisabled} onClick={handleClick}>
				{buttonContent}
			</IconButton>
		</Box>
	)
}

export default DownloadPdfButton
