import { CheckCircleOutline, VpnKey } from '@material-ui/icons'
import { Box, Button, Dialog, Slide, Typography } from '@mui/material'
import moment from 'moment'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import AppContext from '../../../../AppContext'
import { CurrentUserContext } from '../../../../CurrentUser'
import useToggle from '../../../../Hooks/ToogleHook'
import newTheme from '../../../../newTheme'
import { dialog_style, end_flex_buttons, inline } from '../../../../Utils/defaultStyles'
import { ChecklistContext } from '../../Show/ChecklistView'
import Sign from './Sign'
import SignForm from './SignForm'
import { transformBodyAndSendCallback } from '../../../../Utils/offlineSyncFunctions'
import { useActionContext } from '../../../../DatabaseManagers/ActionsManager'
import RegisterNewSign from '../../../../Shared/Dialogs/RegisterNewSign'
import { callSnackbar } from '../../../../Utils/snackbar'
import { v4 as uuid } from 'uuid'
import { updateChecklistWithNewSign } from '../utils'

const css = {
	container: {
		background: 'whitesmoke',
		padding: 2,
		borderRadius: 1,
		margin: '6px 0'
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
		gap: 2,
	},
	see_button: {
		flexGrow: 1,
		textAlign: 'end'
	},
	selected: {
		background: newTheme.palette.blue.light
	},
	signs: {
		padding: '12px 0',
		display: 'flex',
		flexWrap: 'wrap',
		gap: 2
	}
}

function SignGroup({ group, selected, onSelect, signs: currentSigns }) {
	const { t } = useTranslation('checklistSigns')
	const { hasPermissions, checklist, setChecklist, appendSave, resolveSave, isResponsible, readonly, setHighlighted, imResponsibleOfSection, finished } = useContext(ChecklistContext)
	const { history } = useContext(AppContext)
	const [openDialog, toggleDialog] = useToggle(false)
	const actionContext = useActionContext()

	const out = !!selected && selected !== group.id
	const isThisGroupSelected = !!selected && selected === group.id
	const container = isThisGroupSelected ? { ...css.container, ...css.selected } : css.container
	const signs = group.signs || []
	const { currentUser } = useContext(CurrentUserContext)
	const hasRegisteredSigns = !!currentUser?.signs?.length
	const sign = hasRegisteredSigns ? currentUser?.signs[0] : null
	const isSigned = !!currentSigns.filter(s => String(s.user_id) === String(currentUser.id)).length
	const isCollab = currentUser.userType === 3
	const hasPriviledges = hasPermissions || imResponsibleOfSection
	const shouldSignInmediatly = (!hasPriviledges || isCollab) && hasRegisteredSigns
	const forceRegisteredSign = checklist?.force_registered_sign

	async function onSign() {
		const body = {
			sign_url: sign?.sign,
			name: currentUser?.name,
			sign_name: currentUser?.name,
			rut: currentUser?.rut,
			checklist_id: checklist?.id,
			checklist_group_sign_id: group?.id,
			user_id: currentUser?.id,
			position: currentUser?.position,
			date: moment().format("YYYY-MM-DD HH:mm")
		}
		body.temp_id = uuid()
		body.id = body.temp_id
		await handleSignCompletion(body, checklist, group, t, actionContext)
		callSnackbar(t('group.signRegistered'), "success")
	}

	async function afterRegister(new_sign) {
		const body = {
			sign_url: new_sign.sign,
			name: currentUser?.name,
			sign_name: currentUser?.name,
			rut: currentUser?.rut,
			checklist_id: checklist?.id,
			checklist_group_sign_id: group?.id,
			user_id: currentUser?.id,
			date: moment().format("YYYY-MM-DD HH:mm"),
			id: new_sign.id,
			temp_id: new_sign.temp_id
		}
		await handleSignCompletion(body, checklist, group, t, actionContext)
	}

	async function handleSignCompletion(body, checklist, group, t, actionContext) {
		const newChecklist = updateChecklistWithNewSign(body, checklist, group)
		setChecklist(newChecklist)
		appendSave()
		await transformBodyAndSendCallback(body, "add_checklist_registered_sign", actionContext, t('actions.addRegisteredSign'))
		resolveSave()
		toggleDialog()
		setHighlighted(null)
	}

	function goBack() {
		history.push("/")
	}

	return (
		<Slide in={!out} direction="right" unmountOnExit>
			<Box>
				{(isThisGroupSelected) &&
					<SignForm group={group} onSelect={onSelect} signs={currentSigns} />
				}
				<Box sx={container}>
					<Box sx={css.header}>
						<Box>
							<Typography variant='h4'>{group.name}</Typography>
							<Typography variant='subtitle1'>{group.description}</Typography>
						</Box>
						{!readonly &&
							<Box sx={css.see_button}>
								{(!shouldSignInmediatly) ?
									<Box sx={end_flex_buttons}>
										{hasPriviledges && hasRegisteredSigns &&
											<Button color="info" variant="outlined" endIcon={<VpnKey />} onClick={onSign} disabled={isSigned}>
												{t('group.addMySign')}
											</Button>
										}
										{hasPriviledges && !hasRegisteredSigns && !isSigned &&
											<RegisterNewSign afterRegister={afterRegister} />
										}
										{!forceRegisteredSign &&
											<Button
												variant={isThisGroupSelected ? "contained" : "outlined"}
												color={isThisGroupSelected ? "secondary" : "inherit"}
												onClick={() => onSelect(group.id)}
											>
												{isThisGroupSelected ? t('group.close') : t('group.addSignatures')}
											</Button>
										}
									</Box>
									:
									<Button color="info" variant="outlined" endIcon={<VpnKey />} onClick={onSign} disabled={isSigned}>
										{t('group.sign')}
									</Button>
								}
							</Box>
						}
					</Box>
					{!!signs.length &&
						<Box sx={css.signs}>
							{signs.map(sign => <Sign sign={sign} group={group} />)}
						</Box>
					}
				</Box>
				<Dialog open={openDialog && !isResponsible} onClose={toggleDialog} fullWidth maxWidth="sm">
					<Box sx={dialog_style}>
						<Box sx={inline}>
							<CheckCircleOutline style={{ color: newTheme.palette.success.main }} />
							<Typography variant='h4' sx={{ color: newTheme.palette.success.main }} >{t('group.signSuccess')}</Typography>
						</Box>
						<Button color="info" variant="contained" fullWidth sx={{ margin: '12px 0' }} onClick={goBack}>
							{t('group.goBack')}
						</Button>
					</Box>
				</Dialog>
			</Box>
		</Slide >
	)
}

export default SignGroup
