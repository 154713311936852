import { Box, Typography } from '@mui/material'
import { labeless, text_space } from '../../../../Utils/defaultStyles'
import { AssignmentTurnedInOutlined } from '@material-ui/icons'
import TextInput from '../../../../Shared/Inputs/TextInput'
import { useState } from 'react'
import moment from 'moment'
import PlainImageInput from '../../../../Shared/Inputs/PlainImageInput'
import PlainFileInput from '../../../../Shared/Inputs/PlainFileInput'
import DialogLayout from './DialogLayout'
import { useTranslation } from 'react-i18next'

function SolveDialog({ finding, open, onClose, crit, urls, onSubmit }) {

	const [params, setParams] = useState({})
	const { t } = useTranslation("findingCardDialog")
	function handleChange(event) {
		const { target } = event
		const { name, value } = target
		setParams({ ...params, [name]: value })
	}

	function onSolve() {
		const body = { ...params, date_solved: moment().format('YYYY-MM-DD HH:mm') }
		onSubmit(body)
		onClose()
	}

	const disableSave = !(params.solution_comment || params.solution_evidence)
	const button_styles = { "variant": "contained", "color": "info", "icon": <AssignmentTurnedInOutlined /> }
	const titles = { "title": t('actions.resolveFinding'), "action": t('actions.resolveAndExit') }

	return (
		<DialogLayout open={open} crit={crit} urls={urls} onClose={onClose} titles={titles} finding={finding} disableSave={disableSave} onClick={onSolve} button_styles={button_styles}>
			<Box sx={text_space}>
				<Typography variant='subtitle2' sx={labeless}>{t('titles.resolution')}:</Typography>
				<TextInput value={params.solution_comment} onChange={handleChange} name="solution_comment" label="" multiline rows={4} />
			</Box>
			<Box sx={text_space}>
				<Typography variant='subtitle2'>{t('data.resolutionDocument')}:</Typography>
				<PlainFileInput
					value={params.solution_file}
					onChange={handleChange}
					name="solution_file"
				/>
			</Box>
			<Box sx={text_space}>
				<Typography variant='subtitle2'>{t('data.resolutionImages')}:</Typography>
				<PlainImageInput
					value={params.solution_evidence}
					onChange={handleChange} label=""
					name="solution_evidence"
				/>
			</Box>
		</DialogLayout >
	)
}

export default SolveDialog