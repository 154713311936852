import { Box, IconButton } from '@mui/material'
import SeamlessInput from '../../../../Shared/Inputs/SeamlessInput'
import newTheme from '../../../../newTheme'
import { useContext, useEffect, useState } from 'react'
import { inline_title_no_wrap } from '../../../../Utils/defaultStyles'
import { Delete } from '@material-ui/icons'
import { ConfirmationContext } from '../../../../Shared/Dialogs/ConfirmationDialog'
import { useTranslation } from 'react-i18next'

function CategoryGroupNameEdit({ group, onUpdateGroup, deletable, onDeleteGroup }) {

	const [name, setName] = useState(group?.name)
	const { askConfirmation } = useContext(ConfirmationContext)
	const { t } = useTranslation('checklistItem')

	useEffect(() => {
		setName(group?.name)
	}, [group])

	function onBlur() {
		if (name === group?.name) return null // No changes
		if (!name) return setName(group?.name) // Empty name
		onUpdateGroup({ ...group, name })
	}

	function onDelete() {
		if (!deletable) return null
		async function callback() {
			onDeleteGroup(group?.id)
		}
		askConfirmation(
			t('sheet.deleteTitle'),
			t('sheet.deleteConfirmation'),
			null,
			callback
		)
	}

	return (
		<Box sx={inline_title_no_wrap}>
			<SeamlessInput
				color="transparent"
				variant="h1"
				fontColor={newTheme.palette.info.main}
				value={name}
				onChange={(e) => setName(e.target.value)}
				onBlur={onBlur}
			/>
			<IconButton color="error" onClick={onDelete} disabled={!deletable}>
				<Delete />
			</IconButton>
		</Box>
	)
}

export default CategoryGroupNameEdit