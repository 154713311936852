import { dialog_style, end_buttons } from "../../../Utils/defaultStyles"
import { Badge, Box, Button, Dialog, IconButton, Typography } from "@mui/material"
import TextInput from "../../../Shared/Inputs/TextInput"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import BigTooltip from "../../../Shared/Tooltip/BigTooltip"
import { ChecklistContext } from "../Show/ChecklistView"
import useToggle from "../../../Hooks/ToogleHook"
import { Announcement } from "@mui/icons-material"


function parseCommentText(comment, field, field_type = "field") {
	const foundComment = comment
		.split(";")
		.find(comment => comment.startsWith(`${field_type}-${field.id}:`)) || ""

	return foundComment.replace(`${field_type}-${field.id}:`, "").trim()
}

function RejectionCommentDialog({ field, field_type = "field" }) {
	const [text, setText] = useState("")
	const { t } = useTranslation('checklistRejection')
	const { rejectionComment, setRejectionComment } = useContext(ChecklistContext)
	const [openRejectDialog, toggleRejectDialog] = useToggle(false)

	useEffect(() => {
		setText(parseCommentText(rejectionComment, field, field_type))
	}, [rejectionComment])

	function handleChange(event) {
		const { target } = event
		setText(target.value)
	}

	function handleRejectComment(text) {
		const newComment = `${field_type}-${field.id}:${text}`
		toggleRejectDialog()
		setRejectionComment(prevComment => {
			let comments = prevComment.split(";").filter(comment => comment.trim() !== "")
			const existingIndex = comments.findIndex(comment => comment.startsWith(`${field_type}-${field.id}:`))
			if (existingIndex !== -1) {
				comments[existingIndex] = newComment
			} else {
				comments.push(newComment)
			}
			return `;${comments.join(";")}`
		})
	}

	return (
		<Box>
			<BigTooltip title="Motivo de rechazo">
				<Badge badgeContent={1} color="warning" variant='dot' invisible={text.trim() === ""}>
					< IconButton onClick={toggleRejectDialog}>
						<Announcement color='warning' />
					</IconButton>
				</Badge>
			</BigTooltip>

			<Dialog open={openRejectDialog} onClose={toggleRejectDialog} fullWidth maxWidth="sm">
				<Box sx={dialog_style}>
					<Typography variant="h4">{t('titles.rejectionReason')}</Typography>
					<TextInput value={text} onChange={handleChange} multiline rows={4} />
					<Box sx={end_buttons} >
						<Button color="primary" variant="contained" onClick={() => handleRejectComment(text)}>
							{t('save')}
						</Button>
					</Box>
				</Box>
			</Dialog>
		</Box>
	)

}

export default RejectionCommentDialog