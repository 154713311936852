import { Box, Slide, Typography } from '@mui/material'
import newTheme from '../../../../newTheme'
import MiniLoaderAnimator from '../../../../Shared/Loader/MiniLoaderAnimator'
import { useTranslation } from 'react-i18next'

const css = {
	container: {
		position: 'fixed',
		bottom: 24,
		right: 24,
		height: 48,
		background: newTheme.palette.secondary.main,
		borderRadius: 2,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '0 12px',
		gap: 1
	}
}

function LoadingCard({ loading }) {
	const { t } = useTranslation('checklistShow')

	return (
		<Slide in={loading} direction="left">
			<Box sx={css.container}>
				<MiniLoaderAnimator />
				<Box>
					<Typography variant="subtitle2" color="white.main">
						{t('cards.saving')}
					</Typography>
					<Typography variant="caption" color="white.main">
						{t('cards.dontLeavePage')}
					</Typography>
				</Box>
			</Box>
		</Slide>
	)
}

export default LoadingCard