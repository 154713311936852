import { Box, Typography } from '@mui/material'
import SeamlessInput from '../../../../Shared/Inputs/SeamlessInput'
import { useTranslation } from 'react-i18next'

function ItemComment({ value, onChange, onBlur, disabled }) {
	const { t } = useTranslation('checklistItem')
	return (
		<Box>
			<Typography variant='subtitle1'>{t('comments')}</Typography>
			<SeamlessInput disabled={disabled} onBlur={onBlur} name="comments" value={value} onChange={onChange} />
		</Box>
	)
}

export default ItemComment