import { Box } from '@mui/material'
import { createContext, useEffect, useState } from 'react'

export const RequestManagerContext = createContext({})

function RequestManager({ children, db }) {

	const [requests, setRequestsArray] = useState([])

	useEffect(() => {
		getRequests()
	}, [])

	function addRequest(request) {
		const db_request = db.transaction(["requests"], "readwrite")
			.objectStore("requests")
			.put(request)

		db_request.onsuccess = function (event) {
			getRequests()
			console.log("Request has been added to the store")
		}

		db_request.onerror = function (event) {
			console.log("Error adding request to the store")
		}
	}

	function addRequests(requests) {
		const transaction = db.transaction(["requests"], "readwrite")
		const store = transaction.objectStore("requests")

		for (const request of requests) {
			store.put(request)
		}

		transaction.oncomplete = function (event) {
			console.log("Requests has been added to the store")
			setRequestsArray(r => [...r, ...requests])
		}

		transaction.onerror = function (event) {
			console.log("Error adding requests to the store")
		}
	}

	function getRequest(id) {
		if (!db) return null
		const db_request = db.transaction(["requests"]).objectStore("requests").get(id)

		return new Promise((resolve, reject) => {
			db_request.onsuccess = function (event) {
				resolve(db_request.result)
			}

			db_request.onerror = function (event) {
				console.log("Error getting request from the store")
				reject(db_request.error)
			}
		})
	}

	function setRequests(requests) {
		const transaction = db.transaction(["requests"], "readwrite")
		const store = transaction.objectStore("requests")
		store.clear()

		for (const request of requests) {
			store.put(request)
		}

		transaction.oncomplete = function (event) {
			setRequestsArray(requests)
			console.log("Requests has been added to the store")
		}

		transaction.onerror = function (event) {
			console.log("Error adding requests to the store")
		}
	}

	function getRequests() {
		if (!db) return null
		const objectStore = db.transaction("requests").objectStore("requests")
		const requests_response = []

		return new Promise((resolve, reject) => {
			objectStore.openCursor().onsuccess = function (event) {
				const cursor = event.target.result
				if (cursor) {
					requests_response.push(cursor.value)
					cursor.continue()
				} else {
					setRequestsArray(requests_response)
					resolve(requests_response)
				}
			}

			objectStore.openCursor().onerror = function (event) {
				console.log("Error getting requests from the store")
				reject(event.target.error)
			}
		})
	}

	function getRequestsPlain() {
		if (!db) return Promise.resolve([]); // Return an empty array if the db is not available

		const objectStore = db.transaction("requests").objectStore("requests");
		const requests_response = [];

		return new Promise((resolve, reject) => {
			const cursorRequest = objectStore.openCursor();

			cursorRequest.onsuccess = function (event) {
				const cursor = event.target.result;
				if (cursor) {
					requests_response.push(cursor.value);
					cursor.continue();
				} else {
					resolve(requests_response);
				}
			};

			cursorRequest.onerror = function (event) {
				console.log("Error getting requests from the store");
				reject(event.target.error);
			};
		});
	}

	function deleteRequest(id) {
		if (!db) return null
		const db_request = db.transaction(["requests"], "readwrite").objectStore("requests").delete(id)

		return new Promise((resolve, reject) => {
			db_request.onsuccess = function (event) {
				resolve(db_request.result)
				getRequests()
			}

			db_request.onerror = function (event) {
				console.log("Error getting request from the store")
				reject(db_request.error)
			}
		})
	}

	return (
		<RequestManagerContext.Provider value={{ addRequest, addRequests, getRequest, getRequests, setRequests, deleteRequest, requests, getRequestsPlain }}>
			{children}
		</RequestManagerContext.Provider>
	)
}

export default RequestManager