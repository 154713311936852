import { createContext } from 'react'

export const BaseFindingManagerContext = createContext({})

function BaseFindingManager({ children, db }) {

	async function addOrUpdateBaseFindings(base_findings) {
		if (!db) return false
		const finding_types = base_findings.types
		const base_finding_actions = base_findings.actions
		const base_finding_descriptions = base_findings.measures
		const base_finding_timings = base_findings.timings
		const base_finding_forms = base_findings.forms

		await addOrUpdateDataInStore("finding_types", finding_types)
		await addOrUpdateDataInStore("base_finding_actions", base_finding_actions)
		await addOrUpdateDataInStore("base_finding_descriptions", base_finding_descriptions)
		await addOrUpdateDataInStore("base_finding_timings", base_finding_timings)
		await addOrUpdateDataInStore("base_finding_forms", base_finding_forms)
	}

	async function addOrUpdateDataInStore(storeName, data) {
		for (const item of data) {
			const existingData = await getDataFromStore(storeName, item.id)
			if (existingData.length > 0) {
				await updateDataInStore(storeName, item)
			} else {
				await addDataToStore(storeName, item)
			}
		}
	}

	function getDataFromStore(storeName, finding_type_id = null) {
		const objectStore = db.transaction(storeName).objectStore(storeName)
		const results = []

		return new Promise((resolve, reject) => {
			objectStore.openCursor().onsuccess = function (event) {
				const cursor = event.target.result

				if (cursor) {
					if (finding_type_id !== null) {
						const finding_type_id_int = parseInt(finding_type_id)
						if (cursor.value.finding_type_id === finding_type_id_int) {
							results.push(cursor.value)
						}
					} else {
						results.push(cursor.value)
					}
					cursor.continue()
				} else {
					resolve(results)
				}
			}

			objectStore.openCursor().onerror = function (event) {
				console.log(`Error getting data from the store: ${storeName}`)
				reject(event.target.error)
			}
		})
	}

	function updateDataInStore(storeName, data) {
		const transaction = db.transaction([storeName], "readwrite")
		const objectStore = transaction.objectStore(storeName)
		const request = objectStore.put(data)

		return new Promise((resolve, reject) => {
			request.onsuccess = () => {
				console.log(`${storeName} data updated successfully.`)
				resolve(request.result)
			}

			request.onerror = (event) => {
				console.log(`Error updating data in ${storeName}`)
				reject(event.target.error)
			}
		})
	}

	function addDataToStore(storeName, data) {
		const transaction = db.transaction([storeName], "readwrite")
		const objectStore = transaction.objectStore(storeName)
		const request = objectStore.add(data)

		return new Promise((resolve, reject) => {
			request.onsuccess = () => {
				console.log(`${storeName} data added successfully.`)
				resolve(request.result)
			}

			request.onerror = (event) => {
				console.log(`Error adding data to ${storeName}`)
				reject(event.target.error)
			}
		})
	}

	function getBaseFindingDescriptions(finding_type_id) {
		return getDataFromStore("base_finding_descriptions", finding_type_id)
	}

	function getBaseFindingActions(finding_type_id) {
		return getDataFromStore("base_finding_actions", finding_type_id)
	}

	function getBaseFindingTimings(finding_type_id) {
		return getDataFromStore("base_finding_timings", finding_type_id)
	}

	function getBaseFindingForms(finding_type_id) {
		return getDataFromStore("base_finding_forms", finding_type_id)
	}

	function getFindingTypes() {
		return getDataFromStore("finding_types")
	}


	function getPending(id) {
		if (!db) return false
		const request = db.transaction(["pendings"]).objectStore("pendings").get(id)

		return new Promise((resolve, reject) => {
			request.onsuccess = function (event) {
				const pending = request.result
				if (pending) {
					resolve(pending)
				} else {
					resolve(null) // Resolve with null when pending is not found
				}
			}

			request.onerror = function (event) {
				console.log("Error getting pending from the store")
				reject(request.error)
			}
		})
	}

	async function checkIfPendingExists(pending_id) {
		const exists = await getPending(pending_id)
		return !!exists
	}

	async function removeAllPendings() {
		const transaction = db.transaction(["pendings"], "readwrite")
		const objectStore = transaction.objectStore("pendings")

		objectStore.clear()

		return new Promise((resolve, reject) => {
			transaction.oncomplete = function (event) {
				console.log("Pendings has been removed from the store")
				resolve()
			}

			transaction.onerror = function (event) {
				console.log("Error removing pendings from the store")
				reject(event.target.error)
			}
		})
	}

	async function keepOnlyPendings(pending_ids) {
		const transaction = db.transaction(["pendings"], "readwrite")
		const objectStore = transaction.objectStore("pendings")

		const getAllRequest = objectStore.getAll()

		getAllRequest.onsuccess = function (event) {
			const allPendings = event.target.result
			const pendingIDsToDelete = allPendings
				.filter((pending) => !pending_ids.includes(pending.id))
				.map((pending) => pending.id)

			for (const id of pendingIDsToDelete) {
				objectStore.delete(id)
			}
		}

		return new Promise((resolve, reject) => {
			transaction.oncomplete = function (event) {
				console.log("Pendings have been filtered and removed from the store")
				resolve()
			}

			transaction.onerror = function (event) {
				console.log("Error removing pendings from the store")
				reject(event.target.error)
			}
		})
	}

	function setPendings(pendings) {
		if (!db) return null
		const parsed_pendings = pendings.map(pending => ({ ...pending, unique_id: `${pending.id}_${pending.type}` }))
		console.log(parsed_pendings)
		const transaction = db.transaction(["pendings"], "readwrite")
		const store = transaction.objectStore("pendings")
		store.clear()

		for (const pending of parsed_pendings) {
			store.put(pending)
		}

		transaction.oncomplete = function (event) {
			console.log("Pendings has been added to the store")
		}

		transaction.onerror = function (event) {
			console.log("Error adding pendings to the store")
		}
	}

	function getPendings() {
		if (!db) return null
		const objectStore = db.transaction("pendings").objectStore("pendings")
		const pendings = []

		return new Promise((resolve, reject) => {
			objectStore.openCursor().onsuccess = function (event) {
				const cursor = event.target.result
				if (cursor) {
					pendings.push(cursor.value)
					cursor.continue()
				} else {
					resolve(pendings)
				}
			}

			objectStore.openCursor().onerror = function (event) {
				console.log("Error getting pendings from the store")
				reject(event.target.error)
			}
		})
	}

	return (
		<BaseFindingManagerContext.Provider value={{
			getPending,
			getPendings,
			setPendings,
			removeAllPendings,
			checkIfPendingExists,
			keepOnlyPendings,
			addOrUpdateBaseFindings,
			getFindingTypes,
			getBaseFindingMeasures: getBaseFindingDescriptions,
			getBaseFindingActions,
			getBaseFindingTimings,
			getBaseFindingForms
		}}>
			{children}
		</BaseFindingManagerContext.Provider>
	)
}

export default BaseFindingManager