import { useEffect, useState } from 'react'
import TextInput from './TextInput'
import { useTranslation } from 'react-i18next'

function EmailInput({ onChange, onBlur, value, label = "", name = "email", width = "100%", margin = "12px 0", disabled = false }) {
	const [error, setError] = useState(false)
	const { t } = useTranslation('input')

	useEffect(() => {
		if (value) {
			if (!validateEmail(value)) {
				setError(true)
			} else {
				setError(false)
			}
		}
	}, [value])

	function validateEmail(email) {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
		return emailRegex.test(email)
	}

	function handleBlur(event) {
		if (onBlur) onBlur(event)
	}

	return (
		<TextInput
			onChange={onChange}
			onBlur={handleBlur}
			name={name}
			label={label}
			value={value}
			error={error}
			errorMsge={t('emailInput.error')}
			width={width}
			margin={margin}
			disabled={disabled}
		/>
	)
}

export default EmailInput
