import React, { useState, useEffect } from 'react'
import { Dialog, Typography, Box } from '@mui/material'
import './animations.css'
import SubmitButton from '../Buttons/SubmitButton'
import { useTranslation } from 'react-i18next'

const css = {
  container: {
    position: 'relative',
    height: 250,
    width: 250,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loading_message: {
    padding: 2,
    textAlign: 'center'
  },
  close_container: {
    position: 'absolute',
    bottom: 12,
    right: 12
  }
}

function LoadingDialog({ open, loadingMessage = "", disableCancel = false }) {
  const [showOption, setShowOption] = useState(false)
  const [forceClose, setForceClose] = useState(false)
  const { t } = useTranslation('loader')

  useEffect(() => {
    let timeout
    if (open) {
      timeout = setTimeout(() => setShowOption(true), 5000)
    } else {
      clearTimeout(timeout)
      setShowOption(false)
    }
    return () => clearTimeout(timeout)
  }, [open])

  const handleForceClose = () => {
    setForceClose(true)
  }

  return (
    <Dialog open={open && !forceClose} sx={{
      '& .MuiDialog-paper': { borderRadius: '16px' }
    }}>
      <Box sx={css.container}>
        <Box className="lds-ring">
          <Box />
          <Box />
          <Box />
          <Box />
        </Box>
        <Typography sx={css.loading_message} variant="h4">
          {loadingMessage}
        </Typography>
      </Box>
      {showOption && !disableCancel && (
        <Box sx={css.close_container}>
          <SubmitButton onClick={handleForceClose} color="secondary">
            {t('close')}
          </SubmitButton>
        </Box>
      )}
    </Dialog>
  )
}

export default LoadingDialog
