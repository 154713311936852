import { useContext } from 'react'
import AppContext from '../../AppContext'

function IfOnline({ children }) {

	const { online: { status: online } } = useContext(AppContext)

	if (online) return children
	return null
}

export default IfOnline