import { Box, Typography } from '@mui/material'
import { info_box_white, info_box_grey, text_space } from '../../../../Utils/defaultStyles'
import { NotesOutlined, ScheduleOutlined } from '@material-ui/icons'
import moment from 'moment'
import { useEffect, useMemo, useRef } from 'react'
import { InfoRow } from './CardInfoLayouts'
import { useTranslation } from 'react-i18next'

function RejectionInfo({ finding }) {
	const { t } = useTranslation("findingCardInfo")
	// The current rejection is the last in the list
	const rejection = useMemo(() => finding.rejections[finding.rejections.length - 1] || {}, [finding.rejections])
	const html_content = useRef(null)

	useEffect(() => {
		if (!!html_content.current) {
			html_content.current.innerHTML = rejection.content
		}
	}, [html_content, rejection])

	return (
		<Box>
			<Typography variant='h4' sx={text_space} >{t('data.rejection')}:</Typography>
			<Box sx={info_box_grey}>
				<InfoRow icon={ScheduleOutlined} label={t('data.dateRejected')} value={moment(rejection?.created_at).format("DD-MM-YYYY HH:mm")} />
				<InfoRow icon={NotesOutlined} label={t('data.rejectedReason')} />
				<Box sx={info_box_white}>
					<Box ref={html_content} />
				</Box>
			</Box>
		</Box>
	)
}

export default RejectionInfo