import { Box } from '@mui/material'
import newTheme from '../../../../newTheme'

const css = {
	bar: {
		height: 4,
		width: '100%',
		background: `${newTheme.palette.blue.main}30`,
		position: 'absolute',
		left: 0,
		right: 0,
		borderRadius: 12
	},
	inner_bar: {
		position: 'absolute',
		left: 0,
		background: newTheme.palette.blue.main,
		height: 4,
		borderRadius: 12,
		transition: 'all 0.2s ease-in-out'
	}
}

function InlineProgressBar({ value, baseColor = newTheme.palette.info.main, fillColor = newTheme.palette.success.main }) {
	if (value === 1000) return null
	return (
		<Box sx={css.bar}>
			<Box sx={css.inner_bar} style={{ right: `${100 - value}%`, background: value >= 100 ? fillColor : baseColor }} />
		</Box>
	)
}

export default InlineProgressBar