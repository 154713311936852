import { Box, Typography } from '@mui/material'
import newTheme from '../../../newTheme'

const css = {
	tag: {
		padding: "4px 8px",
		borderRadius: 1,
		margin: '12px 0',
		maxWidth: 120
	}
}

const status_dict = {
	"Vencido": newTheme.palette.error.main,
	"Pendiente": newTheme.palette.warning.main,
	"Procesando": newTheme.palette.info.main,
	"Terminado": newTheme.palette.success.main,
	"Aprobado": newTheme.palette.success.main,
	"Rechazado": newTheme.palette.error.main,
}

function StatusTag({ status }) {
	return (
		<Box sx={{ ...css.tag, background: status_dict[status] }}>
			<Typography variant='subtitle2' color="white.main">{status}</Typography>
		</Box>
	)
}

export default StatusTag