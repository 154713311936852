export function updateField(fields, id, body) {
	const all_fields = flattenFields(fields)
	const selected_field = all_fields.find(f => f.id === id)
	selected_field["value"] = body.value
}

function flattenFields(fields) {
	let flatten = []
	flatten = flatten.concat(fields)
	for (const field of fields) {
		for (const option of field.options) {
			const subquestions = option.subquestions
			flatten = flatten.concat(flattenFields(subquestions))
		}
	}
	return flatten
}

export function updateChecklistWithNewSign(newSign, checklist, group) {
	const newGroups = [...checklist.group_signs]
	const newGroup = { ...group }
	const newGroupIndex = newGroups.findIndex(g => g.id === group.id)

	newGroup.signs.push(newSign)
	newGroups[newGroupIndex] = newGroup

	return {
		...checklist,
		group_signs: newGroups
	}
}