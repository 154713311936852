import instance2 from "../Utils/instance2"

export const answerChecklistFieldFast = (body, offline) => {
	const request = {
		method: 'put',
		url: 'checklists/field_fast',
		data: body
	}
	if (offline) return request
	return instance2(request)
}

export const changeMetadata = (body, offline) => {
	const request = {
		method: 'put',
		url: 'checklists/metadata',
		data: body
	}
	if (offline) return request
	return instance2(request)
}

export const signChecklistGroupRegistered = (body, offline) => {
	const request = {
		method: 'post',
		url: 'checklists/sign_group_registered',
		data: body
	}
	if (offline) return request
	return instance2(request)
}

export const editChecklist = (body, offline) => {
	const request = {
		method: 'put',
		url: 'checklists',
		data: body
	}
	if (offline) return request
	return instance2(request)
}

export const updateChecklistSubdivision = body => {
	return instance2({
		method: 'put',
		url: 'checklists/subdivision',
		data: body
	})
}

export const getTracing = body => {
	return instance2({
		method: 'get',
		url: 'checklists/tracing',
		params: body
	})
}

export const deleteChecklist = body => {
	return instance2({
		method: 'post',
		url: 'checklists/delete.php',
		data: body
	})
}

export const authorizationNotification = body => {
	return instance2({
		method: 'post',
		url: 'checklists/auth_notification',
		data: body
	})
}

export const editAttachments = body => {
	return instance2({
		method: 'put',
		url: 'checklists/attachment',
		data: body
	})
}

export const getPublicInfo = body => {
	return instance2({
		method: 'get',
		url: 'checklists/public',
		params: body
	})
}

export const finishPublicChecklist = body => {
	return instance2({
		method: 'post',
		url: 'checklists/public',
		data: body
	})
}

export const searchChecklistsByContent = body => {
	return instance2({
		method: 'get',
		url: 'checklists/content',
		params: body
	})
}

export const getOpenChecklists = body => {
	return instance2({
		method: 'get',
		url: 'checklists/open',
		params: body,
		signal: body?.signal
	})
}

export const getClosedChecklists = body => {
	return instance2({
		method: 'get',
		url: 'checklists/closed',
		params: body,
		signal: body?.signal
	})
}

export const getChecklistForm = body => {
	return instance2({
		method: 'get',
		url: 'checklists/form',
		params: body,
		signal: body?.signal
	})
}

export const createFreeChecklist = body => {
	return instance2({
		method: 'post',
		url: 'checklists',
		data: body
	})
}

export const getChecklistSigns = body => {
	return instance2({
		method: 'get',
		url: 'checklists/signs',
		params: body
	})
}

export const toggleCategoryDisable = (body, offline) => {
	const request = {
		method: 'put',
		url: 'checklists/categories/toggle',
		data: body
	}
	if (offline) return request
	return instance2(request)
}

export const toggleCategoryUserDisable = (body, offline) => {
	const request = {
		method: 'put',
		url: 'checklists/categories/toggle_user',
		data: body
	}
	if (offline) return request
	return instance2(request)
}

export const rejectChecklist = (body, offline) => {
	const request = {
		method: 'put',
		url: 'checklists/reject',
		data: body
	}
	if (offline) return request
	return instance2(request)
}

export const editChecklistTags = body => {
	return instance2({
		method: 'put',
		url: 'checklists/tags',
		data: body
	})
}