import { Box, Typography, Chip } from '@mui/material'
import { Announcement as AnnouncementIcon } from '@mui/icons-material'
import MyDialog from '../../../../Shared/Dialogs/MyDialog'
import { useTranslation } from 'react-i18next'

function InstructionsDialog({ open, onClose }) {
	const { t } = useTranslation('checklistRejection')

	const css = {
		contentContainer: {
			display: "flex",
			flexDirection: "column",
			gap: "12px",
			padding: "16px"
		},
		infoRow: {
			display: "flex",
			alignItems: "center",
			gap: "8px"
		},
		chip: {
			minWidth: "70px"
		}
	}

	return (
		<MyDialog open={open} onClose={onClose} title={t('titles.instructions')}>
			<Typography variant="h2">
				{t('instructions.rejectionCommentsDescription')}
			</Typography>
			<Box sx={css.contentContainer}>
				<Box sx={css.infoRow}>
					<AnnouncementIcon color="warning" sx={{ minWidth: "70px" }} />
					<Typography variant="body1">
						{t('instructions.addComment')}
					</Typography>
				</Box>

				<Box sx={css.infoRow}>
					<Chip label="Campo" sx={css.chip} color="secondary" />
					<Typography variant="body1">
						{t('instructions.associatedField')}
					</Typography>
				</Box>
			</Box>
		</MyDialog>
	)
}

export default InstructionsDialog
