import { useContext } from "react"
import { ChecklistContext } from "../../Show/ChecklistView"
import { Box, Button, Typography } from "@mui/material"
import { LocationOn } from "@mui/icons-material"
import {
	inline_buttons,
	italic,
	italic_link,
} from "../../../../Utils/defaultStyles"
import { useTranslation } from "react-i18next"

function LocationField({ field, onChangeAndSave, value, onBlur }) {
	const { finished, isAdmin, hasPermissions } = useContext(ChecklistContext)
	const { t } = useTranslation("checklistForm")

	if (finished || !hasPermissions)
		return (
			<Box>
				{value ? (
					<Box sx={inline_buttons}>
						<Typography variant="subtitle1" sx={italic_link} onClick={openLocation}>
							{t("location.view")}
						</Typography>
						<LocationOn color="info" />
					</Box>
				) : (
					<Typography variant="subtitle1">{t("noAnswer")}</Typography>
				)}
			</Box>
		)

	async function getLocation() {
		if ("geolocation" in navigator) {
			navigator.geolocation.getCurrentPosition((position) => {
				const { latitude, longitude } = position.coords
				const coordinates = `${latitude}&#&${longitude}`
				const new_event = {
					target: {
						name: field.label,
						value: coordinates,
					},
				}
				onChangeAndSave(new_event)
			})
		} else {
			console.error(t("location.geolocationNotAvailable"))
		}
	}

	function openLocation() {
		const [latitude, longitude] = value.split("location.&#&")
		const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
		window.open(url, "_blank")
	}

	return (
		<Box>
			{!!value ? (
				<Typography variant="subtitle1" sx={italic_link} onClick={openLocation}>
					{t("location.view")}
				</Typography>
			) : (
				<Typography
					variant="subtitle1"
					color="warning.main"
					sx={italic}
				>
					{t("location.notRecorded")}
				</Typography>
			)}
			<Button
				variant="outlined"
				color="info"
				onClick={getLocation}
				size="small"
				endIcon={<LocationOn />}
				sx={{ marginTop: 2 }}
			>
				{t("location.capture")}
			</Button>
		</Box>
	)
}

export default LocationField
