import { Box, IconButton, Typography, useMediaQuery } from '@mui/material'
import { useRef, useState } from 'react'
import { resizeImage } from '../../Utils/functions'
import newTheme from '../../newTheme'
import MultipleImageContainer from '../Containers/MultipleImageContainer'
import { useTranslation } from 'react-i18next'
import { AddAPhotoOutlined, AddPhotoAlternateOutlined } from '@mui/icons-material'
import SelectImage from './Mobile/SelectImage'

const css = {
	input: {
		border: "1px solid lightgrey",
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '6px 12px',
		borderRadius: 1
	},
	images: {
		background: newTheme.palette.background.main,
		marginTop: 2,
		padding: 2,
		borderRadius: 1
	}
}

function PlainImageInput({ value = "", onChange, name, disableMultiple }) {
	// Value is a string separated by &#& containing the base64 of the group of images selected to upload
	const { t } = useTranslation('input')
	const hidden_folder_file = useRef(null)
	const images = !!value ? value?.split("&#&") || [] : []
	const isMobile = useMediaQuery('(max-width:850px)')
	const [openDrawer, setOpenDrawer] = useState(false)
	const dialogRef = useRef(null)

	function photoDrawer() {
		if (isMobile) {
			setOpenDrawer(true)
		}
		else {
			simulateFolderClick()
		}
	}

	function simulateFolderClick() {
		hidden_folder_file.current.click()
	}

	async function handleChange(event) {
		const { target } = event
		const { files } = target
		const file = files[0]
		const image = await resizeImage(file)
		const file_name = file.name.split('.').slice(0, -1).join('.') // Remove the extension
		const image_with_name = `${image}|&|${file_name}`
		const new_images = [...images]
		new_images.push(image_with_name)
		let new_value = ""
		if (disableMultiple) {
			new_value = image_with_name
		} else {
			new_value = new_images.join("&#&")
		}
		onChange({ target: { name, value: new_value } })
		setOpenDrawer(false)
	}

	function onDeleteImage(index) {
		const new_images = [...images]
		new_images.splice(index, 1)
		const new_value = new_images.join("&#&")
		onChange({ target: { name, value: new_value } })
	}

	const images_without_name = images.map(image => image.split("|&|")[0])

	return (
		<Box ref={dialogRef}>
			<Box sx={css.input}>
				<Box>
					<Typography variant='subtitle1'>{t('actions.addImage')}</Typography>
				</Box>
				<IconButton onClick={photoDrawer}>
					<AddPhotoAlternateOutlined />
				</IconButton>
				<input type="file" accept="image/*" id="file" hidden ref={hidden_folder_file} onChange={handleChange} />
			</Box>
			{!!images.length &&
				<Box sx={css.images}>
					<MultipleImageContainer images={images_without_name} align='left' grid height={64} width={64} onRemove={onDeleteImage} />
				</Box>
			}
			<SelectImage open={openDrawer} onClose={() => setOpenDrawer(false)} dialogRef={dialogRef} addPhoto={handleChange} />
		</Box>
	)
}

export default PlainImageInput