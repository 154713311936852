import { Box, Button, Dialog, IconButton, Typography } from '@mui/material'
import { dialog_style, inline_title_no_wrap, labeless, section_separation, text_space } from '../../Utils/defaultStyles'
import { createContext, useContext, useMemo, useState } from 'react'
import { UserSelectContext } from '../Dialogs/UserSelectDialog'
import { Clear } from '@mui/icons-material'
import TextInput from '../Inputs/TextInput'
import { TOTP } from 'totp-generator'
import useToggle from '../../Hooks/ToogleHook'
import PinInput from './PinInput'

export const OTPContext = createContext({})

export function useOTPDialog() {
	const { verifyPin } = useContext(OTPContext)
	return verifyPin
}

function OTPDialog({ children }) {

	const { getUsersObjects } = useContext(UserSelectContext)
	const [openDialog, toggleDialog] = useToggle(false)
	const [pin, setPin] = useState('')
	const [status, setStatus] = useState('')
	const [userId, setUserId] = useState('')
	const [action, setAction] = useState(() => { })
	const [currentUser, setCurrentUser] = useState({})

	function verifyPin({ user_id, callback }) {
		setAction(() => callback)
		setPin('')
		setUserId(user_id)
		const user = getUsersObjects(user_id)
		setCurrentUser(user)
		toggleDialog()
	}

	function verifyPinCode() {
		const otp_key = currentUser.otp_key
		if (!otp_key) return setStatus('El usuario no tiene configurado un pin de seguridad')
		const { otp, expires } = TOTP.generate(otp_key, { digits: 6, period: 60 })
		console.log(otp, expires)
		if (pin !== otp) return setStatus('Pin incorrecto')
		setStatus('Verificado exitosamente')
		action(userId)
		toggleDialog()
	}

	function onCancel() {
		setPin('')
		setStatus('')
		toggleDialog()
	}

	return (
		<Box>
			<Dialog open={openDialog} fullWidth maxWidth="sm">
				<Box sx={dialog_style}>
					<Box sx={inline_title_no_wrap}>
						<Typography color="info.main" variant='h4'>Ingresar pin de seguridad de {currentUser?.name}</Typography>
						<IconButton onClick={onCancel}>
							<Clear />
						</IconButton>
					</Box>
					<Box sx={text_space}>
						<Typography variant='subtitle2'>Ingrese Pin</Typography>
						<PinInput value={pin} onChange={e => setPin(e.target.value)} length={6} />
					</Box>

					<Button color="success" variant='contained' fullWidth onClick={verifyPinCode} disabled={pin.length < 6}>
						Verificar
					</Button>

					<Box sx={section_separation}>
						<Typography variant='h1'>{status}</Typography>
					</Box>
				</Box>
			</Dialog>
			<OTPContext.Provider value={{ verifyPin }}>
				{children}
			</OTPContext.Provider>
		</Box>
	)
}

export default OTPDialog