import React from 'react'
import { Box, Dialog, Typography } from '@mui/material'
import { ErrorOutline } from '@mui/icons-material'
import SelectInput from '../../../../Shared/Inputs/SelectInput'
import { dialog_style, inline_buttons, labeless, title_style, text_space } from '../../../../Utils/defaultStyles'
import { useTranslation } from 'react-i18next'

function SuppliesSelector({ open, options, checklist, handleChange, disabled }) {
	const { t } = useTranslation('checklistShow')

	return (
		<Dialog open={open} maxWidth='sm' fullWidth >
			<Box sx={dialog_style} >
				<Typography variant='h4' sx={title_style} >{t('supplies.relacionated')}</Typography>
				<Box sx={{ ...inline_buttons, ...text_space }}>
					<ErrorOutline color="warning" />
					<Typography variant='caption' color='warning.main'>{t('supplies.description')}</Typography>
				</Box>
				<Typography variant='subtitle2' sx={labeless}>{t('supplies.select')}</Typography>
				<SelectInput disabled={disabled} value={checklist.supply_id} label="" options={options} onChange={handleChange} />
			</Box >
		</Dialog>
	)
}

export default SuppliesSelector