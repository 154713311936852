import { Box, Typography } from '@mui/material'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import newTheme from '../../../../newTheme'
import { ChecklistContext } from '../ChecklistView'
import { italic, title_style } from '../../../../Utils/defaultStyles'

const css = {
	paper: {
		padding: 2,
		margin: '12px 0',
		borderRadius: 2,
		background: newTheme.palette.success.light,
	},
	expired: {
		padding: 2,
		margin: '12px 0',
		borderRadius: 2,
		background: newTheme.palette.warning.light,
	},
}

function FinishedCard({ value }) {
	const { t } = useTranslation('checklistShow')
	const { checklist } = useContext(ChecklistContext)
	const expired = checklist?.expired && checklist?.date_done === '0000-00-00'

	return (
		<Box sx={expired ? css.expired : css.paper}>
			<Box>
				<Typography color="white.main" variant="h4" sx={title_style}>
					{t(expired ? 'cards.expiredChecklist' : 'cards.finishedChecklist')}
				</Typography>
				<Typography color="white.main" variant="subtitle1">{checklist?.user}</Typography>
				{!expired && (
					<Typography color="white.main" variant="subtitle1" sx={italic}>
						{checklist.date_done} {checklist.time_done}
					</Typography>
				)}
			</Box>
		</Box>
	)
}

export default FinishedCard
