import { Box, Dialog, IconButton, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { getChecklistForm } from '../../API/new_checklists'
import useToggle from '../../Hooks/ToogleHook'
import { dialog_style, inline_space_title } from '../../Utils/defaultStyles'
import newTheme from '../../newTheme'
import RenderReadOnlyFields from '../../Components/Checklist/Shared/RenderReadOnlyFields'
import { Clear } from '@material-ui/icons'
import MiniLoaderAnimator from '../Loader/MiniLoaderAnimator'
import AppContext from '../../AppContext'
import { useTranslation } from 'react-i18next'

const css = {
	link: {
		cursor: 'pointer',
		textDecoration: 'underline',
		color: newTheme.palette.info.main,
		fontWeight: 600,
		fontStyle: 'italic'
	},
	disabled: {
		color: newTheme.palette.grey.medium,
		fontWeight: 600,
		fontStyle: 'italic'
	},
	fields: {
		maxHeight: 500,
		overflowY: 'auto',
	}
}

function ChecklistFieldsRender({ value, element }) {

	const [form, setForm] = useState([])
	const [categories, setCategories] = useState([])
	const [loading, setLoading] = useState(false)
	const [open, toggleOpen] = useToggle(false)
	const [fetched, setFetched] = useState(false)
	const { online: { status: online } } = useContext(AppContext)
	const [supply, setSupply] = useState(null)
	const { t } = useTranslation('tableRenders')

	useEffect(() => {
		let abortController
		async function fetchData() {
			abortController = new AbortController()
			const signal = abortController.signal
			setLoading(true)
			const response = await getChecklistForm({ id: element.id, signal })
			setFetched(true)
			setLoading(false)
			setForm(response.data.info)
			setSupply(response.data.supply)
			setCategories(response.data.categories)
		}

		if (!!element.id && open && !fetched) { fetchData() }
		return () => {
			if (!!abortController) { abortController.abort() }
		}
	}, [element, open, fetched])

	return (
		<Box>
			<Dialog open={open} onClose={toggleOpen} fullWidth maxWidth="sm" fullScreen={window.innerWidth <= 600} >
				<Box sx={dialog_style}>
					<Box sx={inline_space_title}>
						<Typography variant='h4'>{t('titles.formOf', { value: value })}</Typography>
						<IconButton onClick={toggleOpen}>
							<Clear />
						</IconButton>
					</Box>
					<Box sx={css.fields}>
						{loading ?
							<MiniLoaderAnimator />
							:
							<RenderReadOnlyFields form={form} supply={supply} categories={categories} />
						}
					</Box>
				</Box>
			</Dialog>
			<Typography variant='subtitle1' sx={online ? css.link : css.disabled} onClick={online ? toggleOpen : null} >{value}</Typography>
		</Box>
	)
}

export default ChecklistFieldsRender