import { Divider } from '@material-ui/core'
import { Box, Button, Collapse, Dialog, Typography } from '@mui/material'
import moment from 'moment'
import { useContext, useState } from 'react'
import useToggle from '../../../Hooks/ToogleHook'
import { completePath } from '../../../Utils/functions'
import { ChecklistContext } from './ChecklistView'
import { inline, inline_space, inline_space_no_wrap, paper_style, title_style } from '../../../Utils/defaultStyles'
import TextInput from '../../../Shared/Inputs/TextInput'
import { useTranslation } from 'react-i18next'
import AuthorizationCard from './Cards/AuthorizationCard'
import { CheckCircleOutline } from '@mui/icons-material'
import newTheme from '../../../newTheme'
import RejectionCommentField from './RejectionField/RejectionCommentField'

const css = {
	container: {
		padding: 2,
		background: 'white',
		borderRadius: 2,
		margin: '24px 0'
	},
	authorization: {
		padding: 2,
		borderRadius: 1,
		background: 'whitesmoke',
		marginTop: 2
	},
	sings: {
		background: 'whitesmoke',
		borderRadius: 1,
	},
	dialog: {
		padding: 2
	},
	check_icon: {
		color: newTheme.palette.green.main,
		height: 30,
		width: 30
	},
}

const old_sign_url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_USER_SIGNS}`

function Authorization({ authorization, index, onSign, disabled, isLastAuthorization, onReject }) {
	const [openSign, toggleSign] = useToggle(false)
	const [selectedSign, setSign] = useState({})
	const { users, signs, amount } = authorization
	const [openReject, toggleReject] = useToggle(false)
	const { t } = useTranslation('checklistShow')
	const { setRejecting, setRejectionComment, rejectionComment, checklist } = useContext(ChecklistContext)
	const [generalComment, setGeneralComment] = useState(rejectionComment.split(";")[0] || "")
	const items = checklist.checklist_items2 || []
	const fields = checklist?.checklist_fields || []

	function handleReject() {
		const body = rejectionComment
		onReject(body)
		toggleReject()
		setRejectionComment('')
		setRejecting(false)
	}


	function handleSign(user) {
		const should_notify = amount === signs.length + 1
		const data = {
			user_id: user.id,
			name: user.name,
			authorization_id: authorization.id,
		}
		const body = { data, notify: should_notify, index, isLastAuthorization }
		onSign(body)
	}

	function gotoSign(sign) {
		setSign(sign)
		toggleSign()
	}

	function stopReject() {
		setRejecting(false)
		toggleReject()
	}

	function handleGeneralComment(event) {
		const { target } = event
		const newGeneralComment = target.value

		setGeneralComment(newGeneralComment)

		setRejectionComment(prevComment => {
			const parts = prevComment.split(";")
			parts[0] = newGeneralComment
			return parts.join(";")
		})
	}

	const isAuthorized = amount <= signs.length

	return (
		<Box sx={css.authorization}>
			<Dialog open={openSign} onClose={toggleSign} fullWidth maxWidth="sm">
				<Box sx={css.dialog}>
					<Typography variant="h4">{t('authorizations.signatureOf', { name: selectedSign.name })}</Typography>
					<img src={completePath(selectedSign.url, old_sign_url)} width="100%" alt={t('authorizations.signature')} />
					<Typography variant="subtitle1">
						{t('authorizations.signedOn')}: {moment(selectedSign.date).format('authorizations.YYYY-MM-DD HH:mm')}
					</Typography>
				</Box>
			</Dialog>
			<Box sx={{ ...inline_space_no_wrap, ...title_style }}>
				<Box>
					<Typography variant="subtitle2">{authorization.name}</Typography>
					<Typography variant="caption">
						{amount > 1 ? t('authorizations.requireSignatures', { count: amount }) : t('authorizations.requireSignature', { count: amount })}
					</Typography>
				</Box>
				{isAuthorized &&
					<Box sx={{ ...inline }}>
						<Typography variant="h1" color="success">{t('authorizations.authorized')}</Typography>
						<CheckCircleOutline sx={css.check_icon} />
					</Box>
				}
			</Box>
			<Box sx={css.sings}>
				{users.map((user, index) => {
					const isSigned = signs.map((s) => s.user_id).includes(user.id)
					return (
						<>
							<AuthorizationCard
								disabled={disabled}
								user={user}
								signs={signs}
								isSigned={isSigned}
								toggleReject={toggleReject}
								authorization={authorization}
								handleSign={handleSign}
								gotoSign={gotoSign}
								isAuthorized={isAuthorized}
							/>
							<Divider />
						</>
					)
				})}
			</Box>
			<Collapse in={openReject}>
				<Box sx={{ ...paper_style, marginTop: 2 }}>
					<Typography variant="h4" color="warning.main" sx={title_style}>
						{t('authorizations.rejectRecord')}
					</Typography>
					<Typography variant="subtitle2">{t('authorizations.enterRejectionReason')}</Typography>
					<TextInput value={generalComment} onChange={handleGeneralComment} label="" />
					<RejectionCommentField items={items} motive={rejectionComment} fields={fields} />
				</Box>
				<Box sx={{ ...inline_space, marginTop: 3 }}>
					<Button color="primary" variant="contained" onClick={stopReject}>
						{t('buttons.cancel')}
					</Button>
					<Button color="warning" variant="contained" onClick={handleReject}>
						{t('authorizations.saveAndReject')}
					</Button>
				</Box>
			</Collapse>
		</Box>
	)
}

function Authorizations({ onSign, onReject }) {

	const { checklist } = useContext(ChecklistContext)
	const { t } = useTranslation('checklistShow')

	const authorizations = checklist.authorizations || []
	if (!authorizations.length) return null

	return (
		<Box sx={css.container}>
			<Typography variant='h1'>{t('authorizations.title')}</Typography>
			{authorizations.map((authorization, index) => {
				const isPreviousSigned = index > 0 ? authorizations[index - 1].signs.length >= authorizations[index - 1].amount : true
				return <Authorization
					disabled={!isPreviousSigned}
					onSign={onSign}
					authorization={authorization}
					index={index}
					key={authorization.id}
					isLastAuthorization={index === authorizations.length - 1}
					onReject={onReject}
				/>
			})}
		</Box>
	)
}

export default Authorizations