import { Box, Typography } from '@mui/material'
import { useContext } from 'react'
import { AttachFile } from '@material-ui/icons'
import newTheme from '../../../../newTheme'
import { inline, italic, text_space } from '../../../../Utils/defaultStyles'
import BigTooltip from '../../../../Shared/Tooltip/BigTooltip'
import { ChecklistContext } from '../../Show/ChecklistView'
import { useTranslation } from 'react-i18next'


const css = {
	container: {
		padding: 2,
		background: newTheme.palette.white.main,
		borderRadius: 2
	}
}

function Attachments() {

	const { checklist } = useContext(ChecklistContext)
	const { t } = useTranslation('checklistSigns')

	const attachments = checklist.attachment?.split("&#&") || []

	return (
		<Box sx={css.container}>
			{attachments.map((att, index) => {
				const att_array = att.split("/")
				const name = att_array[att_array.length - 1]
				return (
					<Box sx={text_space}>
						<Box sx={{ ...inline }}>
							<AttachFile />
							<BigTooltip title={t('openFile')} placement="top">
								<Typography onClick={() => window.open(att)} style={{ cursor: "pointer" }} color="info.main" variant='subtitle2' sx={italic} >{name}</Typography>
							</BigTooltip>
						</Box>
					</Box>
				)
			})}
		</Box >
	)
}

export default Attachments