import { Dialog, Box, Typography, IconButton, Button } from "@mui/material"
import { full_dialog_style, inline_space_title, end_buttons, title_style } from "../../../../Utils/defaultStyles"
import { Clear } from '@material-ui/icons'
import MainInfo from "../CardInfo/MainInfo"

function DialogLayout({ titles, open, onClose, finding, disableSave, button_styles, onClick, urls, crit, children }) {

	return (
		<Dialog open={open} onClose={onClose} fullScreen>
			<Box sx={full_dialog_style}>
				<Box sx={inline_space_title}>
					<Typography variant='h1'> {titles.title}</Typography>
					<IconButton onClick={onClose}>
						<Clear />
					</IconButton>
				</Box>
				<Typography variant='h4' sx={title_style} >{finding.free_description}</Typography>
				<MainInfo finding={finding} crit={crit} urls={urls} />
				{children}
				<Box sx={end_buttons}>
					<Button variant={button_styles.variant} color={button_styles.color} endIcon={button_styles.icon} onClick={onClick} disabled={disableSave || false}>
						{titles.action}
					</Button>
				</Box>
			</Box>
		</Dialog>
	)
}

export default DialogLayout