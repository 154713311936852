import { Box, Button, Typography } from "@mui/material"
import newTheme from "../../newTheme"
import { getHexValueFromAcquisition, identifyUserFingerprint } from "../../API/fingerprint"
import { useContext, useEffect, useMemo, useState } from "react"
import AppContext from "../../AppContext"
import { end_buttons, inline, inline_buttons, inline_space_no_wrap, text_space } from "../../Utils/defaultStyles"
import { Fingerprint, WifiOff } from "@mui/icons-material"
import { UserSelectContext } from "../../Shared/Dialogs/UserSelectDialog"
import { useTranslation } from "react-i18next"

const { FingerprintReader, SampleFormat } = require("@digitalpersona/devices")

const css = {
	container: {
		margin: '12px 0',
		background: newTheme.palette.white.main,
		borderRadious: 2,
		padding: 2
	},
	finger_image: {
		background: newTheme.palette.background.main,
		padding: 1,
		borderRadius: 1,
		margin: '12px 0',
		textAlign: 'center'
	}
}

function FingerSignCapture({ callback }) {
	const { enterprise, online: { status: online } } = useContext(AppContext)
	const [finger_reader, setReader] = useState(null)
	const [scaning, setScaning] = useState(false)
	const [uploading, setUploading] = useState(false)
	const [success, setSuccess] = useState(false)
	const [error, setError] = useState(false)
	const [identifiedName, setIdentifiedName] = useState(null)
	const { getUsersNames } = useContext(UserSelectContext)
	const { t } = useTranslation('fingerprint')

	useEffect(() => {
		const reader = new FingerprintReader()
		reader.on("DeviceConnected", () => console.log("Connected"))
		reader.on("SamplesAcquired", sampleAquire)
		setReader(reader)

		return () => {
			reader && reader.stopAcquisition()
		}
	}, [])

	async function startReading() {
		await finger_reader.startAcquisition(SampleFormat.Raw)
	}

	async function sampleAquire(data) {
		setUploading(true)
		setSuccess(false)
		setError(false)
		const processed_data = getHexValueFromAcquisition(data)
		const { hex, height, width, dpi } = processed_data
		const body = {
			enterprise_id: enterprise?.id,
			hex, width, height, dpi
		}
		const response = await identifyUserFingerprint(body)
		const successfully_identified = response.data.status === 200
		setUploading(false)
		if (successfully_identified) {
			const identified_id = response.data.data
			const id_name = getUsersNames(identified_id)
			setIdentifiedName(id_name)
			if (!!callback) await callback(identified_id)
			setSuccess(true)
			setError(false)
		} else {
			setError(true)
			setSuccess(false)
		}
	}

	function onStartScanning() {
		setScaning(true)
		startReading()
	}

	const finger_color = useMemo(() => {
		if (success) return "success"
		if (error) return "error"
		if (scaning) return "info"
		if (uploading) return "info"
		return "primary"
	}, [scaning, uploading, success, error])

	return (
		<Box sx={{ ...css.container, opacity: online ? 1 : 0.5, pointerEvents: online ? "auto" : "none" }}>
			<Box sx={inline_space_no_wrap}>
				<Box sx={inline_buttons}>
					<Fingerprint color="info" />
					<Typography variant="h4">{t('signs')}</Typography>
				</Box>
				{!online &&
					<Box sx={inline}>
						<Typography variant="caption">{t('offline')}</Typography>
						<WifiOff />
					</Box>
				}
			</Box>
			<Box sx={text_space}>
				{success && <Typography variant="subtitle2" color="success.main">{t('identify.succeded')}: {identifiedName}</Typography>}
				{error && <Typography variant="subtitle2" color="error.main">{t('identify.failed')}</Typography>}
			</Box>
			<Box sx={css.finger_image}>
				<Fingerprint className={uploading ? 'finger_blink' : ""} color={finger_color} sx={{ fontSize: 100 }} />
			</Box>
			<Box sx={end_buttons}>
				<Button
					color="info"
					variant="contained"
					endIcon={<Fingerprint />}
					onClick={onStartScanning}
					disabled={!online} // Deshabilita botón si no está en línea
				>
					{t('startScan')}
				</Button>
			</Box>
		</Box>
	)
}

export default FingerSignCapture
