import { Box } from '@mui/material'
import { createContext } from 'react'

export const PendingManagerContext = createContext({})

function PendingManager({ children, db }) {

	function addPending(pending) {
		if (!db) return null
		const request = db.transaction(["pendings"], "readwrite")
			.objectStore("pendings")
			.put(pending)

		request.onsuccess = function (event) {
			console.log("Pending has been added to the store")
		}

		request.onerror = function (event) {
			console.log("Error adding pending to the store")
		}
	}

	function addPendings(pendings) {
		if (!db) return null
		const transaction = db.transaction(["pendings"], "readwrite")
		const store = transaction.objectStore("pendings")

		for (const pending of pendings) {
			store.put(pending)
		}

		transaction.oncomplete = function (event) {
			console.log("Pendings has been added to the store")
		}

		transaction.onerror = function (event) {
			console.log("Error adding pendings to the store")
		}
	}

	function getPending(id) {
		if (!db) return false
		const request = db.transaction(["pendings"]).objectStore("pendings").get(id)

		return new Promise((resolve, reject) => {
			request.onsuccess = function (event) {
				const pending = request.result
				if (pending) {
					resolve(pending)
				} else {
					resolve(null) // Resolve with null when pending is not found
				}
			}

			request.onerror = function (event) {
				console.log("Error getting pending from the store")
				reject(request.error)
			}
		})
	}

	async function checkIfPendingExists(pending_id) {
		const exists = await getPending(pending_id)
		return !!exists
	}

	async function removeAllPendings() {
		const transaction = db.transaction(["pendings"], "readwrite")
		const objectStore = transaction.objectStore("pendings")

		objectStore.clear()

		return new Promise((resolve, reject) => {
			transaction.oncomplete = function (event) {
				console.log("Pendings has been removed from the store")
				resolve()
			}

			transaction.onerror = function (event) {
				console.log("Error removing pendings from the store")
				reject(event.target.error)
			}
		})
	}

	async function keepOnlyPendings(pending_ids) {
		const transaction = db.transaction(["pendings"], "readwrite")
		const objectStore = transaction.objectStore("pendings")

		const getAllRequest = objectStore.getAll()

		getAllRequest.onsuccess = function (event) {
			const allPendings = event.target.result
			const pendingIDsToDelete = allPendings
				.filter((pending) => !pending_ids.includes(pending.id))
				.map((pending) => pending.id)

			for (const id of pendingIDsToDelete) {
				objectStore.delete(id)
			}
		}

		return new Promise((resolve, reject) => {
			transaction.oncomplete = function (event) {
				console.log("Pendings have been filtered and removed from the store")
				resolve()
			}

			transaction.onerror = function (event) {
				console.log("Error removing pendings from the store")
				reject(event.target.error)
			}
		})
	}

	function setPendings(pendings) {
		if (!db) return null
		const parsed_pendings = pendings.map(pending => ({ ...pending, unique_id: `${pending.id}_${pending.type}` }))
		const transaction = db.transaction(["pendings"], "readwrite")
		const store = transaction.objectStore("pendings")
		store.clear()

		for (const pending of parsed_pendings) {
			store.put(pending)
		}

		transaction.oncomplete = function (event) {
			console.log("Pendings has been added to the store")
		}

		transaction.onerror = function (event) {
			console.log("Error adding pendings to the store")
		}
	}

	function getPendings() {
		if (!db) return null
		const objectStore = db.transaction("pendings").objectStore("pendings")
		const pendings = []

		return new Promise((resolve, reject) => {
			objectStore.openCursor().onsuccess = function (event) {
				const cursor = event.target.result
				if (cursor) {
					pendings.push(cursor.value)
					cursor.continue()
				} else {
					resolve(pendings)
				}
			}

			objectStore.openCursor().onerror = function (event) {
				console.log("Error getting pendings from the store")
				reject(event.target.error)
			}
		})
	}

	return (
		<PendingManagerContext.Provider value={{
			addPending,
			addPendings,
			getPending,
			getPendings,
			setPendings,
			removeAllPendings,
			checkIfPendingExists,
			keepOnlyPendings
		}}>
			{children}
		</PendingManagerContext.Provider>
	)
}

export default PendingManager