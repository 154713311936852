import { Box, Typography } from '@mui/material'
import newTheme from '../../../../newTheme'
import { Warning } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import { checkForItemRepetition } from '../../../../API/findings'
import { useTranslation } from 'react-i18next'

const css = {
	banner: {
		background: newTheme.palette.warning.main,
		padding: 2,
		borderRadius: 1,
		margin: '12px 0',
		display: 'flex',
		alignItems: 'center',
		gap: 1
	}
}

function RepetitionWarning({ finding }) {

	const [show, setShow] = useState(false)
	const { t } = useTranslation("findingCreationDialog")
	useEffect(() => {
		async function fetchData() {
			const response = await checkForItemRepetition(finding)
			const status = response.data.status || "success"
			if (status === "error") { setShow(true) }
		}
		if (!!finding.checklist_item_id && !!finding.checklist_id) fetchData()
	}, [finding])

	if (!show) return null

	return (
		<Box sx={css.banner}>
			<Warning color="white" />
			<Typography color="white.main" variant='subtitle2'>{t('messages.findingExists')}</Typography>
		</Box>
	)
}

export default RepetitionWarning
