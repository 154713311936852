import { Box, Typography, IconButton } from '@mui/material'
import { useContext, useRef } from 'react'
import { ChecklistContext } from '../../Show/ChecklistView'
import { italic, underline_style } from '../../../../Utils/defaultStyles'
import { uploadFile } from '../../../../API/users'
import { UploadFile } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { DeleteOutline } from '@material-ui/icons'
import { useState } from 'react'
const css = {
	container: {
		margin: "4px 0",
		cursor: 'pointer',
	},
	input: {
		border: "1px solid lightgrey",
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '6px 12px',
		borderRadius: 1
	}
}

export function extractNameFromUrl(url) {
	let fileNameWithTimestamp = url.substring(url.lastIndexOf('/') + 1)
	fileNameWithTimestamp = decodeURIComponent(fileNameWithTimestamp)
	const nameWithExtension = fileNameWithTimestamp.substring(10)
	const name = nameWithExtension.replace(/\.[^/.]+$/, "")
	return name
}

function DocumentField({ field, value, onChangeAndSave }) {
	const { finished, hasPermissions } = useContext(ChecklistContext)
	const [document, setDocument] = useState(value)
	const hidden_file = useRef(null)
	const { t } = useTranslation('checklistForm')

	function simulateClick() {
		hidden_file.current.click()
	}

	function goToDocument() {
		window.open(document, '_blank')
	}

	if (finished || !hasPermissions) return (
		<Box onClick={goToDocument} sx={css.container}>
			{!!document ?
				<Typography sx={italic} color="info.main" variant='subtitle2'>{extractNameFromUrl(document)}</Typography> :
				<Typography variant='subtitle1'>{t('noAnswer')}</Typography>
			}
		</Box>
	)

	async function onUpload(event) {
		let file
		if (event.target && event.target.files) {
			file = event.target.files[0]
		} else if (event.dataTransfer && event.dataTransfer.files) {
			file = event.dataTransfer.files[0]
		}
		if (!file) return

		const body = new FormData()
		body.append('file', file)
		body.append('name', file.name)
		const response = await uploadFile(body)
		const url = response.data.info
		const new_event = { target: { value: url } }
		setDocument(url)
		onChangeAndSave(new_event)
	}

	function handleDeleteDocument() {
		setDocument(null)
		onChangeAndSave({ target: { value: null } })
	}

	return (
		<Box sx={css.input}>
			<Box>
				{document ?
					<Typography variant='subtitle1' color={"info.main"} sx={underline_style} onClick={goToDocument}>{document ? extractNameFromUrl(document) : t('document.add')}</Typography>
					:
					<Typography variant='subtitle1' >{t('document.add')}</Typography>
				}
			</Box>
			<Box>

				<IconButton onClick={simulateClick}>
					<UploadFile />
				</IconButton>
				{document &&
					<IconButton sx={{ padding: 1 }} onClick={handleDeleteDocument} >
						<DeleteOutline />
					</IconButton>
				}
			</Box>
			<input type="file" id="file" hidden ref={hidden_file} onChange={onUpload} />
		</Box>
	)
}

export default DocumentField
