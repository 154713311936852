import { useContext } from "react"
import { ChecklistContext } from "../../Show/ChecklistView"
import { Box, Typography } from "@mui/material"
import TextInput from "../../../../Shared/Inputs/TextInput"
import { useTranslation } from "react-i18next"

function TextField({ field, onChange, value, onBlur }) {
	const { finished, isAdmin, hasPermissions } = useContext(ChecklistContext)
	const { t } = useTranslation("checklistForm")

	if (finished || !hasPermissions) return (
		<Box>
			<Typography variant='subtitle1'>{value || t('noAnswer')}</Typography>
		</Box>
	)
	return (
		<Box>
			<TextInput onBlur={onBlur} value={value} onChange={onChange} label="" />
		</Box>
	)
}

export default TextField