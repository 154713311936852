import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import loginEs from '../Components/Login/Lang/loginEs.json'
import activitiesEs from '../Components/Activities/Lang/activitiesES.json'
import baseTnTEs from '../Components/Settings/BaseTnT/Lang/baseTnTEs.json'
import inputEs from '../Shared/Inputs/Lang/inputEs.json'
import calendarEs from '../Components/Calendar/Lang/calendarEs.json'
import myCalendarEs from '../Components/MyCalendar/Lang/myCalendarEs.json'
import branchesEs from '../Components/Branch/Lang/branchesEs.json'
import branchEs from '../Components/Branch/Show/Lang/branchEs.json'
import branchStructureEs from '../Components/Branch/Structure/Lang/branchStructureEs.json'
import branchesTagsEs from '../Components/Branch/Tags/Lang/branchesTagsEs.json'
import findingsEs from '../Components/Findings/Lang/findingsEs.json'
import committeeEs from '../Components/Committee/Lang/committeeEs.json'
import certificatesEs from '../Components/Certificates/Lang/certificatesEs.json'
import areasES from '../Components/Areas/Lang/areasEs.json'
import campaignsEs from '../Components/Campaigns/Lang/campaignsEs.json'
import fileExplorerEs from '../Components/FileExplorer/Lang/fileExplorerEs.json'
import enterpriseDataEs from '../Components/Enterprise/EnterpriseData/Lang/enterpriseDataEs.json'
import positionsEs from '../Components/Enterprise/Positions/Lang/positionsEs.json'
import tagsEs from '../Components/Enterprise/Tags/Lang/tagsEs.json'
import CompanyDocumentsEs from '../Components/Enterprise/CompanyDocuments/Lang/companyDocumentsEs.json'
import SubEnterprisesEs from '../Components/Enterprise/EnterpriseData/SubEnterprises/Lang/subEnterprisesEs.json'
import bukEs from '../Components/Enterprise/EnterpriseData/Buk/Lang/bukEs.json'
import bukDialogEs from '../Components/Enterprise/EnterpriseData/Buk/BukDialog/Lang/bukDialogEs.json'
import apiKeyEs from '../Components/Enterprise/EnterpriseData/ApiKey/Lang/apiKeyEs.json'
import googleDriveEs from '../Components/Enterprise/EnterpriseData/GoogleDrive/Lang/googleDriveEs.json'
import oneDriveEs from '../Components/Enterprise/EnterpriseData/OneDrive/Lang/oneDriveEs.json'
import checklistCalendarEs from '../Components/Calendar/Checklist/Lang/checklistCalendarEs.json'
import contractorsEs from '../Components/Contractors/Lang/contractorsEs.json'
import contractorsGuideEs from '../Components/Contractors/Guide/Lang/contractorsGuideEs.json'
import contractorEs from '../Components/Contractors/ContractorView/Lang/contractorEs.json'
import contractorUsersEs from '../Components/Contractors/ContractorView/Users/Lang/contractorUsersEs.json'
import permissionsEs from '../Components/Contractors/ContractorView/Permissions/Lang/permissionsEs.json'
import applicationsEs from '../Components/Contractors/ContractorView/Permissions/Applications/Lang/applicationsEs.json'
import findingCardDialogEs from '../Components/Findings/Card/Dialogs/Lang/findingCardDialogEs.json'
import findingCardEs from '../Components/Findings/Card/Lang/findingCardEs.json'
import findingCardInfoEs from '../Components/Findings/Card/CardInfo/Lang/cardInfoEs.json'
import findingEs from '../Components/Findings/Show/Lang/findingEs.json'
import findingCreationDialogEs from '../Components/Findings/CreateDialog/Lang/findingCreationDialogEs.json'
import fingerprintEs from '../Components/Fingerprint/Lang/fingerprintEs.json'
import headerEs from '../Components/Header/Lang/headerEs.json'
import syncDialogEs from '../Components/Header/SyncDialog/Lang/syncDialogEs.json'
import evaluationsEs from '../Components/Evaluations/Lang/evaluationsEs.json'
import suppliesEs from '../Components/Supplies/Lang/suppliesEs.json'
import supplyEs from '../Components/Supplies/Show/Lang/supplyEs.json'
import baseUsersEs from '../Components/Settings/BaseUsers/Lang/baseUsersEs.json'
import baseEvaluationsEs from '../Components/Settings/BaseEvaluations/Lang/baseEvaluationsEs.json'
import validationsEs from '../Components/Validations/Lang/validationsEs.json'
import routesEs from '../Routes/Lang/routesEs.json'
import srcEs from '../Lang/srcEs.json'
import legalEs from '../Legal/Lang/legalEs.json'
import tntEs from '../Components/Talks&Trainings/Lang/tntEs.json'
import showTnTEs from '../Components/Talks&Trainings/Show/Lang/showTnTEs.json'
import programsEs from '../Components/ProgramManagement/Lang/programsEs.json'
import programEs from '../Components/ProgramManagement/Show/Lang/programEs.json'
import registerTableProgramEs from '../Components/ProgramManagement/Show/RegisterTable/Lang/registerTableProgramEs.json'
import programSettingsEs from '../Components/ProgramManagement/Settings/Lang/programSettingsEs.json'
import baseFindingsEs from '../Components/Settings/Findings/Lang/baseFindingsEs.json'
import baseFindingStructureEs from '../Components/Settings/Findings/Structure/Lang/baseFindingStructureEs.json'
import baseFindingFieldsEs from '../Components/Settings/Findings/Structure/Fields/Lang/baseFindingFieldsEs.json'
import baseSuppliesEs from '../Components/Settings/BaseSupplies/Lang/baseSuppliesEs.json'
import baseSupplyFieldEs from '../Components/Settings/BaseSupplies/BaseSupplyField/Lang/baseSupplyFieldEs.json'
import inventoryEs from '../Components/Inventory/Lang/inventoryEs.json'
import eppEs from '../Components/Inventory/Epp/Lang/eppEs.json'
import authorizationsEs from '../Components/Inventory/Authorizations/Lang/authorizationsEs.json'
import deliveryEs from '../Components/Inventory/Deliveries/Lang/deliveryEs.json'
import batchesEs from '../Components/Inventory/Batches/Lang/batchesEs.json'
import baseChecklistsEs from '../Components/Settings/BaseChecklists/Lang/baseChecklistsEs.json'
import baseChecklistsItemsEs from '../Components/Settings/BaseChecklists/Show/Items/Lang/baseChecklistsItemsEs.json'
import baseChecklistsFormEs from '../Components/Settings/BaseChecklists/Show/Form/Lang/baseChecklistsFormEs.json'
import workflowsEs from '../Components/Workflows/Lang/workflowsEs.json'
import workflowStepsEs from '../Components/Workflows/Steps/Lang/workflowStepsEs.json'
import settingsWorkflowsEs from '../Components/Settings/Workflows/Lang/settingsWorkflowsEs.json'
import fieldsEs from '../Shared/Fields/Lang/fieldsEs.json'
import reportsEs from '../Shared/Reports/Lang/reportsEs.json'
import cardsEs from '../Shared/Cards/Lang/cardsEs.json'
import tableRendersEs from '../Shared/TableRenders/Lang/tableRendersEs.json'
import tourEs from '../Shared/Tour/Lang/tourEs.json'
import iconsEs from '../Shared/Icons/Lang/iconsEs.json'
import videoWatcherEs from '../Shared/Lang/videoWatcherEs.json'
import fixedTableEs from '../Shared/FixedTable/Lang/fixedTableEs.json'
import scheduleEs from '../Shared/Schedule/Lang/scheduleEs.json'
import dialogsEs from '../Shared/Dialogs/Lang/dialogsEs.json'
import generalTableEs from '../Shared/GeneralTable/Lang/generalTableEs.json'
import lazyTableEs from '../Shared/LazyTable/Lang/lazyTableEs.json'
import excelParserEs from '../Shared/ExcelParser/Lang/excelParserEs.json'
import buttonsEs from '../Shared/Buttons/Lang/buttonsEs.json'
import onlineStatusRenderedEs from '../Shared/OnlineStatusRendered/Lang/onlineStatusRenderedEs.json'
import permissionProfilesEs from '../Components/PermissionProfiles/Lang/permissionProfilesEs.json'
import loaderEs from '../Shared/Loader/Lang/loaderEs.json'
import homeEs from '../Components/Home/Lang/homeEs.json'
import supervisionHomeEs from '../Components/Home/Supervision/Lang/supervisionHomeEs.json'
import checklistManagementEs from '../Components/Home/ChecklistManagement/Lang/checklistManagementEs.json'
import pendingsEs from '../Components/Home/Pendings/Lang/pendingsEs.json'
import baseChecklistsAuthorizationsEs from '../Components/Settings/BaseChecklists/Show/Authorizations/Lang/baseChecklistsAuthorizationsEs.json'
import baseChecklistsSettingsEs from '../Components/Settings/BaseChecklists/Show/Settings/Lang/baseChecklistsSettingsEs.json'
import profileTabsEs from '../Components/Workers/Profile/ProfileTabs/Lang/profileTabsEs.json'
import userProfileEs from '../Components/Workers/Profile/Lang/userProfileEs.json'
import userBaseActivitiesEs from '../Components/Workers/Profile/ProfileTabs/BaseActivities/Lang/userBaseActivitiesEs.json'
import fingerprintStepsEs from '../Components/Workers/Profile/FingerprintSteps/Lang/fingerprintStepsEs.json'
import profileBukEs from '../Components/Workers/Buk/Lang/profileBukEs.json'
import profilePermissionsEs from '../Components/Workers/Profile/Permissions/Lang/profilePermissionsEs.json'
import profileBranchesEs from '../Components/Workers/Profile/Branches/Lang/profileBranchesEs.json'
import indexWorkersEs from '../Components/Workers/IndexWorkers/Lang/indexWorkersEs.json'
import createUserDialogEs from '../Shared/Dialogs/CreateUser/Lang/createUserDialogEs.json'
import workerImportEs from '../Components/Workers/WorkerImport/Lang/workerImportEs.json'
import workerReportsEs from '../Components/Workers/WorkerReports/Lang/workerReportsEs.json'
import mandatoryDocumentationEs from '../Components/Workers/WorkerReports/MandatoryDocumentation/Lang/mandatoryDocumentationEs.json'
import checklistIndexEs from '../Components/Checklist/Index/Lang/checklistIndexEs.json'
import checklistPublicEs from '../Components/Checklist/Public/Lang/checklistPublicEs.json'
import checklistSharedEs from '../Components/Checklist/Shared/Lang/checklistSharedEs.json'
import checklistShowEs from '../Components/Checklist/Show/Lang/checklistShowEs.json'
import checklistContentEs from '../Components/Checklist/Content/Lang/checklistContentEs.json'
import checklistSignsEs from '../Components/Checklist/Content/Signs/Lang/checklistSignsEs.json'
import checklistItemEs from '../Components/Checklist/Content/Item/Lang/checklistItemEs.json'
import checklistFormEs from '../Components/Checklist/Content/Form/Lang/checklistFormEs.json'
import ipaReportEs from '../Components/Enterprise/EnterpriseData/Criticalities/Lang/ipaReportEs.json'
import permissionsMenuEs from '../Shared/PermissionsMenu/Lang/permissionsMenuEs.json'
import checklistRejectionEs from '../Components/Checklist/Show/RejectionField/Lang/checklistRejectionEs.json'
// por default en español
i18n
	.use(LanguageDetector) // detect user language
	.use(initReactI18next)
	.init({
		fallbackLng: {
			'es-CL': ['es'],
			'es-ARG': ['es'],
			'es-MX': ['es'],
			default: ['es'],
		},
		debug: true,
		resources: {
			es: {
				activities: activitiesEs,
				areas: areasES,
				baseTnT: baseTnTEs,
				input: inputEs,
				calendar: calendarEs,
				myCalendar: myCalendarEs,
				certificates: certificatesEs,
				login: loginEs,
				campaigns: campaignsEs,
				evaluations: evaluationsEs,
				enterpriseData: enterpriseDataEs,
				positions: positionsEs,
				tags: tagsEs,
				companyDocuments: CompanyDocumentsEs,
				subEnterprises: SubEnterprisesEs,
				buk: bukEs,
				bukDialog: bukDialogEs,
				apiKey: apiKeyEs,
				googleDrive: googleDriveEs,
				oneDrive: oneDriveEs,
				checklistCalendar: checklistCalendarEs,
				contractorsGuide: contractorsGuideEs,
				contractor: contractorEs,
				contractorUsers: contractorUsersEs,
				contractors: contractorsEs,
				permissions: permissionsEs,
				applications: applicationsEs,
				branches: branchesEs,
				branch: branchEs,
				branchStructure: branchStructureEs,
				branchesTags: branchesTagsEs,
				findings: findingsEs,
				committee: committeeEs,
				fileExplorer: fileExplorerEs,
				findingCardDialog: findingCardDialogEs,
				findingCard: findingCardEs,
				findingCardInfo: findingCardInfoEs,
				finding: findingEs,
				findingCreationDialog: findingCreationDialogEs,
				fingerprint: fingerprintEs,
				header: headerEs,
				syncDialog: syncDialogEs,
				supplies: suppliesEs,
				supply: supplyEs,
				baseUsers: baseUsersEs,
				baseEvaluations: baseEvaluationsEs,
				validations: validationsEs,
				routes: routesEs,
				src: srcEs,
				legal: legalEs,
				home: homeEs,
				supervisionHome: supervisionHomeEs,
				checklistManagement: checklistManagementEs,
				pendings: pendingsEs,
				tnt: tntEs,
				showTnT: showTnTEs,
				programs: programsEs,
				program: programEs,
				registerTableProgram: registerTableProgramEs,
				programSettings: programSettingsEs,
				baseFindings: baseFindingsEs,
				baseFindingStructure: baseFindingStructureEs,
				baseFindingFields: baseFindingFieldsEs,
				baseSupplies: baseSuppliesEs,
				baseSupplyField: baseSupplyFieldEs,
				inventory: inventoryEs,
				epp: eppEs,
				authorizations: authorizationsEs,
				delivery: deliveryEs,
				batches: batchesEs,
				baseChecklists: baseChecklistsEs,
				baseChecklistsItems: baseChecklistsItemsEs,
				baseChecklistsForm: baseChecklistsFormEs,
				workflows: workflowsEs,
				workflowSteps: workflowStepsEs,
				settingsWorkflow: settingsWorkflowsEs,
				fields: fieldsEs,
				reports: reportsEs,
				cards: cardsEs,
				tableRenders: tableRendersEs,
				tour: tourEs,
				icons: iconsEs,
				videoWatcher: videoWatcherEs,
				fixedTable: fixedTableEs,
				schedule: scheduleEs,
				dialogs: dialogsEs,
				generalTable: generalTableEs,
				lazyTable: lazyTableEs,
				excelParser: excelParserEs,
				buttons: buttonsEs,
				onlineStatusRendered: onlineStatusRenderedEs,
				permissionProfiles: permissionProfilesEs,
				loader: loaderEs,
				baseChecklistsAuthorizations: baseChecklistsAuthorizationsEs,
				baseChecklistsSettings: baseChecklistsSettingsEs,
				profileTabs: profileTabsEs,
				userProfile: userProfileEs,
				userBaseActivities: userBaseActivitiesEs,
				fingerprintSteps: fingerprintStepsEs,
				profileBuk: profileBukEs,
				profilePermissions: profilePermissionsEs,
				profileBranches: profileBranchesEs,
				indexWorkers: indexWorkersEs,
				createUserDialog: createUserDialogEs,
				workerImport: workerImportEs,
				workerReports: workerReportsEs,
				mandatoryDocumentation: mandatoryDocumentationEs,
				checklistIndex: checklistIndexEs,
				checklistPublic: checklistPublicEs,
				checklistShared: checklistSharedEs,
				checklistShow: checklistShowEs,
				checklistContent: checklistContentEs,
				checklistSigns: checklistSignsEs,
				checklistItem: checklistItemEs,
				checklistForm: checklistFormEs,
				ipaReport: ipaReportEs,
				permissionsMenu: permissionsMenuEs,
				checklistRejection: checklistRejectionEs,
			},
		},
		interpolation: {
			escapeValue: false, // not needed for react as it escapes
		},

	})


export default i18n