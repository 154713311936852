import { Box } from '@mui/material'
import { createContext, useEffect, useState } from 'react'

export const SuppliesManagerContext = createContext({})

function SuppliesManager({ children, db }) {

	const [supplies, setSuppliesArray] = useState([])

	useEffect(() => {
		getSupplies()
	}, [])

	function addSupply(request) {
		const db_request = db.transaction(["supplies"], "readwrite")
			.objectStore("supplies")
			.put(request)

		db_request.onsuccess = function (event) {
			getSupplies()
			console.log("Supply has been added to the store")
		}

		db_request.onerror = function (event) {
			console.log("Error adding request to the store")
		}
	}

	function addSupplies(supplies) {
		const transaction = db.transaction(["supplies"], "readwrite")
		const store = transaction.objectStore("supplies")

		for (const request of supplies) {
			store.put(request)
		}

		transaction.oncomplete = function (event) {
			console.log("Supplies has been added to the store")
			setSuppliesArray(r => [...r, ...supplies])
		}

		transaction.onerror = function (event) {
			console.log("Error adding supplies to the store")
		}
	}

	function getSupply(id) {
		if (!db) return null
		if (typeof id === "string") id = parseInt(id)
		const db_request = db.transaction(["supplies"]).objectStore("supplies").get(id)

		return new Promise((resolve, reject) => {
			db_request.onsuccess = function (event) {
				resolve(db_request.result)
			}

			db_request.onerror = function (event) {
				console.log("Error getting request from the store")
				reject(db_request.error)
			}
		})
	}

	function setSupplies(supplies) {
		const transaction = db.transaction(["supplies"], "readwrite")
		const store = transaction.objectStore("supplies")
		store.clear()

		for (const request of supplies) {
			store.put(request)
		}

		transaction.oncomplete = function (event) {
			setSuppliesArray(supplies)
			console.log("Supplies has been added to the store")
		}

		transaction.onerror = function (event) {
			console.log("Error adding supplies to the store")
		}
	}

	function updateSupply(supply) {
		if (!db) return null
		const db_request = db.transaction(["supplies"], "readwrite").objectStore("supplies").put(supply)

		return new Promise((resolve, reject) => {
			db_request.onsuccess = function (event) {
				resolve(db_request.result)
				getSupplies()
			}

			db_request.onerror = function (event) {
				console.log("Error getting request from the store")
				reject(db_request.error)
			}
		})

	}

	async function addOrUpdateSupplies(supplies) {
		if (!db) return false
		for (let supply of supplies) {
			const exists = await getSupply(supply.id)
			if (!!exists) {
				updateSupply(supply)
			} else {
				addSupply(supply)
			}
		}
	}

	function getSupplies() {
		if (!db) return null
		const objectStore = db.transaction("supplies").objectStore("supplies")
		const supplies_response = []

		return new Promise((resolve, reject) => {
			objectStore.openCursor().onsuccess = function (event) {
				const cursor = event.target.result
				if (cursor) {
					supplies_response.push(cursor.value)
					cursor.continue()
				} else {
					setSuppliesArray(supplies_response)
					resolve(supplies_response)
				}
			}

			objectStore.openCursor().onerror = function (event) {
				console.log("Error getting supplies from the store")
				reject(event.target.error)
			}
		})
	}

	function getSuppliesPlain() {
		if (!db) return Promise.resolve([]); // Return an empty array if the db is not available

		const objectStore = db.transaction("supplies").objectStore("supplies");
		const supplies_response = [];

		return new Promise((resolve, reject) => {
			const cursorSupply = objectStore.openCursor();

			cursorSupply.onsuccess = function (event) {
				const cursor = event.target.result;
				if (cursor) {
					supplies_response.push(cursor.value);
					cursor.continue();
				} else {
					resolve(supplies_response);
				}
			};

			cursorSupply.onerror = function (event) {
				console.log("Error getting supplies from the store");
				reject(event.target.error);
			};
		});
	}

	function deleteSupply(id) {
		if (!db) return null
		const db_request = db.transaction(["supplies"], "readwrite").objectStore("supplies").delete(id)

		return new Promise((resolve, reject) => {
			db_request.onsuccess = function (event) {
				resolve(db_request.result)
				getSupplies()
			}

			db_request.onerror = function (event) {
				console.log("Error getting request from the store")
				reject(db_request.error)
			}
		})
	}

	return (
		<SuppliesManagerContext.Provider value={{ addOrUpdateSupplies, addSupply, addSupplies, getSupply, getSupplies, setSupplies, deleteSupply, supplies, getSuppliesPlain }}>
			{children}
		</SuppliesManagerContext.Provider>
	)
}

export default SuppliesManager