import { Box, Button, Typography } from '@mui/material'
import { inline_space, labeless, title_style } from '../../../../Utils/defaultStyles'
import UsersInput from '../../../../Shared/Inputs/UsersInput'
import { FindingDialogContext } from '../FindingDialog'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
function ResponsibleStep() {

	const { params, setParams, nextStep, prevStep, is_responsible_required, responsibles } = useContext(FindingDialogContext)
	const { t } = useTranslation("findingCreationDialog")
	function onChange(event) {
		const { target } = event
		const { name, value } = target
		setParams(p => ({ ...p, [name]: value }))
	}

	const disable_next = is_responsible_required && !params.user_id
	return (
		<Box>
			<Typography variant='h4' color="info.main">{t('titles.responsibleSelection')}</Typography>
			<Typography variant='subtitle1' sx={title_style} > {t('questions.responsibleQuestion')}</Typography>
			<Typography variant='subtitle2' sx={labeless}>{t('indications.selectResponsible')}</Typography>
			<UsersInput limit={responsibles} name="user_id" value={params.user_id} onChange={onChange} branch_id={params?.branch_id} />
			{!!params.user_id &&
				<Box>
					<Typography variant='subtitle2' sx={labeless}> {t('questions.notificationQuestion')}</Typography>
					<UsersInput name="notified_users" value={params.notified_users} onChange={onChange} isMultiple branch_id={params?.branch_id} />
				</Box>
			}
			<Box sx={inline_space}>
				<Button variant="outlined" onClick={prevStep}>
					{t('stepBackwards')}
				</Button>
				{/* TODO disable button if no user selected and is required */}
				<Button variant="contained" color="success" onClick={nextStep} disabled={disable_next}>
					{t('actions.continue')} {params.user_id ? "" : t('noResponsible')}
				</Button>
			</Box>
		</Box >
	)
}

export default ResponsibleStep