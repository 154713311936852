import { Box } from '@mui/material'
import moment from 'moment'
import { createContext } from 'react'
import { getFinding as getFindingFromBackend } from '../API/findings'

export const FindingsManagerContext = createContext({})

function FindingsManager({ children, db }) {

	function addFinding(finding) {
		if (!db) return null
		const request = db.transaction(["findings"], "readwrite")
			.objectStore("findings")
			.put(finding)

		request.onsuccess = function (event) {
			console.log(`Finding ${finding.id} has been added to the store`)
		}

		request.onerror = function (event) {
			console.log("Error adding finding to the store")
		}
	}

	function addFindings(findings) {
		if (!db) return null
		const transaction = db.transaction(["findings"], "readwrite")
		const store = transaction.objectStore("findings")

		for (const finding of findings) {
			store.put(finding)
		}

		transaction.oncomplete = function (event) {
			console.log("Findings has been added to the store")
		}

		transaction.onerror = function (event) {
			console.log("Error adding findings to the store")
		}
	}

	function getFinding(id) {
		if (!db) return false
		const request = db.transaction(["findings"]).objectStore("findings").get(id)

		return new Promise((resolve, reject) => {
			request.onsuccess = function (event) {
				const finding = request.result
				if (finding) {
					resolve(finding)
				} else {
					resolve(null) // Resolve with null when finding is not found
				}
			}

			request.onerror = function (event) {
				console.log("Error getting finding from the store")
				reject(request.error)
			}
		})
	}

	async function checkIfFindingExists(finding_id) {
		const exists = await getFinding(finding_id)
		return !!exists
	}

	async function fetchFindings(finding_ids) {
		if (!db) return false
		for (let id of finding_ids) {
			const exists = await getFinding(id)
			if (!!exists) continue
			const response = await getFindingFromBackend({ id })
			const new_finding = response.data.info
			addFinding(new_finding)
		}
		await cleanFindings()
	}

	async function cleanFindings() {
		const findings = await getFindings()
		const to_delete = findings.filter(finding => moment(finding.date_scheduled).isBefore(moment().subtract(7, "days")))
		to_delete.map(c => c.id).map(deleteFinding)
	}

	async function deleteFinding(id) {
		if (!db) return null
		const transaction = db.transaction(["findings"], "readwrite")
		const objectStore = transaction.objectStore("findings")

		objectStore.delete(id)

		return new Promise((resolve, reject) => {
			transaction.oncomplete = function (event) {
				console.log("Finding has been removed from the store")
				resolve()
			}

			transaction.onerror = function (event) {
				console.log("Error removing finding from the store")
				reject(event.target.error)
			}
		})
	}

	async function removeAllFindings() {
		if (!db) return null
		const transaction = db.transaction(["findings"], "readwrite")
		const objectStore = transaction.objectStore("findings")

		objectStore.clear()

		return new Promise((resolve, reject) => {
			transaction.oncomplete = function (event) {
				console.log("All findings have been removed from the store")
				resolve()
			}

			transaction.onerror = function (event) {
				console.log("Error removing all findings from the store")
				reject(event.target.error)
			}
		})
	}

	async function keepOnlyFindings(finding_ids) {
		if (!db) return null
		const transaction = db.transaction(["findings"], "readwrite")
		const objectStore = transaction.objectStore("findings")

		const getAllRequest = objectStore.getAll()

		getAllRequest.onsuccess = function (event) {
			const allFindings = event.target.result
			const findingIDsToDelete = allFindings
				.filter((finding) => !finding_ids.includes(finding.id))
				.map((finding) => finding.id)

			for (const id of findingIDsToDelete) {
				objectStore.delete(id)
			}
		}

		return new Promise((resolve, reject) => {
			transaction.oncomplete = function (event) {
				console.log("Findings have been filtered and removed from the store")
				resolve()
			}

			transaction.onerror = function (event) {
				console.log("Error removing findings from the store")
				reject(event.target.error)
			}
		})
	}

	function setFindings(findings) {
		if (!db) return null
		const transaction = db.transaction(["findings"], "readwrite")
		const store = transaction.objectStore("findings")
		store.clear()

		for (const finding of findings) {
			store.put(finding)
		}

		transaction.oncomplete = function (event) {
			console.log("Findings has been added to the store")
		}

		transaction.onerror = function (event) {
			console.log("Error adding findings to the store")
		}
	}

	function getFindings() {
		if (!db) return null
		const objectStore = db.transaction("findings").objectStore("findings")
		const findings = []

		return new Promise((resolve, reject) => {
			objectStore.openCursor().onsuccess = function (event) {
				const cursor = event.target.result
				if (cursor) {
					findings.push(cursor.value)
					cursor.continue()
				} else {
					resolve(findings)
				}
			}

			objectStore.openCursor().onerror = function (event) {
				console.log("Error getting findings from the store")
				reject(event.target.error)
			}
		})
	}

	return (
		<FindingsManagerContext.Provider value={{
			addFinding,
			addFindings,
			getFinding,
			getFindings,
			setFindings,
			fetchFindings,
			removeAllFindings,
			checkIfFindingExists,
			keepOnlyFindings
		}}>
			{children}
		</FindingsManagerContext.Provider>
	)
}

export default FindingsManager