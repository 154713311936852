import { Box, Typography } from "@mui/material"
import RutInput from "../../../../Shared/Inputs/RutInput"
import { useContext } from "react"
import { ChecklistContext } from "../../Show/ChecklistView"

const css = {

}

function RutField({ field, onChange, value, onBlur }) {
	const { finished, hasPermissions, isAdmin } = useContext(ChecklistContext)
	if (finished || !hasPermissions) return (
		<Box sx={css.input}>
			<Typography variant='subtitle1'>{value}</Typography>
		</Box>
	)
	return (
		<Box sx={css.input}>
			<RutInput onBlur={onBlur} value={value} onChange={onChange} label="" />
		</Box>
	)
}

export default RutField