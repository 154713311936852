import { Box } from '@mui/material'
import moment from 'moment'
import { createContext } from 'react'
import { getChecklist } from '../API/checklists'

export const ChecklistManagerContext = createContext({})

function ChecklistManager({ children, db }) {

	function addChecklist(checklist) {
		if (!db) return null
		const request = db.transaction(["checklists"], "readwrite")
			.objectStore("checklists")
			.put(checklist)

		request.onsuccess = function (event) {
			console.log("Checklist has been added to the store")
		}

		request.onerror = function (event) {
			console.log("Error adding checklist to the store")
		}
	}

	function addChecklists(checklists) {
		if (!db) return null
		const transaction = db.transaction(["checklists"], "readwrite")
		const store = transaction.objectStore("checklists")

		for (const checklist of checklists) {
			store.put(checklist)
		}

		transaction.oncomplete = function (event) {
			console.log("Checklists has been added to the store")
		}

		transaction.onerror = function (event) {
			console.log("Error adding checklists to the store")
		}
	}

	function getLocalChecklist(id) {
		if (!db) return false
		if (!id) return null
		if (typeof id === "string" && !id.includes("-")) { id = parseInt(id) }
		const request = db.transaction(["checklists"]).objectStore("checklists").get(id)

		return new Promise((resolve, reject) => {
			request.onsuccess = function (event) {
				const checklist = request.result
				if (checklist) {
					resolve(checklist)
				} else {
					resolve(null) // Resolve with null when checklist is not found
				}
			}

			request.onerror = function (event) {
				console.log("Error getting checklist from the store")
				reject(request.error)
			}
		})
	}

	async function checkIfChecklistExists(checklist_id) {
		const exists = await getLocalChecklist(checklist_id)
		return !!exists
	}

	async function fetchChecklists(checklist_ids) {
		if (!db) return false
		for (let id of checklist_ids) {
			const exists = await getLocalChecklist(id)
			if (!!exists) continue
			const response = await getChecklist({ id })
			const new_checklist = response.data.info
			addChecklist(new_checklist)
		}
		await cleanChecklists()
	}

	async function cleanChecklists() {
		const checklists = await getChecklists()
		const to_delete = checklists.filter(checklist => moment(checklist.date_scheduled).isBefore(moment().subtract(7, "days")))
		to_delete.map(c => c.id).map(deleteChecklist)
	}

	async function deleteChecklist(id) {
		if (!db) return null
		const transaction = db.transaction(["checklists"], "readwrite")
		const objectStore = transaction.objectStore("checklists")

		objectStore.delete(id)

		return new Promise((resolve, reject) => {
			transaction.oncomplete = function (event) {
				console.log("Checklist has been removed from the store")
				resolve()
			}

			transaction.onerror = function (event) {
				console.log("Error removing checklist from the store")
				reject(event.target.error)
			}
		})
	}

	async function removeAllChecklists() {
		if (!db) return null
		const transaction = db.transaction(["checklists"], "readwrite")
		const objectStore = transaction.objectStore("checklists")

		objectStore.clear()

		return new Promise((resolve, reject) => {
			transaction.oncomplete = function (event) {
				console.log("Checklists has been removed from the store")
				resolve()
			}

			transaction.onerror = function (event) {
				console.log("Error removing checklists from the store")
				reject(event.target.error)
			}
		})
	}

	async function keepOnlyChecklists(checklist_ids) {
		if (!db) return null
		const transaction = db.transaction(["checklists"], "readwrite")
		const objectStore = transaction.objectStore("checklists")

		const getAllRequest = objectStore.getAll()

		getAllRequest.onsuccess = function (event) {
			const allChecklists = event.target.result
			const checklistIDsToDelete = allChecklists
				.filter((checklist) => !checklist_ids.includes(checklist.id))
				.map((checklist) => checklist.id)

			for (const id of checklistIDsToDelete) {
				objectStore.delete(id)
			}
		}

		return new Promise((resolve, reject) => {
			transaction.oncomplete = function (event) {
				console.log("Checklists have been filtered and removed from the store")
				resolve()
			}

			transaction.onerror = function (event) {
				console.log("Error removing checklists from the store")
				reject(event.target.error)
			}
		})
	}

	function setChecklists(checklists) {
		if (!db) return null
		const transaction = db.transaction(["checklists"], "readwrite")
		const store = transaction.objectStore("checklists")
		store.clear()

		for (const checklist of checklists) {
			store.put(checklist)
		}

		transaction.oncomplete = function (event) {
			console.log("Checklists has been added to the store")
		}

		transaction.onerror = function (event) {
			console.log("Error adding checklists to the store")
		}
	}

	function getChecklists() {
		if (!db) return null
		const objectStore = db.transaction("checklists").objectStore("checklists")
		const checklists = []

		return new Promise((resolve, reject) => {
			objectStore.openCursor().onsuccess = function (event) {
				const cursor = event.target.result
				if (cursor) {
					checklists.push(cursor.value)
					cursor.continue()
				} else {
					resolve(checklists)
				}
			}

			objectStore.openCursor().onerror = function (event) {
				console.log("Error getting checklists from the store")
				reject(event.target.error)
			}
		})
	}

	return (
		<ChecklistManagerContext.Provider value={{
			addChecklist,
			addChecklists,
			getChecklist: getLocalChecklist,
			getChecklists,
			setChecklists,
			fetchChecklists,
			removeAllChecklists,
			checkIfChecklistExists,
			keepOnlyChecklists
		}}>
			{children}
		</ChecklistManagerContext.Provider>
	)
}

export default ChecklistManager