import instance2 from "../Utils/instance2"

export const getSettingsSupplies = () => {
	return instance2({
		method: 'get',
		url: 'supply_families'
	})
}

export function getSupplies(body) {
	return instance2.get('supplies', { params: body })
}

export function syncSupplies(body) {
	return instance2.get('supplies/sync', { params: body })
}

export function suppliesQrs(body) {
	return instance2.get('supplies/qrs', { params: body })
}

export const createSupply = body => {
	return instance2({
		method: 'post',
		url: 'supplies',
		data: body
	})
}

export const editSupply = body => {
	return instance2({
		method: 'put',
		url: 'supplies',
		data: body
	})
}

export const deleteSupply = body => {
	return instance2({
		method: 'delete',
		url: 'supplies',
		data: body
	})
}

export const createFamily = body => {
	return instance2({
		method: 'post',
		url: 'settings/supplies/create_family',
		data: body
	})
}

export const editFamily = body => {
	return instance2({
		method: 'post',
		url: 'settings/supplies/edit_family',
		data: body
	})
}
export const moveSupply = body => {
	return instance2({
		method: 'post',
		url: 'supplies/move',
		data: body
	})
}

export const supplyReport = body => {
	return instance2({
		method: 'post',
		url: 'supplies/report',
		data: body
	})
}

export function getSuppliesReport(body) {
	return instance2.get('reports/supplies', { params: body })
}

export function getSupplyChecklistsReport(body) {
	return instance2.get('reports/supply_checklists', { params: body })
}

export function getSupplyConsolidatedReport(body) {
	return instance2.get('reports/supply_consolidated', { params: body })
}