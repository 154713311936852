import { BookmarkBorder } from '@material-ui/icons'
import { Box, IconButton, Menu, Typography } from '@mui/material'
import { Fragment, useRef } from 'react'
import useToggle from '../../../Hooks/ToogleHook'
import { useTranslation } from 'react-i18next'
import BigTooltip from '../../../Shared/Tooltip/BigTooltip'

const css = {
	tag: {
		padding: '6px 12px',
		borderRadius: 2,
		minWidth: 150,
		margin: '6px 0'
	},
	menu: {
		padding: '0px 12px'
	}
}

function CardTags({ tags }) {

	const [openMenu, toggleMenu] = useToggle(false)
	const menu = useRef(null)
	const { t } = useTranslation("findingCard")
	if (!tags.length) return null

	return (
		<Fragment >
			<IconButton ref={menu} size="small" onClick={toggleMenu}>
				<BigTooltip title={t('titles.tags')}>
					<BookmarkBorder />
				</BigTooltip>
			</IconButton>
			<Menu open={openMenu} anchorEl={menu.current} onClose={toggleMenu}>
				<Box sx={css.menu}>
					{tags.map(tag =>
						<Box sx={css.tag} style={{ background: tag.color }} key={tag.name}>
							<Typography style={{ color: 'white' }} variant='subtitle1'>{tag.name}</Typography>
						</Box>
					)}
				</Box>
			</Menu>
		</Fragment>
	)
}

export default CardTags