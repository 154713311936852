import { Box, Typography } from "@mui/material"
import RutInput from "../../../../Shared/Inputs/RutInput"
import { useContext } from "react"
import { ChecklistContext } from "../../Show/ChecklistView"
import RTE from "../../../../Shared/Inputs/RTE"

const css = {

}

function RTEField({ field, onChange, value, onBlur }) {

	const { finished, isAdmin, hasPermissions } = useContext(ChecklistContext)

	return (
		<Box style={{ maxWidth: '100%' }}>
			<RTE disabled={finished || !hasPermissions} onBlur={onBlur} value={value} onChange={onChange} label="" />
		</Box>

	)
}

export default RTEField