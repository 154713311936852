import instance2 from "../Utils/instance2"

export const getSubdivisions = (body) => {
  return instance2({
    method: 'get',
    url: "subdivisions",
    params: body
  })
}

export function showSubdivisions(body) {
  return instance2.get("/subdivisions/show", { params: body })
}

export const createSubdivision = body => {
  return instance2({
    method: 'post',
    url: 'subdivisions',
    data: body
  })
}

export const editSubdivision = body => {
  return instance2({
    method: 'put',
    url: 'subdivisions',
    data: body
  })
}

export const deleteSubdivision = body => {
  return instance2({
    method: 'delete',
    url: 'subdivisions',
    data: body
  })
}

export const cloneSubdivisions = body => {
  return instance2({
    method: 'post',
    url: 'subdivisions/clone',
    data: body
  })
}

export function createRequirement(body) {
  return instance2.post("/subdivisions/requirement/new", body)
}

export function checkRequirement(body) {
  return instance2.put("/subdivisions/requirement", body)
}

export const getParents = body => {
  return instance2({
    method: 'get',
    url: 'subdivisions/parents',
    params: body
  })
}

export const deleteRequirement = body => {
  return instance2({
    method: 'delete',
    url: 'subdivisions/requirement',
    data: body
  })
}

export const getChildren = body => {
  return instance2({
    method: 'get',
    url: 'subdivisions/children',
    params: body
  })
}

export function searchSubdivision(body) {
  return instance2.get("/subdivisions/search", { params: body })
}

export function showSubdivision(body) {
  return instance2.get("/subdivision", { params: body })
}