import React from 'react'
import { Box } from '@mui/material'
import "./animations.css"

function MiniLoaderAnimator() {

  return (
    <Box className="mini-lds-container">
      <Box className="mini-lds-ring">
        <Box></Box>
        <Box></Box>
        <Box></Box>
        <Box></Box>
      </Box>
    </Box>
  )
}

export default MiniLoaderAnimator