import instance2 from "../Utils/instance2"

export function getPositions(body) {
	return instance2.get('positions', { params: body })
}

export function editUserPosition(body) {
	return instance2.put('positions/transfer', body)
}

export function createPosition(body) {
	return instance2.post('positions', body)
}

export function editPosition(body) {
	return instance2.put(`positions/${body.id}`, body)
}

export function deletePosition(body) {
	return instance2.delete(`positions/${body.id}`)
}