import { Box, Dialog, Typography, useMediaQuery } from '@mui/material'
import { dialog_style, title_style } from '../../../Utils/defaultStyles'
import { createContext, useContext, useEffect, useState } from 'react'
import { getBaseFindingActions, getBaseFindingMeasures, getBaseFindingTimings, getFindingBaseForm, getFindingType } from '../../../API/findings'
import TypeStep from './Steps/TypeStep'
import DescriptionStep from './Steps/DescriptionStep'
import EvidenceStep from './Steps/EvidenceStep'
import MeasureStep from './Steps/MeasureStep'
import CriticalityStep from './Steps/CriticalityStep'
import ResponsibleStep from './Steps/ResponsibleStep'
import DateLimitStep from './Steps/DateLimitStep'
import { getLocalCrits, calculateBusinessHoursDeadline, getBusinessHours } from '../../../Utils/functions'
import FinalStep from './Steps/FinalStep'
import moment from 'moment'
import { CurrentUserContext } from '../../../CurrentUser'
import FormStep from './Steps/FormStep'
import AppContext from '../../../AppContext'
import { BaseFindingManagerContext } from '../../../DatabaseManagers/BaseFindingManager'
import RepetitionWarning from './Steps/RepetitionWarning'
import { useTranslation } from 'react-i18next'

function initializeFinding({ open, item, checklist, currentUser, branch_id, workflow_phase_id, audit_item_id, crits, businessHoursOnly, free_criticality }) {
	const initial_finding = {
		counter: 1,
		checklist_item_id: item?.id,
		checklist_id: checklist?.id,
		free_criticality,
		branch_id: checklist?.branch_id || branch_id,
		owner_id: currentUser?.id,
		date_detected: moment().format('YYYY-MM-DD HH:mm'),
		user_creator: currentUser?.name,
		workflow_phase_id,
		audit_item_id,
	}
	if (!open) return {} // para que no corra si no está abierto
	if (initial_finding.free_criticality >= 0) {
		const crit = crits.find(c => c.value === initial_finding.free_criticality)
		if (businessHoursOnly) {
			initial_finding.date_limit = calculateBusinessHoursDeadline(moment(), crit?.hours_offset)
		} else {
			initial_finding.date_limit = moment().add(crit?.hours_offset, 'hours').format('YYYY-MM-DD HH:mm');
		}
	}
	return initial_finding
}


export const FindingDialogContext = createContext()

function transformBaseToOptions(options) {
	return options.map(op => ({ ...op, label: op.content, value: op.id }))
}

function FindingDialog({ open, onClose, onCancel, item, checklist, onCreate, branch_id, workflow_phase_id = null, enterprise = {}, audit_item_id = null }) {

	const businessHoursOnly = getBusinessHours()
	const { t } = useTranslation("findingCreationDialog")
	const { currentUser } = useContext(CurrentUserContext)
	const { online: { status: online } } = useContext(AppContext)
	const {
		getFindingTypes,
		getBaseFindingActions: getBaseFindingActionsOffline,
		getBaseFindingMeasures: getBaseFindingMeasuresOffline,
		getBaseFindingTimings: getBaseFindingTimingsOffline,
		getBaseFindingForms: getBaseFindingFormsOffline,
	} = useContext(BaseFindingManagerContext) // For working offline

	const crits = getLocalCrits()

	let free_criticality = undefined
	if (!!item || !!checklist) {
		free_criticality = item?.default_criticality ? parseInt(item?.default_criticality, 10) : 0
	}


	// Check if item has a default criticality


	const is_evidence_required = !!checklist?.require_finding_evidence || !!enterprise?.require_evidence
	const is_responsible_required = !!checklist?.require_finding_responsible || !!enterprise?.require_responsible
	const is_criticality_required = !!checklist?.require_finding_criticality || !!enterprise?.require_criticality
	const responsibles = enterprise?.responsibles || []


	const [finding, setFinding] = useState({})
	const [finding_types, setFindingTypes] = useState([])
	const [finding_actions, setFindingActions] = useState([])
	const [finding_measures, setFindingMeasures] = useState([])
	const [finding_timings, setFindingTimings] = useState([])
	const [finding_form, setFindingForm] = useState([])
	const [params, setParams] = useState({})
	const [step, setStep] = useState(0)
	const isMobile = useMediaQuery('(max-width: 600px)')

	useEffect(() => {
		if (params.date_limit === undefined) {
			setParams(initializeFinding({
				item,
				checklist,
				currentUser,
				branch_id,
				workflow_phase_id,
				audit_item_id,
				crits,
				businessHoursOnly,
				free_criticality,
				open
			}))
		}
	}, [open])

	useEffect(() => {
		async function fetchDataTypes() {
			const response = await getFindingType()
			setFindingTypes(response.data.info)

			const newEvent = new CustomEvent("reload_base_findings")
			window.dispatchEvent(newEvent)
		}

		async function fetchOfflineDataTypes() {
			const response = await getFindingTypes()
			setFindingTypes(response)
		}
		if (!item && open) {
			if (online) fetchDataTypes()
			else fetchOfflineDataTypes()
		}
	}, [online, open])

	useEffect(() => {
		async function fetchDatas() {
			const body = { type: params.finding_type_id }
			const responses = await Promise.all([
				getBaseFindingActions(body),
				getBaseFindingMeasures(body),
				getBaseFindingTimings(body),
				getFindingBaseForm(body),

			])
			const datas = responses.map(res => res.data.info)
			setFindingActions(datas[0])
			setFindingMeasures(datas[1])
			setFindingTimings(datas[2])
			setFindingForm(datas[3])
			// If there is a form related
			if (datas[3].length) {
				setStep(1)
			} else {
				setStep(2)
			}
		}

		async function fetchOfflineDatas() {
			const finding_type_id = params.finding_type_id
			const responses = await Promise.all([
				getBaseFindingActionsOffline(finding_type_id),
				getBaseFindingMeasuresOffline(finding_type_id),
				getBaseFindingTimingsOffline(finding_type_id),
				getBaseFindingFormsOffline(finding_type_id)
			])
			const datas = responses.map(res => res)
			setFindingActions(datas[0])
			setFindingMeasures(datas[1])
			setFindingTimings(datas[2])
			setFindingForm(datas[3])
			// If there is a form related
			if (datas[3].length) {
				setStep(1)
			} else {
				setStep(2)
			}
		}

		if (!!params.finding_type_id) {
			if (online) fetchDatas()
			else fetchOfflineDatas()
		}


	}, [params.finding_type_id, online])

	function onReset() {
		setStep(0)
		setParams(initializeFinding({
			item,
			checklist,
			currentUser,
			branch_id,
			workflow_phase_id,
			audit_item_id,
			crits,
			businessHoursOnly,
			free_criticality,
			open
		}))
	}

	function handleStepChange(direction, value) {
		if (typeof value === 'number') return setStep(value)

		const delta = direction === 'next' ? 1 : -1
		const next_step = step + delta
		if (next_step === 5) {
			// If criticality has been automatically selected
			const is_step_needed = params.free_criticality === 0 && is_criticality_required
			if (params.free_criticality !== undefined && !is_step_needed) return setStep(next_step + delta)
		}

		if (next_step === 7) {
			if (!!params.date_limit) return setStep(next_step + delta)
			if (!params.user_id) return setStep(next_step + delta)
		}


		if (next_step === 1) { // If it is the form step
			// If it doesn't have form
			if (!finding_form.length) return setStep(next_step + delta)
		}
		setStep(s => s + delta)
	}

	function nextStep(value) {
		handleStepChange('next', value)

	}

	function prevStep(value) {
		handleStepChange('prev', value)
	}

	return (
		<Dialog open={open} fullWidth maxWidth='md' fullScreen={isMobile}>
			<Box sx={dialog_style}>
				<Box sx={{ ...title_style }}>
					<Typography variant='h1' color="error" >{item?.name}</Typography>
					{!!item && <Typography variant='subtitle2'>{t('messages.selectedANoCompliance')}</Typography>}
					{!item && <Typography variant='h1'>{t('titles.findingCreation')}</Typography>}
				</Box>
				<RepetitionWarning finding={params} />
				<FindingDialogContext.Provider value={{
					finding,
					setFinding,
					finding_types,
					finding_measures,
					finding_actions,
					finding_timings,
					finding_form,
					params,
					setParams,
					checklist,
					item,
					nextStep,
					prevStep,
					transformBaseToOptions,
					onCancel,
					onClose,
					onReset,
					onSubmit: onCreate,
					is_evidence_required,
					is_responsible_required,
					is_criticality_required,
					responsibles,
					businessHoursOnly
				}}>
					{step === 0 && <TypeStep />}
					{step === 1 && <FormStep />}
					{step === 2 && <DescriptionStep />}
					{step === 3 && <EvidenceStep />}
					{step === 4 && <MeasureStep />}
					{step === 5 && <CriticalityStep />}
					{step === 6 && <ResponsibleStep />}
					{step === 7 && <DateLimitStep />}
					{step === 8 && <FinalStep />}
				</FindingDialogContext.Provider>
			</Box>
		</Dialog>
	)
}

export default FindingDialog