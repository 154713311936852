import { Box, Collapse, Typography } from '@mui/material'
import InlineProgressBar from '../../Show/Progress/InlineProgressBar'
import newTheme from '../../../../newTheme'
import { inline, inline_space } from '../../../../Utils/defaultStyles'
import { useTranslation } from 'react-i18next'


function CategoryCompliance({ showDetail, subtitleProgress, subtitleCompliance }) {
	const { t } = useTranslation('checklistItem')
	return (
		<Collapse in={showDetail}>
			<Box>
				<Box sx={{ position: 'relative', margin: '24px 0 24px', ...inline_space }}>
					<Typography variant='subtitle2'>{t('progress')}</Typography>
					<Box sx={{ position: 'relative', flexGrow: 1, ...inline }}>
						<InlineProgressBar value={subtitleProgress} />
					</Box>
					<Typography variant='subtitle2' color={subtitleProgress >= 100 ? "success.main" : "info.main"} >{subtitleProgress}%</Typography>
				</Box>
				<Box sx={{ position: 'relative', margin: '24px 0 24px', ...inline_space }}>
					<Typography variant='subtitle2'>{t('compliance')}</Typography>
					<Box sx={{ position: 'relative', flexGrow: 1, ...inline }}>
						<InlineProgressBar value={subtitleCompliance} baseColor={newTheme.palette.error.main} />
					</Box>
					<Typography variant='subtitle2' color={subtitleCompliance >= 100 ? "success.main" : "error.main"} >{subtitleCompliance}%</Typography>
				</Box>
			</Box>
		</Collapse>
	)
}

export default CategoryCompliance