import { Box, Typography } from '@mui/material'
import { labeless, text_space } from '../../../../Utils/defaultStyles'
import { useContext, useState } from 'react'
import moment from 'moment'
import UsersInput from '../../../../Shared/Inputs/UsersInput'
import DateTimeInput from '../../../../Shared/Inputs/DateTimeInput'
import TextInput from '../../../../Shared/Inputs/TextInput'
import { UserSelectContext } from '../../../../Shared/Dialogs/UserSelectDialog'
import DialogLayout from './DialogLayout'
import { PersonAddOutlined } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

function AssignDialog({ finding, open, onClose, crit, urls, onSubmit }) {

	const [params, setParams] = useState({ ...finding })
	const { getUsersNames } = useContext(UserSelectContext)
	const { t } = useTranslation("findingCardDialog")

	function handleChange(event) {
		const { target } = event
		const { name, value } = target
		setParams({ ...params, [name]: value })
	}

	function onAssign() {
		const responsible = getUsersNames(params.user_id)
		const body = { ...params, date_assigned: moment().format('YYYY-MM-DD HH:mm'), user_responsible: responsible }
		onSubmit(body)
		onClose()
	}

	const disableSave = !params.inmediate_action || !params.user_id || !params.date_limit
	const button_styles = { "variant": "outlined", "color": "info", "icon": <PersonAddOutlined /> }
	const titles = { "title": t('actions.assignFinding'), "action": t('actions.assignAndExit') }

	return (
		<DialogLayout open={open} finding={finding} onClose={onClose} titles={titles} onClick={onAssign} button_styles={button_styles} urls={urls} crit={crit} disableSave={disableSave} >
			<Box sx={text_space}>
				<Typography variant='subtitle2' sx={labeless}>{t('data.inmediateAction')}:</Typography>
				<TextInput value={params.inmediate_action} onChange={handleChange} name="inmediate_action" label="" />
			</Box>
			<Box sx={text_space}>
				<Typography variant='subtitle2' sx={labeless}>{t('data.responsible')}:</Typography>
				<UsersInput value={params.user_id} onChange={handleChange} name="user_id" label="" />
			</Box>
			<Box sx={text_space}>
				<Typography variant='subtitle2' sx={labeless} >{t('data.dateLimit')}:</Typography>
				<DateTimeInput value={params.date_limit} onChange={handleChange} name="date_limit" label="" />
			</Box>
		</DialogLayout>
	)
}

export default AssignDialog