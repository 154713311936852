import { useState, useEffect, useMemo, useContext } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { getProfile } from '../../../../API/users'
import { getContractors } from '../../../../API/contractors'
import { getSubEnterprises } from '../../../../API/enterprise'
import { paper_style, title_style } from '../../../../Utils/defaultStyles'
import AppContext from '../../../../AppContext'
import { UserSelectContext } from '../../../../Shared/Dialogs/UserSelectDialog'
import { userInfoFields } from '../../../Settings/BaseChecklists/Show/Form/info'
import { useTranslation } from 'react-i18next'

const css = {
	user_container: {
		marginBottom: 4,
		background: "whitesmoke",
		padding: 2,
		borderRadius: 2
	},
	container: {
		backgroundColor: "white",
		overflowY: "auto",
		maxHeight: "250px"
	}
}

function UserInfoField({ field, user_ids }) {
	const [users, setUsers] = useState([])
	const [sub_branches, setSubBranches] = useState([])
	const [sub_enterprises, setSubEnterprises] = useState([])
	const { online: { status: online } } = useContext(AppContext)
	const { getUsersObjects } = useContext(UserSelectContext)
	const { t } = useTranslation("baseChecklistsForm")
	const { t: tChecklist } = useTranslation("checklistForm")

	useEffect(() => {
		async function fetchUserData() {
			if (online) {
				const ids = Array.isArray(user_ids) ? user_ids : [user_ids]
				const response = await getProfile({ ids, info: true })
				setUsers(response.data.info)
			} else {
				const userObjects = Array.isArray(user_ids)
					? user_ids.map(id => getUsersObjects(id))
					: [getUsersObjects(user_ids)]
				setUsers(userObjects)
			}
		}

		fetchUserData()
	}, [user_ids, online])

	useEffect(() => {
		async function fetchData() {
			const responses = await Promise.all([
				getContractors(),
				getSubEnterprises()
			])
			const datas = responses.map(res => res.data.info)
			setSubBranches(datas[0])
			setSubEnterprises(datas[1])
		}

		if (online) fetchData()
	}, [])

	const selectedFields = field.compliance_observation.split(',')
	const isMultiple = Array.isArray(user_ids)

	return (
		<Box sx={{ ...paper_style, ...css.container }}>
			{users.map((user, index) => (
				<>
					{isMultiple && (
						<Typography variant="subtitle2" sx={{ m: 1 }}>
							{index + 1}. {user.name}
						</Typography>
					)}
					<Box key={index} sx={css.user_container}>
						<Grid container spacing={0}>
							{selectedFields.map((fieldLabel) => {
								const fieldInfo = userInfoFields.find((field) => field.label === fieldLabel);
								const value = typeof fieldInfo.valueKey === "function"
									? fieldInfo.valueKey(user, sub_branches, sub_enterprises)
									: user[fieldInfo.valueKey];

								return (
									<Grid item xs={12} sm={6} md={4} key={fieldInfo.label}>
										<Box sx={{ marginBottom: 2 }}>
											<Typography variant="subtitle2">{t(`${fieldInfo.nameKey}`)}:</Typography>
											<Typography variant="subtitle1">
												{value || tChecklist("userInfo.notEntered")}
											</Typography>
										</Box>
									</Grid>
								);
							})}
						</Grid>
					</Box>
				</>
			))}
			{!online && (
				<Typography variant="caption" color="warning.main">
					* {tChecklist("userInfo.showingOfflineInfo")}
				</Typography>
			)}
		</Box>
	)
}

export default UserInfoField
