import { Box, Collapse, IconButton, Typography, Button } from '@mui/material'
import moment from 'moment'
import { useContext, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ChecklistContext } from './ChecklistView'
import { inline_buttons, inline_title_no_wrap, italic, labeless, title_style } from '../../../Utils/defaultStyles'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import { Send } from '@mui/icons-material'
import TextInput from '../../../Shared/Inputs/TextInput'

const css = {
	container: {
		background: 'white',
		padding: 2,
		borderRadius: 2,
		maxWidth: 800,
	},
	new: {
		display: 'flex',
		alignItems: 'flex-end',
		marginBottom: 4,
		gap: 2
	},
	comment: {
		backgroundColor: '#f5f5f5',
		padding: '8px',
		borderRadius: 2,
		marginBottom: 2,
	},
	commentContainer: {
		maxHeight: '400px',
		overflowY: 'auto',
	},
	commentHeader: {
		fontSize: '1rem',
		color: 'rgba(0, 0, 0, 0.87)'
	},
	commentBody: {
		fontSize: '1rem',
	},
	commentFooter: {
		padding: '0.5px',
		fontSize: '0.875rem',
		color: 'rgba(0, 0, 0, 0.6)',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	}
}

function Comments({ value }) {
	const [params, setParams] = useState({})
	const { checklist, current_user, setMetadata } = useContext(ChecklistContext)
	const [commentInput, openCommentInput] = useState(false)
	const containerRef = useRef(null)
	const { t } = useTranslation('checklistShow')

	function onChange(event) {
		const { target } = event
		setParams(p => ({ ...p, [target.name]: target.value }))
	}

	function changeCommentInput() {
		openCommentInput(!commentInput)
		if (!commentInput) {
			setTimeout(() => {
				containerRef.current.scrollIntoView({ behavior: 'smooth' })
			}, 300)
		}
	}

	function onCreate() {
		const { comment, motive } = params

		const meta = checklist.metadata ? checklist.metadata : {}

		const comments = Array.isArray(meta?.comments) ? [...meta?.comments] : []

		const commentBody = {
			user: current_user.name,
			user_id: current_user.id,
			motive: motive,
			body: comment,
			date: moment().format("YYYY-MM-DD HH:mm")
		}

		comments.push(commentBody)
		meta.comments = comments
		setMetadata(meta)
		setParams({ comment: "", motive: "" })
		if (!commentInput) {
			openCommentInput(true)
		}
		setTimeout(() => {
			containerRef.current.scrollIntoView({ behavior: 'smooth' })
		}, 300)
	}

	const { comment, motive } = params

	function renderComments() {
		const metadata = checklist.metadata || {}
		const comments = metadata?.comments || []

		if (!comments.length) {
			return (
				<Typography variant='subtitle1' color="primary.main" sx={italic}>
					{t('comments.noComments')}
				</Typography>
			)
		}

		return comments.map((comment, index) => {
			return (
				<Box key={index} sx={{ ...css.comment, ...inline_buttons, display: 'flex', justifyContent: 'space-between' }} ref={containerRef}>
					<Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: "80%" }}>
						<Typography sx={css.commentHeader} variant='h4'>{comment.motive}</Typography>
						<Typography sx={css.commentBody} variant='body1' color="textSecondary">{comment.body}</Typography>
					</Box>
					<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', textAlign: 'right' }}>
						<Typography sx={css.commentFooter} variant='caption' color="textSecondary">{comment.user}</Typography>
						<Typography sx={css.commentFooter} variant='caption' color="textSecondary">{comment.date}</Typography>
					</Box>
				</Box>
			)
		})
	}

	return (
		<Box sx={css.container}>
			<Box sx={inline_title_no_wrap}>
				<Typography variant='h4'>{t('comments.addComment')}</Typography>
				<IconButton onClick={() => changeCommentInput()}>
					{commentInput ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
				</IconButton>
			</Box>
			<Collapse in={commentInput}>
				<Typography variant="subtitle2" sx={labeless}>{t('comments.subject')}:</Typography>
				<TextInput name="motive" width={"50%"} value={motive} onChange={onChange} label={""} />
				<Typography variant="subtitle2" sx={labeless}>{t('comments.comment')}:</Typography>
				<Box sx={css.new}>
					<TextInput name="comment" width={"85%"} value={comment} onChange={onChange} label={""} options={{ multiline: true, rows: 4 }} />
					<Button sx={{ marginBottom: 2 }} onClick={onCreate} variant="contained" color="primary" endIcon={<Send />} disabled={!comment}>
						{t('comments.send')}
					</Button>
				</Box>
				<Box sx={{ ...inline_buttons, marginBottom: 2 }}>
					<Typography variant='h4' fontSize={13} sx={title_style} color="info.main">{t('comments.comments')}</Typography>
				</Box>
				<Box sx={css.commentContainer}>
					{renderComments()}
				</Box>
			</Collapse>
		</Box>
	)
}

export default Comments
