import { Box, Button, IconButton, Typography } from '@mui/material'
import { useContext } from 'react'
import { ChecklistContext } from '../../Show/ChecklistView'
import { Delete, FileCopyOutlined } from '@material-ui/icons'
import IndexField from './IndexField'
import { inline_title_no_wrap } from '../../../../Utils/defaultStyles'
import { v4 as uuid } from 'uuid'
import { deepClone } from '../../../../Utils/functions'
import { useTranslation } from 'react-i18next'
import { callSnackbar } from '../../../../Utils/snackbar'
import { transformBodyAndSendCallback } from '../../../../Utils/offlineSyncFunctions'
import { useActionContext } from '../../../../DatabaseManagers/ActionsManager'

function FieldCategory({ category, fields }) {

	const { setChecklist, hasPermissions, finished, checklist, appendSave, resolveSave } = useContext(ChecklistContext)
	const actionContext = useActionContext()
	const { t } = useTranslation('checklistForm')

	function cloneFields(fields, new_category_id, original_category_id) {
		return fields.map(field => {
			const new_field = { ...field }
			new_field.clone_id = uuid()
			new_field.checklist_field_category_id = new_category_id
			new_field.checklist_field_original_category_id = original_category_id
			new_field.id = new_field.clone_id
			new_field.value = ""

			const new_options = field.options.map(option => {
				const new_option = { ...option }
				new_option.clone_id = uuid()
				new_option.id = new_option.clone_id
				const subquestions = option.subquestions || []
				if (subquestions.length) {
					new_option.subquestions = cloneFields(subquestions)
					new_option.subquestions = new_option.subquestions.map(subquestion => {
						const new_subquestion = { ...subquestion }
						new_subquestion.checklist_field_option_id = new_option.clone_id
						return new_subquestion
					})
				}
				return new_option
			})
			new_field.options = new_options
			return new_field
		})
	}

	function getFlattenedFields(fields) {
		let flattened_fields = []
		for (const field of fields) {
			flattened_fields.push(field)
			if (field.options.length) {
				for (const option of field.options) {
					const subquestions = option.subquestions || []
					if (subquestions.length) {
						const sub_flattened_fields = getFlattenedFields(subquestions)
						flattened_fields = flattened_fields.concat(sub_flattened_fields)
					}
				}
			}
		}
		return flattened_fields
	}

	async function onClone() {
		const new_category = deepClone(category)
		new_category.clone_id = uuid()
		new_category.id = new_category.clone_id
		new_category.deletable = true
		const new_fields = cloneFields(fields, new_category.clone_id, category.id)
		const new_fields_flattened = getFlattenedFields(new_fields)
		new_category.fields = new_fields

		// Update checklist information
		const new_checklist = { ...checklist }
		const new_categories = [...new_checklist.checklist_field_categories]

		const categoryIndex = new_categories.findIndex(cat => cat.id === category.id)
		new_categories.splice(categoryIndex + 1, 0, new_category)

		new_checklist.checklist_field_categories = new_categories

		const new_checklist_fields = [...new_checklist.fields, ...new_fields_flattened]
		new_checklist.fields = new_checklist_fields

		setChecklist(new_checklist)

		appendSave()
		await transformBodyAndSendCallback(new_category, "clone_category", actionContext, t('actions.cloneCategory'))
		resolveSave()

		callSnackbar(t('field.categoryCloned'))
	}


	async function onDeleteCategory() {
		const body = { ...category }

		// Remove categories and fields from checklist
		const new_checklist = { ...checklist }
		const new_categories = new_checklist.checklist_field_categories.filter(cat => cat.id !== category.id)
		const fields_to_be_deleted_ids = getFlattenedFields(fields).map(field => field.id)
		const new_fields = new_checklist.fields.filter(field => !fields_to_be_deleted_ids.includes(field.id))
		new_checklist.checklist_field_categories = new_categories
		new_checklist.fields = new_fields
		setChecklist(new_checklist)

		appendSave()
		await transformBodyAndSendCallback(body, "delete_category", actionContext, t('actions.deleteCategory'))
		resolveSave()

		callSnackbar(t('field.categoryDeleted'))

	}

	if (!fields.length) return null

	return (
		<Box style={{ background: `${category.color}22`, padding: 6, margin: "12px -6px", borderRadius: 6, }}>
			<Box sx={inline_title_no_wrap}>
				<Typography variant='h4' style={{ marginBottom: 6 }} >{category?.name}</Typography>
				{hasPermissions && !finished &&
					<IconButton disabled={!category?.deletable} onClick={onDeleteCategory}>
						<Delete />
					</IconButton>
				}
			</Box>
			{fields.map(field => <IndexField field={field} key={field.label} />)}
			{hasPermissions && !finished && category.allow_clone &&
				<Box style={{ textAlign: 'end' }}>
					<Button variant="outlined" endIcon={<FileCopyOutlined />} onClick={onClone}>
						{t('field.cloneSection')}
					</Button>
				</Box>
			}
		</Box >
	)
}

export default FieldCategory