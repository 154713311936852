import { Box, Typography } from "@mui/material"
import RutInput from "../../../../Shared/Inputs/RutInput"
import { useContext } from "react"
import { ChecklistContext } from "../../Show/ChecklistView"
import DateInput from "../../../../Shared/Inputs/DateInput"

const css = {

}

function DateField({ field, onChangeAndSave, value, onBlur }) {
	const { finished, isAdmin, hasPermissions } = useContext(ChecklistContext)
	if (finished || !hasPermissions) return (
		<Box sx={css.input}>
			<Typography variant='subtitle1'>{value}</Typography>
		</Box>
	)
	return (
		<Box sx={css.input}>
			<DateInput value={value} onChange={onChangeAndSave} label="" />
		</Box>
	)
}

export default DateField