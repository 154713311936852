import { Box, Dialog, Typography, Button } from '@mui/material'
import { createContext, useContext, useState } from 'react'
import { dialog_style, inline_space, labeless, section_separation, text_space, title_style } from '../../Utils/defaultStyles'
import TextInput from '../Inputs/TextInput'
import { useTranslation } from 'react-i18next'
export const ConfirmationContext = createContext({})

export function useConfirmationDialog() {
	const { askConfirmation } = useContext(ConfirmationContext)
	return askConfirmation

}

function ConfirmationDialog({ children }) {

	const { t } = useTranslation("dialogs")
	const [openDialog, setOpenDialog] = useState(false)
	const [title, setTitle] = useState(t('actions.deleteElement'))
	const [description, setDescription] = useState(t('confirmationQuestions.deleteElement'))
	const [submit, setSubmit] = useState(t('answer.delete'))
	const [cancel, setCancel] = useState(t('actions.cancel'))
	const [action, setAction] = useState(() => { })
	const [askMotive, setAskMotive] = useState(false)
	const [motive, setMotive] = useState("")
	const [color, setColor] = useState("warning")
	const [cancelColor, setCancelColor] = useState("")
	const [cancelCallback, setCancelCallback] = useState(() => { })

	function askConfirmation(
		title = null, description = null, submit = null, action = null, askMotive = false, color = "warning", cancel = null, cancelColor = "primary", cancelCallback = null
	) {
		setOpenDialog(true)
		setTitle(title || t('actions.deleteElement'))
		setDescription(description || t('confirmationQuestions.deleteElement'))
		setSubmit(submit || t('answer.delete'))
		setCancel(cancel || t('actions.cancel'))
		setAction(() => action || (() => { }))
		setAskMotive(askMotive)
		setColor(color)
		setCancelColor(cancelColor)
		setCancelCallback(() => cancelCallback || (() => setOpenDialog(false)))
	}

	function onSubmit() {
		setOpenDialog(false)
		action(motive)
	}

	function onCancel() {
		setOpenDialog(false)
		cancelCallback()
	}

	const disabled = askMotive && !motive

	return (
		<Box>
			<Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth maxWidth="sm">
				<Box sx={dialog_style}>
					<Typography sx={title_style} variant='h4'>{title}</Typography>
					<Typography sx={section_separation} variant='subtitle1'>{description}</Typography>
					{askMotive &&
						<Box sx={text_space}>
							<Typography sx={labeless} variant='subtitle2'>{t('data.motive')}</Typography>
							<TextInput label="" value={motive} onChange={e => setMotive(e.target.value)} />
						</Box>
					}
					<Box sx={inline_space}>
						<Button color={cancelColor} variant="contained" onClick={onCancel}>
							{cancel}
						</Button>
						<Button color={color} variant="outlined" onClick={onSubmit} disabled={disabled}>
							{submit}
						</Button>
					</Box>
				</Box>
			</Dialog>
			<ConfirmationContext.Provider value={{ askConfirmation }}>
				{children}
			</ConfirmationContext.Provider>
		</Box>
	)
}

export default ConfirmationDialog