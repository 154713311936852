import { Box, Typography } from '@mui/material'
import { ChecklistContext } from '../../Show/ChecklistView'
import { useContext, useEffect, useMemo, useState } from 'react'
import { DatabaseManagerContext } from '../../../../DatabaseManagers/DatabaseManager'
import { ContractorsManagerContext } from '../../../../DatabaseManagers/ContractorsManager'
import SelectInput from '../../../../Shared/Inputs/SelectInput'
import { transformToOptions } from '../../../../Utils/functions'
import { useTranslation } from 'react-i18next'

function ContractorField({ field, onChangeAndSave, value, onBlur }) {
	const { finished, isAdmin, hasPermissions } = useContext(ChecklistContext)
	const [contractors, setContractors] = useState([])
	const { getContractors } = useContext(ContractorsManagerContext)
	const { indexedDBReady } = useContext(DatabaseManagerContext)
	const { t } = useTranslation('checklistForm')

	useEffect(() => {
		async function fetchData() {
			const response = await getContractors()
			setContractors(response)
		}
		if (indexedDBReady) { fetchData() }
	}, [indexedDBReady])

	const selectedContractor = useMemo(() => { return contractors.find(c => String(c.id) === String(value)) })

	if (finished || !hasPermissions) return (
		<Box>
			<Typography variant='subtitle1'>{selectedContractor?.name || t('noAnswer')}</Typography>
		</Box>
	)
	return (
		<SelectInput options={transformToOptions(contractors)} value={value} onChange={onChangeAndSave} label="" />
	)
}

export default ContractorField