import { Box, Button, Dialog, IconButton, Typography } from '@mui/material'
import { dialog_style, end_flex_buttons, inline_space_title, text_space, title_style } from '../../../Utils/defaultStyles'
import { Clear } from '@material-ui/icons'
import { ConfirmationContext } from '../../Dialogs/ConfirmationDialog'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

function RepeatedDialog({ open, onClose, checklist, onDeleteChecklist }) {
	const { askConfirmation } = useContext(ConfirmationContext)
	const { t } = useTranslation('cards')

	function onDelete() {
		function callback() {
			onDeleteChecklist()
		}
		askConfirmation(null, null, null, callback)
	}

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
			<Box sx={dialog_style}>
				<Box sx={inline_space_title}>
					<Typography variant="h4" color="warning.main">
						{t('repeatedLocation.title')}
					</Typography>
					<IconButton onClick={onClose}>
						<Clear />
					</IconButton>
				</Box>
				<Typography variant="subtitle2" sx={text_space}>
					{t('repeatedLocation.assignedMessage', { baseName: checklist?.base_name })}
				</Typography>
				<Typography variant="subtitle1">
					{t('repeatedLocation.informativeMessage')}
				</Typography>
				<Typography variant="subtitle1" sx={title_style}>
					{t('repeatedLocation.deletePrompt')}
				</Typography>
				<Box sx={end_flex_buttons}>
					<Button variant="outlined" color="success" onClick={onClose}>
						{t('repeatedLocation.continueButton')}
					</Button>
					<Button variant="contained" color="error" onClick={onDelete}>
						{t('repeatedLocation.deleteButton')}
					</Button>
				</Box>
			</Box>
		</Dialog>
	)
}

export default RepeatedDialog
