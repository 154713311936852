import { Box, Paper, Typography } from '@mui/material'
import { useContext, useState } from 'react'
import { CurrentUserContext } from '../../../../CurrentUser'
import ImageContainer from '../../../../Shared/Containers/ImageContainer'
import { completePath } from '../../../../Utils/functions'
import SignGroup from './SignGroup'
import { ChecklistContext } from '../../Show/ChecklistView'
import { useTranslation } from 'react-i18next'

const css = {
	paper: {
		padding: 2
	}
}

function SignsGroups({ value }) {

	const [selectedGroup, setGroup] = useState(null)
	const { currentUser } = useContext(CurrentUserContext)
	const { checklist, hasPermissions, readonly, imResponsibleOfSection } = useContext(ChecklistContext)
	const { t } = useTranslation('checklistSigns')
	const groups = checklist?.group_signs || []

	function onSelect(group) {
		if (selectedGroup === group) return setGroup(null)
		setGroup(group)
	}
	const hasRegisteredSigns = !!currentUser?.signs?.length
	const sign = hasRegisteredSigns ? currentUser.signs[0] : null
	const old_sign_url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_USER_SIGNS}`
	const signUrl = completePath(sign?.sign, old_sign_url)
	const isCollab = currentUser.userType === 3
	const hasPriviledges = hasPermissions || imResponsibleOfSection
	const shouldSignInmediatly = (!hasPriviledges || isCollab) && hasRegisteredSigns
	const signs = groups.map(g => g.signs).flat()

	return (
		<Box>
			<Paper sx={css.paper}>
				{(shouldSignInmediatly) && !readonly &&
					<Box>
						<Typography variant='h4'>{t('group.signingLike')} {currentUser?.name}</Typography>
						<Box sx={css.sign}>
							<ImageContainer src={signUrl} height={64} width={128} border={"6px"} />
						</Box>
					</Box>
				}
				{groups.map(group => <SignGroup signs={signs} onSelect={onSelect} selected={selectedGroup} group={group} />)}
			</Paper>
		</Box>
	)
}

export default SignsGroups