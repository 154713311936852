import { Button } from '@material-ui/core'
import { FindInPageOutlined } from '@material-ui/icons'
import { Box, Paper, Typography } from '@mui/material'
import { useContext } from 'react'
import useToggle from '../../../Hooks/ToogleHook'
import { inline, inline_space } from '../../../Utils/defaultStyles'
import { ChecklistContext } from '../Show/ChecklistView'
import FindingCard from '../../Findings/Card/FindingCard'
import FindingDialog from '../../Findings/CreateDialog/FindingDialog'
import newTheme from '../../../newTheme'
import { v4 as uuid } from 'uuid'
import { FindingsManagerContext } from '../../../DatabaseManagers/FindingsManager'
import { transformBodyAndSendCallback } from '../../../Utils/offlineSyncFunctions'
import { useActionContext } from '../../../DatabaseManagers/ActionsManager'
import { useTranslation } from 'react-i18next'

const css = {
	paper: {
		padding: 2,
		margin: '12px 0'
	},
	dialog: {
		padding: 2
	},
	inline: {
		display: 'flex',
		alignItems: 'center'
	},
	findings: {
		background: newTheme.palette.background.main,
		padding: 1,
		borderRadius: 1,
		margin: '12px 0'
	}
}

function FindingsContainer({ value }) {
	const { checklist, setChecklist, appendSave, resolveSave, readonly } = useContext(ChecklistContext)
	const [openDialog, toggleDialog] = useToggle(false)
	const { addFinding: addFindingToLocalDB } = useContext(FindingsManagerContext)
	const actionContext = useActionContext()
	const { t } = useTranslation('checklistContent')

	async function createFindingOffline(data) {
		const new_checklist = { ...checklist }
		data.checklist_index = checklist.id + new_checklist.findings.length
		const new_finding = { ...data }
		new_finding.temp_id = uuid()
		new_finding.id = new_finding.temp_id
		new_checklist.findings.push(new_finding)
		setChecklist(new_checklist)
		addFindingToLocalDB(new_finding)
		appendSave()
		await transformBodyAndSendCallback(new_finding, "create_finding", actionContext, t('actions.finding'), "form")
		resolveSave()
	}

	const findings = checklist.findings || []
	if (!checklist.require_finding_visibility) {
		return null
	}
	return (
		<Paper sx={css.paper}>
			<Box sx={inline_space}>
				<Box sx={inline}>
					<FindInPageOutlined />
					<Typography variant='h4'>{t('findings')}</Typography>
				</Box>
				<Button color="primary" variant="contained" onClick={toggleDialog}>
					{t('addFinding')}
				</Button>
			</Box>
			<FindingDialog
				open={openDialog}
				onClose={toggleDialog}
				onCancel={toggleDialog}
				onCreate={createFindingOffline}
				checklist={checklist}
			/>
			{!!findings.length &&
				<Box sx={css.findings}>
					{findings.map((finding, index) => {
						const secondImgURL = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_IMG_FOLDER}`
						const firstImgURL = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_FINDING_IMG_FOLDER}`
						return (
							<Box sx={{ marginBottom: 1 }} key={finding.id}>
								<FindingCard
									urls={[firstImgURL, secondImgURL]}
									finding={finding}
									disabled={readonly}
									index={index}
								/>
							</Box>
						)
					})}
				</Box>
			}
		</Paper>
	)
}

export default FindingsContainer