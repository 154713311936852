import { Collapse, IconButton, Typography, Box, Button, Divider } from '@mui/material'
import { KeyboardArrowDown, PersonAdd, Send } from '@material-ui/icons'
import { useContext, useState } from 'react'
import useToggle from '../../../Hooks/ToogleHook'
import { inline_buttons_wrap, inline_space, inline_space_no_wrap, inline_title_no_wrap, text_space } from '../../../Utils/defaultStyles'
import { UserSelectContext } from '../../../Shared/Dialogs/UserSelectDialog'
import { Clear } from '@mui/icons-material'
import newTheme from '../../../newTheme'
import { useTranslation } from 'react-i18next'
import EmailInputWithMenu from '../../../Shared/Inputs/EmailInputWithMenu'
import AppContext from '../../../AppContext'
import { customNotifyFinish } from '../../../API/definitive_checklists'
import { callSnackbar } from '../../../Utils/snackbar'
import { ChecklistContext } from './ChecklistView'

const css = {
	container: {
		background: 'white',
		padding: 2,
		borderRadius: 2,
		maxWidth: 800,
		margin: "12px 0"
	},
	button: {
		textAlign: 'end',
		marginTop: 2
	},
	email_tag: {
		background: newTheme.palette.background.main,
		padding: '4px 12px',
		...inline_space,
		borderRadius: 4,
	},
	emails: {
		...inline_buttons_wrap,
		margin: '12px 0',
		maxHeight: 150,
		overflowY: 'auto',
	}
}

function FinishNotification() {
	const [openView, toggleOpen] = useToggle(false)
	const [emails, setEmails] = useState([])
	const { openUsersDialog } = useContext(UserSelectContext)
	const { branch_id } = useContext(AppContext)
	const { checklist } = useContext(ChecklistContext)
	const { t } = useTranslation("checklistShow")

	async function send() {
		const body = { id: checklist.id, emails }
		await customNotifyFinish(body)
		setEmails([])
		callSnackbar(t('email.success'), 'success')
	}

	function removeEmail(email) {
		const newEmails = emails.filter(e => e != email)
		setEmails(newEmails)
	}

	function handleUserSelect(user) {
		const value = user.email || user
		const uniqueEmails = [...new Set([...emails, value])]
		setEmails(uniqueEmails)
	}

	function onOpenDialog() {
		function callback(users) {
			const newEmails = users.map(user => user.email)
			const uniqueEmails = [...new Set([...emails, ...newEmails])]
			setEmails(uniqueEmails)
		}
		openUsersDialog(callback, [], branch_id, true)
	}

	return (
		<Box sx={css.container}>
			<Box onClick={toggleOpen} sx={inline_title_no_wrap}>
				<Typography variant='h4'>{t('email.title')}</Typography>
				<IconButton>
					<KeyboardArrowDown />
				</IconButton>
			</Box>
			<Collapse in={openView}>
				<Box sx={text_space} />
				<Box sx={inline_space_no_wrap}>
					<EmailInputWithMenu onSelect={handleUserSelect} />
					<IconButton onClick={onOpenDialog}>
						<PersonAdd />
					</IconButton>
				</Box>
				<Divider sx={text_space} />
				<Box sx={css.emails}>
					{emails?.map(email => {
						return (
							<Box sx={css.email_tag}>
								<Typography variant='subtitle1'>{email}</Typography>
								<IconButton size='small' onClick={() => removeEmail(email)}>
									<Clear />
								</IconButton>
							</Box>
						)
					})}
				</Box>
				<Box sx={css.button}>
					<Button color="primary" variant="contained" startIcon={<Send />} onClick={send} disabled={emails?.length === 0}>
						{t('email.send')}
					</Button>
				</Box>
			</Collapse>
		</Box>
	)
}

export default FinishNotification