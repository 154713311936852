import { Box, Dialog, Typography, Button } from '@mui/material'
import { dialog_style, inline_space } from '../../Utils/defaultStyles'
import { useTranslation } from 'react-i18next'

function DeleteDialog({ open, onClose, onSubmit }) {
	const { t } = useTranslation("dialogs")
	function handleSubmit() {
		onSubmit()
		onClose()
	}

	return (
		<Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
			<Box sx={dialog_style}>
				<Typography variant='h4'>{t('confirmationQuestions.deleteElement')}</Typography>
				<Box sx={{ ...inline_space, marginTop: 4 }}>
					<Button color="error" variant="outlined" onClick={handleSubmit}>
						{t('answer.delete')}
					</Button>
					<Button variant='contained' onClick={onClose}>
						{t('actions.cancel')}
					</Button>
				</Box>
			</Box>
		</Dialog>
	)
}

export default DeleteDialog