import { Box, Button, Typography } from '@mui/material'
import { getLocalCrits } from '../../../../Utils/functions'
import { inline_space, title_style } from '../../../../Utils/defaultStyles'
import { ErrorOutline } from '@material-ui/icons'
import { FindingDialogContext } from '../FindingDialog'
import { useContext } from 'react'
import newTheme from '../../../../newTheme'
import moment from 'moment'
import { calculateBusinessHoursDeadline } from '../../../../Utils/functions'
import { useTranslation } from 'react-i18next'

const css = {
	crit: {
		width: 130,
		borderRadius: 2,
		border: '1px solid black',
		transition: 'all 0.2s ease-in-out',
		cursor: 'pointer',
		'&:hover': {
			transform: 'scale(1.05)'
		},
		padding: 2,
	},
	crits: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 2,
		margin: '12px 0',
	}
}

function CriticalityStep({ value }) {

	const { params, setParams, nextStep, prevStep, finding_actions, is_criticality_required, businessHoursOnly } = useContext(FindingDialogContext)
	const crits = getLocalCrits()
	const { t } = useTranslation("findingCreationDialog")

	function onClick(value) {
		setParams(p => ({ ...p, free_criticality: value }))
		// Set date limit based on selected criticality

		const crit = crits.find(c => c.value === value)
		const new_date_limit = businessHoursOnly ? calculateBusinessHoursDeadline(moment(), crit.hours_offset) : moment().add(crit.hours_offset, 'hours').format('YYYY-MM-DD HH:mm')
		const maxDate = !!crit.hours_offset ? (businessHoursOnly ? calculateBusinessHoursDeadline(moment(), crit.hours_offset) : moment().add(crit.hours_offset, 'hours').format('YYYY-MM-DD HH:mm')) : null
		setParams(p => ({ ...p, date_limit: new_date_limit, maxDate }))
	}

	const base_action = finding_actions.find(ba => ba.id === params.base_finding_action_id)
	const default_criticality = base_action ? base_action.default_criticality : undefined
	if (params.free_criticality === undefined && default_criticality) {
		setParams(p => ({ ...p, free_criticality: default_criticality }))
	}

	const disableContinue = params.free_criticality === undefined || (params.free_criticality === 0 && is_criticality_required)

	return (
		<Box>
			<Typography variant='h4' color="info.main" sx={title_style} >{t('questions.noComplianceCriticalityQuestion')}</Typography>
			<Typography variant='subtitle2' sx={title_style} >{t('indications.selectCriticality')}</Typography>
			<Box sx={css.crits}>
				{crits.map(crit => {

					const style = { ...css.crit }
					const text_style = {}
					style.border = `1px solid ${crit.color}`
					const selected = crit.value === params.free_criticality
					const canContrast = ![1, 2].includes(crit.value)
					if (selected) {
						style.transform = 'scale(1.05)'
						style.border = `1px solid black`
						style.backgroundColor = crit.color
						if (canContrast) text_style.color = 'white'
						if (!canContrast) text_style.color = newTheme.palette.primary.main
					}
					return (
						<Box sx={style} onClick={() => onClick(crit.value)}>
							<Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
								<ErrorOutline style={{ color: crit.color, ...text_style }} />
								<Typography variant='subtitle2' color={selected && canContrast ? "white.main" : "primary"} >{crit.label}</Typography>
							</Box>
						</Box>
					)
				})}
			</Box>
			<Box sx={inline_space}>
				<Button variant="outlined" onClick={prevStep}>
					{t('stepBackwards')}
				</Button>
				<Button variant="contained" color="success" onClick={nextStep} disabled={disableContinue}>
					{t('actions.continue')}
				</Button>
			</Box>
		</Box>
	)
}

export default CriticalityStep