import moment from "moment"
import { deepClone } from "../../../Utils/functions"
import { v4 as uuid } from 'uuid'

function isItemAnswered(item) { return item.answer.value_id !== null }
function isAnswerYES(item) { return item.answer.value_id === 1 }
function isAnswerNO(item) { return item.answer.value_id === 2 }
function isAnswerNA(item) { return item.answer.value_id === 3 }
function isFieldAnswered(field) { return !!field.value }

export function getProgress(checklist) {
	if (!checklist) return 0
	const categories = checklist.categories || []
	const disabled_categories = categories.filter(cat => !!cat.disabled).map(cat => cat.id)
	const items = checklist?.checklist_items2 || []
	if (items.length === 0) return 100

	const enabled_items = items.filter(element => !disabled_categories.includes(element?.checklist_category_id))
	if (enabled_items.length === 0) return 100

	const total = enabled_items.length
	let added = 0
	enabled_items.forEach(element => { if (isItemAnswered(element)) { added++ } })
	return Math.round(added / total * 100, 10)
}

export function getCompliance(checklist, isPartial = false) {
	if (!checklist) return 0
	const items = checklist?.checklist_items2 || []
	const compliance_fields = checklist?.checklist_fields?.filter(field => field.type === "compliance") || []
	if (items.length === 0 && compliance_fields.length === 0) return 100

	let total_items = 0
	let added_values = 0

	items.forEach(item => {
		if (!isItemAnswered(item)) return null
		if (isAnswerNA(item)) return null
		total_items += item.points
		if (isAnswerNO(item)) return null
		added_values += item.points
	})

	const itemsCompliance = total_items > 0 ? (added_values / total_items * 100) : 0

	let added_field_values = 0
	let total_fields = 0
	compliance_fields.forEach(field => {
		if (!isFieldAnswered(field)) return null
		total_fields += 1
		const field_values = JSON.parse(field.value)
		const not_favorable_cases = field_values.desfav || 0
		let fieldCompliance = 100

		// If solved in place and is asking for final compliance return 100%
		if (field_values.solved && !isPartial) { fieldCompliance = 100 }
		else {
			const nonCompliancePercentage = field_values.total > 0 ? (not_favorable_cases * 100) / field_values.total : 100
			fieldCompliance = 100 - nonCompliancePercentage
		}
		added_field_values += fieldCompliance
	})

	const fieldsTotalCompliance = total_fields > 0 ? (added_field_values / total_fields) : 0
	const added_totals = total_fields + total_items
	const weightedItemsCompliance = added_totals > 0 ? (total_items / added_totals) * itemsCompliance : 0
	const weightedFieldsCompliance = added_totals > 0 ? (total_fields / added_totals) * fieldsTotalCompliance : 0
	const finalCompliance = weightedItemsCompliance + weightedFieldsCompliance
	return (finalCompliance).toFixed(1)
}

export function getPoints(checklist) {
	const checklistElements = checklist?.checklist_items2 || []
	if (checklistElements.length === 0) return { total: 0, added: 0 }
	let total = 0
	let added = 0
	checklistElements.forEach(element => {
		if (element.answer.value_id !== null && element.answer.value_id !== 3) {
			total += element.points
			if (element.answer.value_id === 1) {
				added += element.points
			}
		}
	})
	return { total, added }
}

export function getTotalCounters(checklist) {
	function getFactor(finding) {
		const crit = finding?.criticality
		if (crit === 4) return 2
		if (crit === 3) return 1.5
		return 1
	}
	const findings = checklist.findings || []
	if (findings.length === 0) return 0
	let total = 0
	for (let finding of findings) {
		total += getFactor(finding) * finding.counter
	}
	return total
}

export function CheckIfItemsAreAnswered(checklist) {
	return getProgress(checklist) === 100
}

export function CheckIfFieldsAreAnswered(checklist) {
	const fields = checklist.fields || []
	const formatted_fields = formatForm(fields)
	const allRequiredAnswered = formatted_fields.map(checkIfFieldReady).every(Boolean)
	const checklistDone = CheckIfChecklistAreDone(checklist)
	return allRequiredAnswered && checklistDone
}

export function CheckIfChecklistAreDone(checklist) {
	const fields = checklist.fields || []
	const formatted_fields = formatForm(fields)
	const allFieldsChecklistDone = formatted_fields.map(field =>
		CheckIfChecklistAreDoneForField(field, checklist.related_checklists)
	).every(Boolean)
	if (!checklist.related_checklists || allFieldsChecklistDone) return true
	return false
}

export function CheckIfSignsAreReady(checklist) {
	const groupSigns = checklist.group_signs || []
	const participants = checklist.requested_participants || []

	if (checklist.all_guests_must_sign) {
		const totalSigns = groupSigns.reduce((total, group) => {
			return total + (group.signs ? group.signs.length : 0)
		}, 0)

		if (totalSigns < participants.length) {
			return false
		}
		else {
			for (let participantId of participants) {
				const participantIdNumber = parseInt(participantId)
				const participantFound = groupSigns.some(signGroup => signGroup.signs.some(sign => parseInt(sign.user_id) === participantIdNumber))
				if (!participantFound) {
					return false
				}
			}
		}
	}

	if (checklist.at_least_one_signature_required) {
		const groups = checklist.group_signs || [] // Array of groups of signs
		for (const groupSign of groups) {
			if (groupSign.signs && groupSign.signs.length > 0) {
				return true //At least one sign founded
			}
		}
		return false
	}
	return true
}

export function getItemsProgress(checklist) {
	return getProgress(checklist)
}

export function getFieldsProgress(checklist) {
	const fields = checklist.fields || []
	const formatted_fields = formatForm(fields)
	const related_checklists = checklist.related_checklists || []

	function getFieldsProgressValues(fields) {
		let allRequiredFields = 0
		let allRequiredAnsweredFields = 0

		for (const field of fields) {
			if (field.required) allRequiredFields += 1
			if (field.required && !!field.value && CheckIfChecklistAreDoneForField(field, related_checklists)) allRequiredAnsweredFields += 1
			if (!field.required) continue
			if (!field.value) continue
			const options = field.options || []
			const selected_option = options.find(option => option.label === field.value)
			const subquestions = selected_option?.subquestions || []
			const child_results = getFieldsProgressValues(subquestions)
			allRequiredFields += child_results[0]
			allRequiredAnsweredFields += child_results[1]
		}

		return [allRequiredFields, allRequiredAnsweredFields]
	}

	const [allRequiredFields, allRequiredAnsweredFields] = getFieldsProgressValues(formatted_fields)
	const progress = allRequiredFields > 0 ? Math.round(allRequiredAnsweredFields * 100 / allRequiredFields) : 100
	return progress
}

export function CheckIfChecklistAreDoneForField(field, related_checklists) {
	const options = field.options || []
	const selected_option = options.find(option => option.label === field.value)
	const base_checklists = selected_option?.base_checklists || []
	const required_base_checklists = base_checklists.filter(base_checklist => base_checklist.required)
	if (required_base_checklists.length === 0) return true
	const completedChecklistNames = related_checklists
		.filter(base_checklist => base_checklist.status === "finished")
		.map(doneChecklist => doneChecklist.name)
	return required_base_checklists.every(bc => completedChecklistNames.includes(bc.name))
}

export function checkIfAreTheSameFields(f1, f2) {
	if (f1.id === f2.id) return true
	// If f1 has a clone id, check if the clone id is the same
	if (!!f1.clone_id && f1.clone_id === f2.clone_id) return true
	return false
}


export function sortCategories(c1, c2) {
	if (c1.color > c2.color) return -1
	if (c1.color < c2.color) return 1
	if (moment(c1.created_at) > moment(c2.created_at)) return 1
	if (moment(c1.created_at) < moment(c2.created_at)) return -1
	return 0
}

export function formatForm(fields, isPublic) {
	if (!fields) return [];

	const newFields = deepClone(fields);
	const key = isPublic ? "base_field_option_id" : "checklist_field_option_id";

	// Recursive function to format options and their subquestions
	function formatOptions(options) {
		return options.map(option => {
			const newOption = { ...option };
			const subquestions = newFields.filter(f => f[key] === option.id);

			// Apply recursion for each subquestion's options
			newOption.subquestions = subquestions.map(sq => ({
				...sq,
				options: formatOptions(sq.options || [])
			}));

			return newOption;
		});
	}

	return newFields
		.filter(field => field[key] === null)
		.map(field => ({
			...field,
			options: formatOptions(field.options || [])
		}));
}


function checkIfFieldReady(field) {
	if (!field.required) return true
	if (!field.value) return false
	const options = field.options || []
	const selected_option = options.find(option => option.label === field.value)
	const subquestions = selected_option?.subquestions || []
	if (!subquestions.length) return true
	const required_subquestions = subquestions.filter(subquestion => subquestion.required)
	const ready_with_subquestions = required_subquestions.map(checkIfFieldReady).every(Boolean)
	return ready_with_subquestions
}

export function generateOfflineChecklist(base_checklist, supply_type_id = null, user_id = null, relatedChecklistInfo = null) {
	const newChecklist = {}
	newChecklist.id = uuid()
	newChecklist.base_checklist_id = base_checklist.id
	newChecklist.correlative = "-"
	newChecklist.name = base_checklist.name
	newChecklist.date_scheduled = moment().format("YYYY-MM-DD")
	newChecklist.date_start = moment().format("YYYY-MM-DD HH:mm:ss")
	newChecklist.date_end = moment().add(base_checklist.duration, 'hours').format("YYYY-MM-DD HH:mm:ss")
	newChecklist.date_done = "0000-00-00"
	newChecklist.time_done = "00:00:00"
	newChecklist.checklist_category_group_name = base_checklist.checklist_category_group_name
	newChecklist.allow_sheet_clone = base_checklist.allow_sheet_clone
	newChecklist.supply_type_id = supply_type_id
	newChecklist.user_id = user_id
	newChecklist.invert_order = base_checklist.invert_order
	newChecklist.require_signs = base_checklist.require_signs
	newChecklist.findings = []
	newChecklist.preventive_measure_description = base_checklist.description
	newChecklist.require_finding_visibility = base_checklist.require_finding_visibility
	newChecklist.require_finding_criticality = base_checklist.require_finding_criticality
	newChecklist.require_finding_evidence = base_checklist.require_finding_evidence
	newChecklist.require_finding_responsible = base_checklist.require_finding_responsible

	// We need categories, field_categories, items and fields	

	// Start with items and their categories
	const base_categories = base_checklist.subtitles || []
	const base_categories_new_cateogories_map = {}
	const item_categories = base_categories.map(category => {
		const newCategory = {}
		const new_id = uuid()
		newChecklist.allow_clone = category.allow_clone
		newCategory.base_subtitle_id = category.id
		newCategory.checklist_category_group_id = null
		newCategory.category_order = category.subtitle_order
		newCategory.checklist_id = newChecklist.id
		newCategory.disabled = false
		newCategory.id = new_id
		newCategory.name = category.name
		newCategory.temp_id = new_id
		newCategory.users = []
		base_categories_new_cateogories_map[category.id] = new_id
		return newCategory
	})

	newChecklist.categories = item_categories

	// Now we need to create the items and fields
	const base_items = base_checklist.checklist_items || []
	const items = base_items.map(base_item => {
		const new_item = {}
		const new_id = uuid()
		new_item.checklist_category_id = base_categories_new_cateogories_map[base_item.checklist_subtitle_id] || null
		new_item.checklist_id = newChecklist.id
		new_item.content = null
		new_item.counter = 0
		new_item.default_criticality = base_item.default_criticality
		new_item.id = new_id
		new_item.name = base_item.name
		new_item.negative_answer_type = base_item.negative_answer_type
		new_item.points = base_item.points
		new_item.send_notification = false
		new_item.checklist_subtitle_id = base_item.checklist_subtitle_id
		new_item.temp_id = new_id
		new_item.findings = []
		const new_item_answer_id = uuid()
		new_item.answer = {
			checklist_item_id: new_id,
			comments: null,
			counter: null,
			criticality: null,
			file: null,
			file2: null,
			file3: null,
			id: new_item_answer_id,
			temp_id: new_item_answer_id,
			value: null,
			value_id: null
		}
		return new_item
	})

	newChecklist.checklist_items2 = items // We need to change this to checklist_items when we have the new endpoint

	// Now we need to create the fields and field_categories
	const base_field_categories = base_checklist.fields_categories || []
	const base_fields_map = {}
	const field_categories = base_field_categories.map(base_field_category => {
		const new_field_category = {}
		const new_id = uuid()

		new_field_category.checklist_id = newChecklist.id
		new_field_category.clone_id = null
		new_field_category.color = base_field_category.color
		new_field_category.id = new_id
		new_field_category.deletable = false
		new_field_category.name = base_field_category.name
		new_field_category.clone_id = new_id

		base_fields_map[base_field_category.id] = new_id
		return new_field_category
	})

	newChecklist.checklist_field_categories = field_categories

	const base_fields = base_checklist.extra_fields || []
	const base_options_map = {}
	const fields = base_fields.map(base_field => {
		const new_field = {}
		const new_id = uuid()

		new_field.checklist_field_category_id = base_fields_map[base_field.base_field_category_id] || null
		new_field.checklist_field_option_id = base_options_map[base_field.base_field_option_id] || null
		new_field.checklist_id = newChecklist.id
		new_field.clone_id = null
		new_field.compliance_case = base_field.compliance_case
		new_field.compliance_observation = base_field.compliance_observation
		new_field.field_type = base_field.field_type
		new_field.id = new_id
		new_field.label = base_field.label
		new_field.required = base_field.required
		new_field.clone_id = new_id
		new_field.value = ""
		new_field.options = base_field.options.map(base_option => {
			const new_option = {}
			const new_option_id = uuid()
			new_option.checklist_field_id = new_id
			new_option.id = new_option_id
			new_option.label = base_option.label

			base_options_map[base_option.id] = new_option_id
			return new_option
		})
		return new_field

	})

	newChecklist.fields = fields

	// Now we need to create the group_signs

	const base_sign_groups = base_checklist.base_checklist_sign_groups || []

	const sign_groups = base_sign_groups.map(sign_group => {
		const new_group = {}
		const new_id = uuid()
		new_group.checklist_id = newChecklist.id
		new_group.id = new_id
		new_group.description = sign_group.description
		new_group.name = sign_group.name
		new_group.signs = []
		return new_group
	})

	newChecklist.group_signs = sign_groups

	newChecklist.related_checklists = relatedChecklistInfo

	return newChecklist
}

export function getStatusColor(status) {
	if (status === "finished") return "green.main"
	if (status === "scheduled") return "blue.main"
	if (status === "overdue") return "error.main"
	return "warning.main"
}