import { Box, Typography } from "@mui/material"
import { useContext } from "react"
import { ChecklistContext } from "../../Show/ChecklistView"
import moment from "moment"
import DateTimeInput from "../../../../Shared/Inputs/DateTimeInput"
import { useTranslation } from "react-i18next"

const css = {}

function PeriodField({ field, onChange, value, onChangeAndSave }) {
	const { finished, isAdmin, hasPermissions } = useContext(ChecklistContext)
	const { t } = useTranslation("checklistForm")

	function processValue() {
		return (
			value?.split("#") || [
				moment().format("YYYY-MM-DD hh:mm"),
				moment().format("YYYY-MM-DD hh:mm"),
			]
		)
	}

	function onChangePeriod(step) {
		return (event) => {
			const { target } = event
			const new_value_array = processValue()
			new_value_array[step] = target.value
			const new_value = new_value_array.join("#")
			const new_event = { target: { value: new_value } }
			onChangeAndSave(new_event)
		}
	}

	const parsed_value = processValue()

	if (finished || !hasPermissions)
		return (
			<Box sx={css.input}>
				<Typography variant="subtitle1">
					{t("period.from")}: {parsed_value[0]} - {t("period.to")}: {parsed_value[1]}
				</Typography>
			</Box>
		)

	return (
		<Box
			sx={{ ...css.input, display: "flex", gap: 2, flexWrap: "wrap" }}
		>
			<Box sx={{ flexBasis: 250, flexGrow: 1 }}>
				<DateTimeInput
					onChange={onChangePeriod(0)}
					label={t("period.from")}
					value={parsed_value[0]}
				/>
			</Box>
			<Box sx={{ flexBasis: 250, flexGrow: 1 }}>
				<DateTimeInput
					onChange={onChangePeriod(1)}
					label={t("period.to")}
					value={parsed_value[1]}
				/>
			</Box>
		</Box>
	)
}

export default PeriodField
