import { Box, Typography, Checkbox, Collapse } from '@mui/material'
import { italic, inline_buttons, labeless, text_space, title_style } from '../../../../Utils/defaultStyles'
import { CheckCircleOutline } from '@material-ui/icons'
import TextInput from '../../../../Shared/Inputs/TextInput'
import { useState } from 'react'
import moment from 'moment'
import SolutionInfo from '../CardInfo/SolutionInfo'
import useToggle from '../../../../Hooks/ToogleHook'
import newTheme from '../../../../newTheme'
import UsersInput from '../../../../Shared/Inputs/UsersInput'
import { createEfficacyVerification } from '../../../../API/findings'
import DialogLayout from './DialogLayout'
import { useTranslation } from 'react-i18next'

const css = {
	container: (openCollapse) => ({
		backgroundColor: openCollapse ? newTheme.palette.background.main : 'white',
		padding: 2,
		margin: '20px 0'
	})
}

function ApproveDialog({ finding, open, onClose, crit, urls, onSubmit }) {

	const [params, setParams] = useState({})
	const [openCollapse, toggleCollapse] = useToggle(false)
	const { t } = useTranslation("findingCardDialog")

	function handleChange(event) {
		const { target } = event
		const { name, value } = target
		setParams({ ...params, [name]: value })
	}

	async function onApprove() {
		const body = { ...params, date_approved: moment().format('YYYY-MM-DD HH:mm') }
		if (openCollapse) {
			const efficacy_body = { id: finding.id, user_id: params.user_id }
			await createEfficacyVerification(efficacy_body)
		}
		onSubmit(body)
		onClose()
	}

	const is_verifiable = !!finding.is_verifiable
	const disableSave = openCollapse && !params.user_id
	const button_styles = { "variant": "contained", "color": "success", "icon": <CheckCircleOutline /> }
	const titles = { "title": t('actions.approveFinding'), "action": t('actions.approveAndExit') }
	// TODO: AGREGAR CRITICIDAD
	return (
		<DialogLayout
			open={open}
			onClose={onClose}
			finding={finding}
			disableSave={disableSave}
			onClick={onApprove}
			titles={titles}
			urls={urls}
			crit={crit}
			button_styles={button_styles}>

			<SolutionInfo finding={finding} urls={urls} />
			<Box sx={text_space}>
				<Typography variant='subtitle2' sx={labeless}>{t('data.comments')}:</Typography>
				<TextInput value={params.approval_comment} onChange={handleChange} name="approval_comment" label="" />
			</Box>
			<Box sx={css.container(openCollapse)}>
				{is_verifiable &&
					<Box sx={{ ...inline_buttons, marginBottom: 2 }}>
						<Checkbox checked={openCollapse} onClick={toggleCollapse} />
						<Typography variant='subtitle2'>{t('actions.verifyEffectiveness')}</Typography>
					</Box>}
				<Collapse in={openCollapse}>
					<Box sx={{ ...text_space, margin: 0 }}>
						<Typography variant='subtitle2' sx={labeless}>{t('data.userResponsible')}:</Typography>
						<UsersInput label="" name="user_id" onChange={handleChange} value={params.user_id} />
					</Box>
					<Typography variant='subtitle2' sx={italic} color='warning.main' >{t('messages.verificationOfEfficacyDate', { verification_limit: finding.verification_limit })}</Typography>
				</Collapse>
			</Box>
		</DialogLayout>
	)
}

export default ApproveDialog