import { Box, IconButton, Typography } from '@mui/material'
import useToggle from '../../../../Hooks/ToogleHook'
import { useContext } from 'react'
import { ChecklistContext } from '../../Show/ChecklistView'
import { CategoryOutlined, KeyboardArrowDown } from '@material-ui/icons'
import FinishedItem from './FinishedItem'
import { UserSelectContext } from '../../../../Shared/Dialogs/UserSelectDialog'
import { useTranslation } from 'react-i18next'

const css = {
	category: {
		margin: '30px 0 6px 0'
	},
	users: {
		background: 'white',
		borderRadius: 2,
		position: 'relative',
		padding: 2,
		margin: '6px 0'
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
		gap: 2,
	},
	inline: {
		display: 'flex',
		alignItems: 'center'
	},
}

function FinishedItemsCategory({ category, items }) {
	const { isAdmin, isResponsible } = useContext(ChecklistContext)
	const { getUsers } = useContext(UserSelectContext)
	const [open, toggleOpen] = useToggle(true)
	const hasPrivileges = isAdmin || isResponsible
	const workers = getUsers()
	const category_users = category.users || []
	const branch_users_id = workers.map(u => u.id)
	const category_users_on_this_branch = category_users.filter(u => branch_users_id.includes(u.user_id))
	const disabled_category = category?.disabled
	const enabled_users = category_users_on_this_branch.filter(u => !u.disabled)
	const hasResponsibles = !!enabled_users.length && hasPrivileges && !disabled_category
	const showItems = open && !disabled_category
	const { t } = useTranslation('checklistItem')

	if (!items.length) return null
	return (
		<Box sx={css.category}>
			<Box sx={css.header}>
				<Box sx={css.inline}>
					<CategoryOutlined color={disabled_category ? "disabled" : "secondary"} style={{ marginRight: 6 }} />
					<Typography variant='h4' color={disabled_category ? "disabled" : "secondary"}>{category.name}</Typography>
				</Box>
				{!disabled_category &&
					<Box sx={css.inline}>
						<IconButton onClick={toggleOpen}>
							<KeyboardArrowDown />
						</IconButton>
					</Box>
				}
			</Box>
			{hasResponsibles &&
				<Box sx={css.users}>
					<Typography variant='subtitle1'>{t('responsibles')}</Typography>
					{enabled_users.map(user => {
						return (
							<Box>
								<Typography variant='subtitle1'><strong>{user.name}</strong></Typography>
							</Box>
						)
					}
					)}
				</Box>
			}

			{showItems &&
				<>
					{items.map(item => <FinishedItem key={item.id} item={item} />)}
				</>
			}
		</Box>
	)
}

export default FinishedItemsCategory