import { Box, Checkbox, Typography } from '@mui/material'
import { inline_buttons } from '../../../../Utils/defaultStyles'
import { useTranslation } from 'react-i18next'

function CategoryUsers({ users, disabled, onToggleUser }) {
	const { t } = useTranslation('checklistItem')
	return (
		<Box>
			{users.map(user => {
				const imInactive = user.active === 1
				return (
					<Box sx={inline_buttons}>
						<Checkbox disabled={disabled} checked={!user.disabled} onClick={() => onToggleUser(user.id)} />
						<Typography variant='subtitle1' color={imInactive ? "grey.medium" : "primary"}>{user.name} {imInactive ? t('disabled') : ""}</Typography>
					</Box>
				)
			})}
		</Box>
	)
}

export default CategoryUsers