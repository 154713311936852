import { Box, Typography } from '@mui/material'
import { labeless, text_space } from '../../../../Utils/defaultStyles'
import { useState } from 'react'
import moment from 'moment'
import SolutionInfo from '../CardInfo/SolutionInfo'
import DateTimeInput from '../../../../Shared/Inputs/DateTimeInput'
import DialogLayout from './DialogLayout'
import { HighlightOffOutlined } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import RTE from '../../../../Shared/Inputs/RTE'

function RejectDialog({ finding, open, onClose, crit, urls, onSubmit }) {

	const [params, setParams] = useState({})
	const { t } = useTranslation("findingCardDialog")

	function handleChange(event) {
		const { target } = event
		const { name, value } = target
		setParams({ ...params, [name]: value })
	}

	function onReject() {
		const rejection = {
			content: params.content,
			date_limit: params.date_limit,
			rejected_comment: finding.solution_comment,
			rejected_file: finding.solution_evidence,
		}
		const body = { ...params, date_approved: moment().format('YYYY-MM-DD HH:mm') }
		body.rejections = [rejection]
		onSubmit(body)
		onClose()
	}
	const button_styles = { "variant": "outlined", "color": "warning", "icon": <HighlightOffOutlined /> }
	const titles = { "title": t('actions.rejectFinding'), "action": t('actions.rejectAndExit') }

	return (
		<DialogLayout open={open} finding={finding} onClose={onClose} titles={titles} onClick={onReject} button_styles={button_styles} urls={urls} crit={crit} >
			<SolutionInfo finding={finding} urls={urls} />
			<Box sx={text_space}>
				<Typography variant='subtitle2' >{t('data.rejectReason')}:</Typography>
				<RTE value={params.content} onChange={handleChange} name="content" label="" />
			</Box>
			<Box sx={text_space}>
				<Typography variant='subtitle2' sx={labeless}>{t('data.dateLimitNew')}</Typography>
				<DateTimeInput value={params.date_limit} onChange={handleChange} name="date_limit" label="" />
			</Box>
		</DialogLayout>
	)
}

export default RejectDialog