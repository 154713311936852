import { useContext } from "react"
import { ChecklistContext } from "../../Show/ChecklistView"
import { Box, Typography } from "@mui/material"
import UsersInput from "../../../../Shared/Inputs/UsersInput"
import { UserSelectContext } from "../../../../Shared/Dialogs/UserSelectDialog"
import UserInfoField from "./UserInfoField"
import { isValidJson } from "../../../../Utils/functions"

function MultiUserField({ field, onChange, value = null, onBlur, onChangeAndSave }) {
	const { finished, isAdmin, hasPermissions } = useContext(ChecklistContext)
	const { getUsersNames } = useContext(UserSelectContext)

	let formatedValue = ""
	if (!!value) { formatedValue = (Array.isArray(value)) ? value : JSON.parse(value) }

	const validValue = isValidJson(value) ? JSON.parse(value) : value || []

	if (finished || !hasPermissions) return (
		<Box>
			<Typography variant='subtitle1'>{getUsersNames(formatedValue) || "Sin respuesta"}</Typography>
			{field.compliance_observation && <UserInfoField field={field} user_ids={validValue} />}
		</Box>
	)

	return (
		<Box>
			<UsersInput onBlur={onChangeAndSave} value={formatedValue} onChange={onChange} label="" isMultiple />
			{field.compliance_observation && <UserInfoField field={field} user_ids={validValue} />}
		</Box>
	)
}

export default MultiUserField