import { Box, Button, Typography } from '@mui/material'
import UsersInput from '../Inputs/UsersInput'
import { useState } from 'react'
import { useOTPDialog } from './OTPDialog'
import { end_buttons, labeless } from '../../Utils/defaultStyles'
import { Pin } from '@mui/icons-material'

const css = {
	container: {
		margin: '12px 0'
	}
}

function OTPSign({ callback, user_id }) {

	const [userId, setUserId] = useState(user_id || '')
	const verifyPin = useOTPDialog()

	return (
		<Box sx={css.container}>
			<Typography sx={labeless} variant='subtitle2'>Seleccionar participante</Typography>
			<UsersInput value={userId} onChange={e => setUserId(e.target.value)} disabled={user_id} />

			<Box sx={end_buttons}>
				<Button
					color="success"
					variant="contained"
					onClick={() => verifyPin({ user_id: userId, callback })}
					disabled={!userId}
					endIcon={<Pin />}
				>
					Solicitar Código
				</Button>
			</Box>
		</Box>
	)
}

export default OTPSign