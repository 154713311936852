import { Box, IconButton } from '@mui/material'
import { Fragment, useRef } from 'react'
import ImageContainer from '../../../../Shared/Containers/ImageContainer'
import { AddAPhotoOutlined, Delete } from '@material-ui/icons'
import { completePath, resizeImage } from '../../../../Utils/functions'
import { checklistsUrl } from '../../../../Utils/baseUrls'

const css = {
	images: {
		display: 'flex',
		alignItems: 'center',
		gap: 2,
		justifyContent: 'flex-end',
		margin: '12px 0'
	},
	imageContainer: {
		borderRadius: 2,
		border: '1px dashed black',
		height: 62,
		width: 62,
		position: 'relative'
	},
	image_button: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)'
	},
	delete_image_button: {
		position: 'absolute',
		left: '65%',
		top: '65%',
		background: 'white',
		opacity: 0.8
	},
}



const files = ["file", "file2", "file3"]

function ItemPositiveImages({ disabled, params, onChange }) {

	const file = useRef(null)
	const file2 = useRef(null)
	const file3 = useRef(null)
	const file_refs = { file, file2, file3 }

	function onUploadImage(name) {
		return async (event) => {
			const { target } = event
			const { files } = target
			const file = files[0]
			const image = await resizeImage(file)
			const file_name = file.name
			const image_with_name = `${image}|&|${file_name}`
			const new_value = image_with_name
			onChange({ target: { name, value: new_value } })
		}
	}

	function onDeleteImage(name) {
		return () => { onChange({ target: { name, value: null } }) }
	}

	return (
		<Box sx={css.images}>
			{files.map(file_input => {
				const image = params[file_input.replace("1", "")]
				const image_without_name = !!image ? image.split("|&|")[0] : null
				return (
					<Fragment key={file_input}>
						<input accept="image/png, image/jpeg" type="file" hidden ref={file_refs[file_input]} onChange={onUploadImage(file_input)} />
						<Box sx={css.imageContainer}>
							{!!image_without_name && <ImageContainer height={62} width={62} border={'6px'} src={completePath(image_without_name, checklistsUrl) || "/noimage.png"} />}
							{!image_without_name && <IconButton disabled={disabled} sx={css.image_button} onClick={() => file_refs[file_input].current.click()}>
								<AddAPhotoOutlined />
							</IconButton>}
							{!!image_without_name &&
								<IconButton disabled={disabled}
									onClick={onDeleteImage(file_input)}
									size="small"
									sx={css.delete_image_button}
								>
									<Delete />
								</IconButton>}
						</Box>
					</Fragment>
				)
			})}
		</Box>
	)
}

export default ItemPositiveImages