import { Box, Typography } from "@mui/material"
import { useContext } from "react"
import { ChecklistContext } from "../../Show/ChecklistView"
import PlainImageInput from "../../../../Shared/Inputs/PlainImageInput"
import MultipleImageContainer from "../../../../Shared/Containers/MultipleImageContainer"
import { checklistsUrl } from "../../../../Utils/baseUrls"
import { useTranslation } from "react-i18next"

function ImageField({ field, value, onChangeAndSave }) {

	const { finished, hasPermissions } = useContext(ChecklistContext)
	const { t } = useTranslation("checklistForm")

	const images = typeof value === "string" ? value.split("&#&") : ""
	if (finished || !hasPermissions) return (
		<Box>
			{!!images.length ?
				<MultipleImageContainer border="6px" images={images} height={64} width={64} align="left" base_url={checklistsUrl} /> :
				<Typography variant='subtitle1'>{t('noAnswer')}</Typography>
			}
		</Box>
	)

	return (
		<Box>
			<PlainImageInput
				value={value}
				name={field.label}
				label=""
				url={"/"}
				onChange={onChangeAndSave}
			/>
		</Box>
	)
}

export default ImageField