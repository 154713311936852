import { AttachFile, Delete } from '@material-ui/icons'
import { Badge, Box, Button, Dialog, IconButton, Typography } from '@mui/material'
import { useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ChecklistContext } from './ChecklistView'
import useToggle from '../../../Hooks/ToogleHook'
import { uploadFile } from '../../../API/users'
import { editAttachments } from '../../../API/new_checklists'
import { dialog_style, end_buttons, inline, inline_space, text_space } from '../../../Utils/defaultStyles'
import BigTooltip from '../../../Shared/Tooltip/BigTooltip'

function ChecklistAttachments({ value }) {
	const { checklist, setChecklist, finished } = useContext(ChecklistContext)
	const [openDialog, toggleDialog] = useToggle(false)
	const input = useRef(null)
	const { t } = useTranslation('checklistShow')

	async function onFileSelect(event) {
		const { target } = event
		const file = target.files[0]
		const body = new FormData()
		body.append("file", file)
		body.append("name", file.name)
		const response = await uploadFile(body)
		const new_url = response.data.info
		const old_files = checklist.attachment
		const new_files_array = !!old_files ? old_files.split("&#&") : []
		new_files_array.push(new_url)
		const new_files = new_files_array.join("&#&")
		editAttachments({ id: checklist.id, attachment: new_files })
		const new_checklist = { ...checklist }
		new_checklist.attachment = new_files
		setChecklist(new_checklist)
	}

	const attachment = checklist.attachment
	const attachments = attachment?.split("&#&") || []

	function renderAttachments() {
		return attachments.map((att, index) => {
			const att_array = att.split("/")
			const name = att_array[att_array.length - 1]
			return (
				<Box sx={{ ...text_space, ...inline_space }} key={index}>
					<Box sx={{ ...inline }}>
						<AttachFile />
						<Typography
							onClick={() => window.open(att)}
							style={{ cursor: "pointer" }}
							color="info.main"
							variant="subtitle1">
							{name}
						</Typography>
					</Box>
					{!finished &&
						<IconButton onClick={() => removeAttachment(index)}>
							<Delete />
						</IconButton>
					}
				</Box>
			)
		})
	}

	function removeAttachment(index) {
		const new_attachments = [...attachments]
		new_attachments.splice(index, 1)
		const new_files = new_attachments.join("&#&") || null
		editAttachments({ id: checklist.id, attachment: new_files })
		const new_checklist = { ...checklist }
		new_checklist.attachment = new_files
		setChecklist(new_checklist)
	}

	return (
		<Box>
			<BigTooltip title={t('secondaryInfo.attachPdf')}>
				<Badge badgeContent={attachments.length} color="info">
					<IconButton onClick={toggleDialog}>
						<AttachFile />
					</IconButton>
				</Badge>
			</BigTooltip>
			<Dialog open={openDialog} onClose={toggleDialog} fullWidth maxWidth="sm">
				<input ref={input} hidden type="file" accept="application/pdf" onChange={onFileSelect} />
				<Box sx={dialog_style}>
					<Typography variant="h4">{t('secondaryInfo.attachedFiles')}</Typography>
					{renderAttachments()}
					{!finished &&
						<Box sx={end_buttons}>
							<Button onClick={() => input.current.click()} endIcon={<AttachFile />} color="info" variant="contained">
								{t('secondaryInfo.attachPdf')}
							</Button>
						</Box>
					}
				</Box>
			</Dialog>
		</Box>
	)
}

export default ChecklistAttachments