import { Box, Button, Typography } from '@mui/material'
import { useContext } from 'react'
import { FindingDialogContext } from '../FindingDialog'
import SelectInput from '../../../../Shared/Inputs/SelectInput'
import { transformToOptions } from '../../../../Utils/functions'
import { inline_space, labeless, title_style } from '../../../../Utils/defaultStyles'
import { useTranslation } from 'react-i18next'
function TypeStep() {

	const { nextStep, finding_types, params, setParams, onCancel } = useContext(FindingDialogContext)
	const { t } = useTranslation("findingCreationDialog")

	function onChange(event) {
		const { target } = event
		const { name, value } = target
		setParams(p => ({ ...p, [name]: value }))
	}

	if (finding_types.length === 0) {
		nextStep()
	}

	return (
		<Box>
			<Typography variant='h4' sx={title_style} color="info.main" >{t('data.findingType')}</Typography>
			<Typography variant='subtitle2' sx={labeless} >{t('indications.selectFindingType')}</Typography>
			<SelectInput options={transformToOptions(finding_types)} label="" name="finding_type_id" value={params.finding_type_id} onChange={onChange} />
			<Box sx={inline_space}>
				<Button variant="contained" color="error" onClick={onCancel}>
					{t('actions.cancel')}
				</Button>
				{params.finding_type_id && <Button variant="contained" color="success" onClick={nextStep}>
					{t('actions.continue')}
				</Button>}
			</Box>
		</Box>
	)
}

export default TypeStep