import { InfoOutlined } from "@material-ui/icons"
import { Box, Typography } from "@mui/material"
import { useContext, useEffect, useMemo, useRef, useState } from "react"
import { inline_buttons } from "../../../../Utils/defaultStyles"
import { transformBodyAndSendCallback } from "../../../../Utils/offlineSyncFunctions"
import '../animations.css'
import { ChecklistContext } from "../../Show/ChecklistView"
import newTheme from "../../../../newTheme"
import useToggle from "../../../../Hooks/ToogleHook"
import FindingDialog from "../../../Findings/CreateDialog/FindingDialog"
import ItemFindings from "./ItemFindings"
import ItemComment from "./ItemComment"
import ItemHTMLContent from "./ItemHTMLContent"
import ItemButtons from "./ItemButtons"
import ItemPositiveImages from "./ItemPositiveImages"
import { v4 as uuid } from 'uuid'
import { FindingsManagerContext } from "../../../../DatabaseManagers/FindingsManager"
import { useActionContext } from "../../../../DatabaseManagers/ActionsManager"
import { useTranslation } from "react-i18next"

const css = {
	item: {
		background: 'white',
		borderRadius: 2,
		padding: 2,
		margin: '12px 0'
	},
	item_header: {
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		gap: 2
	},
	option_button: {
		padding: "8px 24px"
	},
	button: {
		cursor: 'pointer',
	},
}

function Item({ item, disabled }) {

	const { setChecklist, checklist, highlighted, appendSave, resolveSave, isPublic, setHighlighted } = useContext(ChecklistContext)
	const { addFinding: addFindingToLocalDB } = useContext(FindingsManagerContext)
	const [params, setParams] = useState({})
	const mainItem = useRef(null)
	const [openContent, toggleContent] = useToggle(false)
	const [openFindingForm, toggleFindingForm] = useToggle(false)
	const actionContext = useActionContext()
	const { t } = useTranslation('checklistItem')

	useEffect(() => { setParams({ ...item.answer }) }, [item])

	async function answerChecklistItemOffline(body) {
		const new_items = [...checklist.checklist_items2]
		const new_item_index = new_items.findIndex(i => i.id === item.id)
		const new_item = { ...item, answer: body }
		new_items[new_item_index] = new_item
		const new_checklist = { ...checklist }
		new_checklist.checklist_items2 = new_items
		setChecklist(new_checklist)

		if (isPublic) return
		if (body.value_id === 2 && !item.findings.length && item.negative_answer_type === 0) { toggleFindingForm() }

		appendSave()
		await transformBodyAndSendCallback(body, "answer_checklist_item", actionContext, t('actions.itemAnswer'))
		resolveSave()
	}

	async function addFindingToItemOffline(finding, updated_checklist) {
		finding.temp_id = uuid() // Add a temporary id to the finding
		finding.id = finding.temp_id // Add the temporary id to the finding id so it can be used in the frontend
		const new_checklist = updated_checklist ? { ...updated_checklist } : { ...checklist }
		const new_items = [...new_checklist.checklist_items2]
		const new_item_index = new_items.findIndex(i => i.id === item.id)
		const new_item = new_items.find(i => i.id === item.id)
		const new_findings = [...item.findings, finding]
		new_item.findings = new_findings
		new_items[new_item_index] = new_item
		new_checklist.checklist_items2 = new_items
		new_checklist.findings.push(finding)
		addFindingToLocalDB(finding)
		console.log(finding)
		setChecklist(new_checklist)

		if (isPublic) return

		appendSave()
		await transformBodyAndSendCallback(finding, "create_finding", actionContext, t('actions.itemFinding'))
		resolveSave()
	}

	function updateChecklistAnswer(body) {
		answerChecklistItemOffline(body)
	}

	function onCancelAnswer() {
		toggleFindingForm()
		// Return if there are findings already created
		if (!!item.findings.length) return
		answerChecklistItemOffline({ ...item.answer, value_id: null })
	}

	function answerItem(option) {
		const body = { ...item.answer, type: "answer" }
		body.value_id = option
		body.value = option > 0 ? ["SI", "NO", "N/A"][option - 1] : ""
		body.checklist_id = checklist.id
		body.criticality = parseInt(item.default_criticality, 10)
		updateChecklistAnswer(body)
		if (shouldHighligh && body.value != null) {
			setHighlighted(null);
		}
	}

	function onChange(e) { setParams(p => ({ ...p, [e.target.name]: e.target.value })) }
	function onChangeAndBlur(e) {
		const { target } = e
		const { name, value } = target
		const body = { ...item.answer, ...params, type: "image" }
		body.checklist_id = checklist.id
		body[name] = value
		updateChecklistAnswer(body)
	}


	function onBlur() {
		const body = { ...item.answer, ...params, type: "comment" }
		body.checklist_id = checklist.id
		updateChecklistAnswer(body)
	}

	const answer = item.answer
	const isAnswered = !!answer.value_id
	const isNegativeAnswer = answer.value_id === 2
	const isPositiveAnswer = answer.value_id === 1
	const isNegativeWithImages = isNegativeAnswer && item.negative_answer_type === 1
	const findings = item.findings || []
	const shouldHighligh = useMemo(() => item.id === highlighted?.element?.id, [highlighted, item.id])
	const showFindings = useMemo(() => isNegativeAnswer && !isPublic, [isNegativeAnswer, isPublic])
	const showPositiveAnswerImages = useMemo(() => isNegativeWithImages || (isPositiveAnswer && !isPublic), [isPositiveAnswer, isPublic, isNegativeWithImages])

	useEffect(() => {
		if (shouldHighligh) {
			mainItem.current.scrollIntoView({ behavior: "smooth", block: "center" })
		}
	}, [shouldHighligh, mainItem])

	return (
		<Box sx={css.item} ref={mainItem} className={shouldHighligh ? "highlight_background" : ""}>
			<Box sx={css.item_header}>
				<Box>
					<Typography
						className={shouldHighligh ? "highlight_text" : ""}
						variant='subtitle1'
					>
						<strong>{item?.name}</strong>{item?.points > 1 ? ` (${item?.points} Pts)` : ""}
					</Typography>
					{!!item?.content &&
						<Box sx={{ ...inline_buttons, ...css.button }} onClick={toggleContent}>
							<InfoOutlined style={{ height: 18, width: 18, color: newTheme.palette.grey.medium }} />
							<Typography variant='caption'>{t('viewInfo')}</Typography>
						</Box>
					}
				</Box>
				<ItemButtons disabled={disabled} answer={answer} answerItem={answerItem} />
			</Box>
			{openContent && <ItemHTMLContent item={item} />}
			{showPositiveAnswerImages && <ItemPositiveImages disabled={disabled} params={params} onChange={onChangeAndBlur} />}
			<FindingDialog
				item={item}
				open={openFindingForm}
				onClose={toggleFindingForm}
				onCancel={onCancelAnswer}
				onCreate={addFindingToItemOffline}
				checklist={checklist}
			/>
			{isAnswered && <ItemComment value={params.comments} onChange={onChange} onBlur={onBlur} disabled={disabled} />}
			{showFindings && <ItemFindings findings={findings} onCreate={toggleFindingForm} disabled={disabled} />}
		</Box >
	)
}

export default Item