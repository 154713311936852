import { Box } from '@mui/material'
import moment from 'moment';
import { createContext, useContext, useEffect, useState } from 'react'

export const ActionManagerContext = createContext({})

export function useActionContext() {
	const context = useContext(ActionManagerContext);
	return context;
}

function ActionManager({ children, db }) {

	const [actions, setActionsArray] = useState([])

	useEffect(() => {
		getActions()
	}, [])

	function addAction(action) {
		if (!db) return null
		console.log(action)
		const db_action = db.transaction(["actions"], "readwrite")
			.objectStore("actions")
			.put(action)

		db_action.onsuccess = function (event) {
			getActions()
			// console.log("Action has been added to the store")
		}

		db_action.onerror = function (event) {
			// console.log("Error adding action to the store")
		}
		cleanActions()
	}

	function updateAction(action) {
		const db_action = db.transaction(["actions"], "readwrite")
			.objectStore("actions")
			.put(action)

		db_action.onsuccess = function (event) {
			getActions()
			// console.log("Action has been updated to the store")
		}

		db_action.onerror = function (event) {
			// console.log("Error updating action to the store")
		}
		cleanActions()
	}

	function addActions(actions) {
		const transaction = db.transaction(["actions"], "readwrite")
		const store = transaction.objectStore("actions")

		for (const action of actions) {
			store.put(action)
		}

		transaction.oncomplete = function (event) {
			// console.log("Actions has been added to the store")
			setActionsArray(r => [...r, ...actions])
		}

		transaction.onerror = function (event) {
			// console.log("Error adding actions to the store")
		}
		cleanActions()
	}

	function getAction(id) {
		if (!db) return null
		const db_action = db.transaction(["actions"]).objectStore("actions").get(id)

		return new Promise((resolve, reject) => {
			db_action.onsuccess = function (event) {
				resolve(db_action.result)
			}

			db_action.onerror = function (event) {
				// console.log("Error getting action from the store")
				reject(db_action.error)
			}
		})
	}

	function cleanActions() {
		// This removes actions that are old, we store only 50 "successful" actions and 100 "error" actions
		const transaction = db.transaction(["actions"], "readwrite");
		const store = transaction.objectStore("actions");

		// Use 'prev' to start at the end and move backwards
		const request = store.openCursor(null, 'prev');
		let successCount = 0;
		let errorCount = 0;

		request.onsuccess = function (event) {
			const cursor = event.target.result;
			if (cursor) {
				if (cursor.value.status === "success") {
					successCount++;
					if (successCount > 50) {
						store.delete(cursor.key);
					}
				} else if (cursor.value.status === "error") {
					errorCount++;
					if (errorCount > 100) {
						store.delete(cursor.key);
					}
				}
				cursor.continue();
			} else {
				// console.log("Actions have been cleaned");
			}
		};
	}

	function setActions(actions) {
		const transaction = db.transaction(["actions"], "readwrite")
		const store = transaction.objectStore("actions")
		store.clear()

		for (const action of actions) {
			store.put(action)
		}

		transaction.oncomplete = function (event) {
			setActionsArray(actions)
			// console.log("Actions has been added to the store")
		}

		transaction.onerror = function (event) {
			// console.log("Error adding actions to the store")
		}
		cleanActions()
	}

	function getActions() {
		if (!db) return null
		const objectStore = db.transaction("actions").objectStore("actions")
		const actions_response = []

		return new Promise((resolve, reject) => {
			objectStore.openCursor().onsuccess = function (event) {
				const cursor = event.target.result
				if (cursor) {
					actions_response.push(cursor.value)
					cursor.continue()
				} else {
					setActionsArray(actions_response)
					resolve(actions_response)
				}
			}

			objectStore.openCursor().onerror = function (event) {
				// console.log("Error getting actions from the store")
				reject(event.target.error)
			}
		})
	}

	function getActionsPlain() {
		if (!db) return Promise.resolve([]); // Return an empty array if the db is not available

		const objectStore = db.transaction("actions").objectStore("actions");
		const actions_response = [];

		return new Promise((resolve, reject) => {
			const cursorAction = objectStore.openCursor();

			cursorAction.onsuccess = function (event) {
				const cursor = event.target.result;
				if (cursor) {
					actions_response.push(cursor.value);
					cursor.continue();
				} else {
					resolve(actions_response);
				}
			};

			cursorAction.onerror = function (event) {
				// console.log("Error getting actions from the store");
				reject(event.target.error);
			};
		});
	}

	function deleteAction(id) {
		if (!db) return null
		const db_action = db.transaction(["actions"], "readwrite").objectStore("actions").delete(id)

		return new Promise((resolve, reject) => {
			db_action.onsuccess = function (event) {
				resolve(db_action.result)
				getActions()
			}

			db_action.onerror = function (event) {
				// console.log("Error getting action from the store")
				reject(db_action.error)
			}
		})
	}

	return (
		<ActionManagerContext.Provider value={{ updateAction, addAction, addActions, getAction, getActions, setActions, deleteAction, actions, getActionsPlain }}>
			{children}
		</ActionManagerContext.Provider>
	)
}

export default ActionManager