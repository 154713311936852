import { Box, Button, Dialog, Typography } from '@mui/material'
import TextInput from '../../../Shared/Inputs/TextInput'
import { useEffect, useState } from 'react'
import { end_flex_buttons, title_style } from '../../../Utils/defaultStyles'
import { useTranslation } from 'react-i18next'

function PositionForm({ open, onClose, position, onSubmit }) {
	const { t } = useTranslation('positions')

	const [name, setName] = useState(position?.name || '')

	useEffect(() => {
		if (!!position) { setName(position?.name || '') }
	}, [position])

	function onSave() {
		const body = { ...position, name }
		onSubmit(body)
		onClose()
	}

	return (
		<Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
			<Box sx={{ padding: 3 }}>
				<Typography variant="h4" sx={title_style}>{t('actions.createPosition')}</Typography>
				<TextInput label={t('labels.nameLabel')} value={name} onChange={e => setName(e.target.value)} />
				<Box sx={end_flex_buttons}>
					<Button variant="outlined" color="error" onClick={onClose}>{t('actions.cancel')}</Button>
					<Button variant="contained" color="success" onClick={onSave} disabled={!name}>
						{t('actions.saveAndExit')}
					</Button>
				</Box>
			</Box>
		</Dialog>
	)
}

export default PositionForm
