import instance2 from "../Utils/instance2"

export function getChecklist(body) {
  return instance2.get('checklists/show', { params: body })
}

export function createChecklist(body) {
  return instance2.post('checklists/create', body)
}

export function setSubdivision(body) {
  return instance2.post('checklists/subdivision', body)
}

export function getSettingsChecklist(body) {
  return instance2.get('settings/checklists/get.php', { params: body })
}

export function setChecklistSupply(body) {
  return instance2.post('checklists/set_supply', body)
}

export function checklistReportAmount(body, signal) {
  return instance2.post('checklists/amount_report', body, signal)
}

export function checklistReportPercent(body, signal) {
  return instance2.post('checklists/percent_report', body, signal)
}

export function groupReportAmount(body, signal) {
  return instance2.post('checklists/amount_report/group', body, signal)
}

export function groupReportPercent(body, signal) {
  return instance2.post('checklists/percent_report/group', body, signal)
}

export function fullChecklistReportPercent(body, signal) {
  return instance2.post('checklists/full_percent_report', body, signal)
}