import { useContext } from 'react'
import { CurrentUserContext } from '../../CurrentUser'
import { permissions_dict } from '../../Components/Workers/Info'

function PermissionChecker({ expectedPermissions, children, plain }) {

  const { currentUser } = useContext(CurrentUserContext)

  const permission_string = currentUser.permissions || ""
  const restrictions = permission_string.split(",")

  function checkPermissions() {
    let expectedArray = Array.isArray(expectedPermissions) ? expectedPermissions : [expectedPermissions]
    if (plain) {
      expectedArray = expectedArray.map(permission => permissions_dict[permission])
    }
    const hasAllThePermissions = expectedArray.every(permission => !restrictions.includes(permission))
    return hasAllThePermissions
  }

  const hasAllThePermissions = checkPermissions()

  if (!hasAllThePermissions) return null
  return children
}

export default PermissionChecker