import React from 'react'
import { Button, Box } from '@material-ui/core'

function SubmitButton({ color = "primary", variant = "outlined", size = "small", align = "end", children, onClick, disabled = false, }) {
  return (
    <Box sx={{ textAlign: align }}>
      <Button color={color} variant={variant} size={size} onClick={onClick} disabled={disabled}>
        {children}
      </Button>
    </Box>
  )
}

export default SubmitButton