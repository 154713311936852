import { HighlightOffOutlined, KeyboardArrowDown } from '@material-ui/icons'
import { Box, IconButton, Typography } from '@mui/material'
import { useContext, useEffect, useMemo, useState } from 'react'
import useToggle from '../../../../Hooks/ToogleHook'
import { getCompliance, getPoints, getProgress } from '../checklistUtils'
import { ChecklistContext } from '../ChecklistView'
import ProgressBar from './ProgressBar'
import { useTranslation } from 'react-i18next'

const css = {
	container: {
		flexGrow: 1,
		position: 'sticky',
		top: '24px',
		zIndex: 100
	},
	card: {
		padding: 2,
		background: 'white',
		borderRadius: 2,
		position: 'sticky',
		top: '24px',
		transition: 'all 0.2s linear'
	},
	info: {
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	info_line: {
		position: 'relative',
		marginBottom: 3,
		maxWidth: 300
	}
}



function FlexCard() {

	const { checklist } = useContext(ChecklistContext)
	const [isOverChecklist, setOver] = useState(false)

	const [openInfo, toggleInfo] = useToggle(false)
	const progress = useMemo(() => getProgress(checklist), [checklist])
	const compliance = useMemo(() => getCompliance(checklist), [checklist])
	const partial_compliance = useMemo(() => getCompliance(checklist, true), [checklist])
	const { total, added } = useMemo(() => getPoints(checklist), [checklist])
	const { t } = useTranslation('checklistShow')

	useEffect(() => {
		window.addEventListener("scroll", isOver)
	}, [])

	function isOver() {
		if (window.scrollY > 150 && window.innerWidth < 1300) {
			setOver(true)
		} else {
			setOver(false)
		}
	}

	const textColor = false && isOverChecklist ? 'whitesmoke' : '#6a6a6a'
	const cardBackground = false && isOverChecklist ? '#00000042' : 'white'

	return (
		<Box sx={css.card} style={{ background: cardBackground }}>
			<Box sx={css.header}>
				<Box>
					{!isOverChecklist && <Typography style={{ color: textColor }} variant='h4'>{t('progress.stats')}</Typography>}
					{!openInfo && <Typography style={{ color: textColor }} variant='subtitle1'>{`${t('progress.compliance')} ${compliance}% | ${t('progress.progress')} ${progress}%`}</Typography>}
				</Box>
				<IconButton onClick={toggleInfo}>
					{openInfo ? <HighlightOffOutlined style={{ color: textColor }} /> : <KeyboardArrowDown style={{ color: textColor }} />}
				</IconButton>
			</Box>
			{openInfo &&
				<Box style={{ marginTop: 12 }}>
					<Box sx={css.info_line}>
						<Typography sx={css.info} style={{ color: textColor }} variant='subtitle1'>{t('progress.progress')} <strong>{progress}%</strong></Typography>
						<ProgressBar value={progress} />
					</Box>
					<Box sx={css.info_line}>
						<Typography sx={css.info} style={{ color: textColor }} variant='subtitle1'>{t('progress.partialCompliance')} <strong>{partial_compliance}%</strong></Typography>
						<ProgressBar value={partial_compliance} />
					</Box>
					<Box sx={css.info_line}>
						<Typography sx={css.info} style={{ color: textColor }} variant='subtitle1'>{t('progress.finalCompliance')} <strong>{compliance}%</strong></Typography>
						<ProgressBar value={compliance} />
					</Box>
					<Box sx={css.info_line}>
						<Typography sx={css.info} style={{ color: textColor }} variant='subtitle1'>{t('progress.score')} <strong>{added}/{total}</strong></Typography>
					</Box>
				</Box>
			}
		</Box>
	)
}

function ComplianceAndProgress({ value }) {

	return (
		<Box sx={css.container}>
			<FlexCard />
		</Box>
	)
}

export default ComplianceAndProgress